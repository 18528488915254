import styled from "styled-components";
import { Flex } from "@sqymagma/elements";

const ChartContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xxs};
`;

export { ChartContainer };
