import { getDay, isSameMinute } from "date-fns";

import { AppState } from "@contexts/App";
import { AppEvent } from "@types";
import { History } from "history";

interface Notify {
  title?: string | null;
  message?: string;
  onClick?: () => void;
  requireInteraction?: boolean;
}

const notify = (props: Notify) => {
  const { title, message, onClick, requireInteraction } = props;

  if (!title) return;

  const execNotification = () => {
    const notification = new Notification(title, {
      icon: "/logo192.png",
      body: message,
      requireInteraction,
    });
    notification.onclick = () => {
      onClick && onClick();
      notification.close();
    };
  };

  if ("Notification" in window) {
    if (Notification.permission === "granted") {
      execNotification();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          execNotification();
        }
      });
    }
  }
};

const requestNotificationPermission = () => {
  if ("Notification" in window) {
    if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }
  }
};

const notifyAppEvent = (
  event: AppEvent,
  appState?: AppState,
  history?: History<unknown>
) => {
  const { weekDays, hour, minute, title, message, goTo } = event;
  const isEventDay = weekDays.some((wDay) => getDay(new Date()) === wDay);
  const eventStart = new Date();
  eventStart.setHours(hour);
  eventStart.setMinutes(minute);
  const now = new Date();
  const isEventNow = isSameMinute(now, eventStart);
  if (isEventDay && isEventNow) {
    const options = {
      title: typeof title === "string" ? title : title(appState),
      message: typeof message === "function" ? message(appState) : message,
      onClick: () => {
        if (goTo) {
          window.focus();
          history?.push(goTo);
        }
      },
      requireInteraction: true,
    };
    notify(options);
  }
};

export { notify, requestNotificationPermission, notifyAppEvent };
