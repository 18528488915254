import React from "react";
import { useHistory } from "react-router";
import { theme } from "@sqymagma/theme";

import { MiniWidget } from "@elements";
import { ReactComponent as JapiSvg } from "@images/japi.svg";

const Japi = () => {
  const history = useHistory();

  const handleAction = () => {
    history.push("/mood");
  };

  return (
    <MiniWidget
      title="JAPI"
      description="¿Cómo se siente el equipo hoy?"
      color={theme("colors.light")}
      icon="happy"
      action={handleAction}
      image={JapiSvg}
    />
  );
};

export default Japi;
