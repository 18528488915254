import React from "react";
import { format } from "date-fns";
import { Box, HStack } from "@sqymagma/elements";

import { Button, IconLink } from "@elements";
import { capitalizeFirstLetter } from "@helpers";

import * as S from "./style";

const es = require("date-fns/locale/es").default;

const CalendarHeader = (props: Props) => {
  const { currentDate, prevMonth, nextMonth, goToday } = props;
  const month = format(currentDate, "MMMM", { locale: es });
  const year = format(currentDate, "yyyy");

  return (
    <S.CalendarHeader>
      <HStack gap="xs" alignItems="center">
        <HStack gap="xxs" alignItems="center">
          <Button appearance="secondary" action={goToday}>
            Hoy
          </Button>
          <IconLink icon="arrowLeft" medium squared action={prevMonth} />
          <IconLink icon="arrowRight" medium squared action={nextMonth} />
        </HStack>
        <Box>
          {capitalizeFirstLetter(month)} {year}
        </Box>
      </HStack>
    </S.CalendarHeader>
  );
};

interface Props {
  currentDate: Date;
  prevMonth: () => void;
  nextMonth: () => void;
  goToday: () => void;
}

export default CalendarHeader;
