import * as React from "react";
import { format } from "date-fns";

import { Event } from "@types";
import { calendarColors } from "@config";

import * as S from "./style";

const EventComponent = (props: Props) => {
  const { event } = props;
  const dateTime =
    event.start.dateTime && format(new Date(event.start.dateTime), "HH:mm");
  const color = calendarColors[event.calendarName];
  const handleClick = () => window.open(event.htmlLink, "_blank");
  return (
    <S.Event
      color={color}
      onClick={handleClick}
      $pending={event.responsePending}
    >
      <div className="event-data">
        {dateTime && `${dateTime} `}
        <span>{event.summary}</span>
      </div>
    </S.Event>
  );
};

interface Props {
  event: Event;
}

export default EventComponent;
