import React, { useEffect, useRef, useState } from "react";
import { Columns, Flex, Text, Box, HStack } from "@sqymagma/elements";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { useApp } from "@contexts";
import { IconLink } from "@elements";
import { useHandleClickOutside } from "@hooks";
import { meetings as meetingsData } from "@data";
import { MeetingId } from "@data/meetings";
import { WidgetBox } from "@components";

import { Meeting } from "./atoms";
import { getAnimationProps, getValidMeetings } from "./utils";
import * as S from "./style";

const defaultState = meetingsData.map((meeting) => meeting.id);
const itemsPerColumn = Math.ceil(meetingsData.length / 2);
const getSlicedItems = (items: MeetingId[]) => [
  items.slice(0, itemsPerColumn),
  items.slice(itemsPerColumn),
];

const MeetingsWidget = () => {
  const { meetings, updateMeetings } = useApp();

  const initialState = getValidMeetings(meetings) || defaultState;
  const [meetingsState, setMeetingsState] = useState(initialState);
  const [isDraggable, setIsDraggable] = useState(false);
  const [animationProps] = useState(getAnimationProps());
  const wrapper = useRef<HTMLDivElement>(null);

  const slicedItems = getSlicedItems(meetingsState);

  useEffect(() => {
    if (meetings?.length) {
      const validMeetings = getValidMeetings(meetings);
      validMeetings && setMeetingsState(validMeetings);
    }
  }, [meetings]);

  useEffect(() => {
    if (!isDraggable) {
      const validMeetings = getValidMeetings(meetingsState);
      validMeetings && updateMeetings(validMeetings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraggable]);

  const toggleDraggable = () => setIsDraggable((state) => !state);

  const handleClickOutside = (e: MouseEvent) => {
    if (wrapper.current && wrapper.current.contains(e.target as Node)) return;
    toggleDraggable();
  };
  useHandleClickOutside(isDraggable, handleClickOutside);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceList = Number(source.droppableId);
    const sourceIndex = source.index;
    const destList = Number(destination.droppableId);
    const destIndex = destination.index;

    const items = Array.from(slicedItems);
    const [reorderedItem] = items[sourceList].splice(sourceIndex, 1);
    items[destList].splice(destIndex, 0, reorderedItem);

    const tooManyItems = items[destList].length > items[sourceList].length;
    if (tooManyItems) {
      const lastDestIndex = items[destList].length - 1;
      const [removedItem] = items[destList].splice(lastDestIndex, 1);
      const newSourceIndex = items[sourceList].length;
      items[sourceList].splice(newSourceIndex, 0, removedItem);
    }

    setMeetingsState(items.flat());
  };

  return (
    <WidgetBox>
      <S.Wrapper ref={wrapper}>
        <Box pb="xs">
          <Flex justifyContent="space-between" alignItems="center">
            <Text textStyle="subtitle01" color="text01" weight="bold">
              Salas de vídeo
            </Text>
            <HStack gap="xxxs">
              <IconLink
                icon="options"
                action={toggleDraggable}
                tooltip="Reordenar salas"
              />
            </HStack>
          </Flex>
        </Box>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Columns cols={2} hs="xxs" style={{ overflow: "visible" }}>
            {slicedItems.map((meetingsList, idx1) => (
              <Box key={idx1}>
                <Droppable droppableId={`${idx1}`}>
                  {(provided) => (
                    <S.MeetingsList
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {meetingsList.map((meetingId, idx2) => (
                        <Draggable
                          draggableId={meetingId}
                          index={idx2}
                          key={meetingId}
                          isDragDisabled={!isDraggable}
                        >
                          {(provided) => (
                            <S.MeetingItemWrapper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <S.MeetingItem
                                isDraggable={isDraggable}
                                {...animationProps[idx2 + idx2 * idx1]}
                              >
                                <Meeting
                                  id={meetingId}
                                  dragHandleProps={provided.dragHandleProps}
                                  disabled={isDraggable}
                                />
                              </S.MeetingItem>
                            </S.MeetingItemWrapper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </S.MeetingsList>
                  )}
                </Droppable>
              </Box>
            ))}
          </Columns>
        </DragDropContext>
      </S.Wrapper>
    </WidgetBox>
  );
};

export default MeetingsWidget;
