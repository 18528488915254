import { isSameMinute, format, sub } from "date-fns";
import { notify, extractMeetingLink } from "@helpers";
import { Event, User } from "@types";

const sortEvents = (events: Event[]) => {
  return events?.sort((event1, event2) => {
    const {
      start: { dateTime: event1Start },
      end: { dateTime: event1End },
    } = event1;
    const {
      start: { dateTime: event2Start },
      end: { dateTime: event2End },
    } = event2;

    if (!event1Start && !event2Start) {
      return event1.summary.localeCompare(event2.summary);
    } else if (!event1Start) {
      return -1;
    } else {
      if (event1Start === event2Start) {
        return new Date(event1End).getTime() - new Date(event2End).getTime();
      } else {
        return (
          new Date(event1Start).getTime() - new Date(event2Start).getTime()
        );
      }
    }
  });
};

const mapResponsePending = (event: Event, user: User | null) => {
  const { attendees } = event;
  const responsePending = !!attendees?.some((attendant) => {
    const { email, responseStatus } = attendant;
    return email === user?.email && responseStatus === "needsAction";
  });
  return { ...event, responsePending };
};

const filterDeclinedEvent = (event: Event, user: User | null) => {
  const { attendees } = event;
  const isDeclined = attendees?.some((attendant) => {
    const { email, responseStatus } = attendant;
    return email === user?.email && responseStatus === "declined";
  });
  return !isDeclined;
};

const notifyEvent = (event: Event, openVideoPopup = false) => {
  const { start, summary, meetingLink } = event;
  const eventStart = new Date(start.dateTime);
  const oneMinuteToStart = sub(eventStart, { minutes: 1 });
  const now = new Date();
  const isEventNow = isSameMinute(now, oneMinuteToStart);
  if (isEventNow) {
    const eventTime = format(new Date(eventStart), "HH:mm");
    const options = {
      title: `${eventTime} ${summary}`,
      ...(meetingLink && { message: "📞 Pulsa para iniciar la llamada" }),
      onClick: () =>
        meetingLink &&
        window.open(
          meetingLink,
          "_blank",
          openVideoPopup ? "popup" : undefined
        ),
      requireInteraction: true,
    };
    notify(options);
  }
};

const addMeetingLink = (event: Event) => {
  const { hangoutLink, description, location } = event;
  const stringsWithPossibleMeeting = [description, location];
  const meetingLink =
    hangoutLink || extractMeetingLink(stringsWithPossibleMeeting);
  return { ...event, meetingLink };
};

export {
  sortEvents,
  mapResponsePending,
  filterDeclinedEvent,
  notifyEvent,
  addMeetingLink,
};
