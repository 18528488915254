import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Section = styled.div`
  ${theme("textStyle.headingxs")};
  color: ${(p) => p.theme.colors.text01};
  padding: ${(p) => p.theme.spacing.xxs} 0;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  border-bottom: 1px solid ${(p) => p.theme.colors.line02};
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: ${(p) => p.theme.spacing.s};
  }
`;

const Warning = styled.div`
  ${theme("textStyle.tiny")};
  color: ${(p) => p.theme.colors.text01};
  background-color: ${(p) => p.theme.colors.quaternaryBackground};
  padding: ${(p) => p.theme.spacing.xxs};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
`;

export { Option, Section, Warning };
