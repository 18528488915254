import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { Tooltip, Icon } from "@elements";
import { IconNames } from "@elements/Icon";

import * as S from "./style";

const IconLink = (props: Props) => {
  const {
    to,
    action,
    icon,
    rotateOnClick,
    small,
    big,
    medium,
    squared,
    tooltip,
    inverted,
    rotated,
    disabled,
  } = props;

  const iconRef = useRef<HTMLDivElement>(null);

  const handleAction = (e: React.MouseEvent) => {
    if (disabled) return;
    e.stopPropagation();
    if (rotateOnClick) {
      iconRef.current && iconRef.current.classList.add("rotate");
      setTimeout(() => {
        iconRef.current && iconRef.current.classList.remove("rotate");
      }, 1000);
    }
    action && action();
  };

  const size = small ? "small" : medium ? "medium" : big ? "big" : "default";

  if (action) {
    return (
      <Tooltip content={tooltip} hideOnClick>
        <S.IconWrapper
          onClick={handleAction}
          ref={iconRef}
          size={size}
          squared={squared}
          inverted={inverted}
          rotated={rotated}
          disabled={disabled}
        >
          <Icon name={icon} />
        </S.IconWrapper>
      </Tooltip>
    );
  }

  return (
    <Tooltip content={tooltip} hideOnClick>
      <div>
        <Link to={to}>
          <S.IconWrapper
            size={size}
            squared={squared}
            inverted={inverted}
            rotated={rotated}
            disabled={disabled}
          >
            <Icon name={icon} />
          </S.IconWrapper>
        </Link>
      </div>
    </Tooltip>
  );
};

interface CommonProps {
  icon: IconNames;
  rotateOnClick?: boolean;
  small?: boolean;
  big?: boolean;
  medium?: boolean;
  squared?: boolean;
  tooltip?: string;
  inverted?: boolean;
  rotated?: boolean;
  disabled?: boolean;
}

interface PropsWithTo extends CommonProps {
  to: string;
  action?: undefined;
}

interface PropsWithAction extends CommonProps {
  action: () => void;
  to?: undefined;
}

type Props = PropsWithTo | PropsWithAction;

export default IconLink;
