import React, { useLayoutEffect, useRef, useState } from "react";
import { Flex, Text, Box, VStack } from "@sqymagma/elements";

import mailApi, { MailTemplate } from "@services/mail";
import { Button, Loader } from "@elements";

import * as S from "./style";

const fontSizes = { max: 60, min: 30, breakpoint: 40 };

const Form = (props: Props) => {
  const { template, placeholder, sentMessage, errorMessage } = props;
  const initialState = {
    message: "",
    fontSize: fontSizes.max,
    textAreaHeight: "auto",
    parentHeight: "auto",
    isSending: false,
    isSent: false,
    error: false,
  };
  const [state, setState] = useState(initialState);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    setState((state) => ({
      ...state,
      parentHeight: `${textAreaRef.current?.scrollHeight}px`,
      textAreaHeight: `${textAreaRef.current?.scrollHeight}px`,
    }));
  }, [state.message]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    const { max, min, breakpoint } = fontSizes;
    const showMinFont = state.fontSize === min || value.length >= breakpoint;
    const fontSize = showMinFont ? min : max;
    setState((state) => ({
      ...state,
      message: value,
      error: false,
      fontSize,
      textAreaHeight: "auto",
      setParentHeight: `${textAreaRef.current?.scrollHeight}px`,
    }));
  };

  const handleSend = async () => {
    setState((state) => ({ ...state, isSending: true }));
    const response = await mailApi.send(state.message, template);
    if (response.ok) {
      setState((state) => ({ ...state, isSending: false, isSent: true }));
    } else {
      setState((state) => ({ ...state, isSending: false, error: true }));
    }
  };

  const isDisabled =
    state.message.length < 1 || state.isSending || state.isSent;

  const SentMessage = () => (
    <>
      <Box>
        <Text textStyle="display02" color="text03">
          {sentMessage.title}
        </Text>
      </Box>
      {sentMessage.subtitle && (
        <Box>
          <Text textStyle="body" color="text01">
            {sentMessage.subtitle}
          </Text>
        </Box>
      )}
    </>
  );

  const ErrorMessage = () => (
    <Box mt="xs">
      <Text textStyle="body" color="error">
        {errorMessage}
      </Text>
    </Box>
  );

  return (
    <Box mt="l" mb="l">
      <VStack gap="s" display={state.isSent ? "none" : "block"}>
        <S.TextAreaWrapper height={state.parentHeight}>
          <S.TextArea
            ref={textAreaRef}
            value={state.message}
            onChange={handleChange}
            placeholder={placeholder}
            rows={1}
            fontSize={state.fontSize}
            height={state.textAreaHeight}
            disabled={state.isSending || state.isSent}
          />
        </S.TextAreaWrapper>
        <Flex justifyContent="flex-end">
          {state.isSending ? (
            <Loader small />
          ) : (
            <Button
              action={handleSend}
              disabled={isDisabled}
              rightIcon="arrowRight2"
              animateHover
            >
              Enviar
            </Button>
          )}
        </Flex>
      </VStack>
      {state.isSent && <SentMessage />}
      {state.error && <ErrorMessage />}
    </Box>
  );
};

interface Props {
  template: MailTemplate;
  placeholder: string;
  sentMessage: { title: string; subtitle?: string };
  errorMessage: string;
}

export default Form;
