import React, { useEffect, useState } from "react";
import { Text, Box, Flex } from "@sqymagma/elements";

import { useApp } from "@contexts";
import { capitalizeFirstLetter, trimText } from "@helpers";
import { Icon, Tooltip } from "@elements";
import { Weather } from "@types";

import * as weatherIcons from "./icons.json";
import * as S from "./style";

const WeatherWidget = () => {
  const { weather } = useApp();
  const [icon, setIcon] = useState<string>();

  const loading = !(weather && Object.keys(weather).length);

  useEffect(() => {
    if (weather && !loading) {
      const prefix = "wi wi-";
      const timeOfDay = weather.timeOfDay;
      const iconId =
        weather.iconId.toString() as keyof (typeof weatherIcons)[typeof timeOfDay];
      const icon = prefix + weatherIcons[timeOfDay][iconId].icon;
      setIcon(icon);
    }
  }, [weather, loading]);

  return <Layout weather={weather} icon={icon} loading={loading} />;
};

const Layout = (props: LayoutProps) => {
  const { weather, icon, loading } = props;
  const [isFirstLoad] = useState(loading);
  const iconColor = icon === "wi wi-day-sunny" && "#f1be42";

  if (loading) return null;

  return (
    <S.WeatherWrapper isFirstLoad={isFirstLoad}>
      <Flex alignItems="center">
        <Box flexGrow="1">
          <Flex justifyContent="center" alignItems="center">
            <Text textStyle="display02" weight="bold">
              {weather?.temperature}&deg;
            </Text>
          </Flex>
        </Box>
        <Box
          flexGrow="1"
          ml="xs"
          mr="xxs"
          display={{ default: "none", l: "block" }}
        >
          <Box>
            <Text textStyle="subtitle03">Máx {weather?.max}&deg;</Text>
            <Text textStyle="subtitle03" pl="xxs">
              Mín {weather?.min}&deg;
            </Text>
            <Text textStyle="subtitle03" pl="xxs">
              <span className="wi wi-thermometer"></span> {weather?.feelsLike}
              &deg;
            </Text>
            <Text textStyle="subtitle03" pl="xxs">
              <span className="wi wi-strong-wind"></span> {weather?.windSpeed}
            </Text>
          </Box>
          <Box mt="xxxs">
            <Text textStyle="subtitle03">
              <Icon name="navigation" size="xs" /> {trimText(weather?.city, 22)}
            </Text>
          </Box>
        </Box>
        <Box flexGrow="1" ml="xs">
          <Tooltip content={capitalizeFirstLetter(weather?.description)}>
            <Box
              className={`${icon}`}
              src={icon}
              fontSize="40px"
              color={iconColor}
            />
          </Tooltip>
        </Box>
      </Flex>
    </S.WeatherWrapper>
  );
};

interface LayoutProps {
  weather: Weather | null;
  icon?: string;
  loading: boolean;
}

export default WeatherWidget;
