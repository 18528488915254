import React, { useCallback, useEffect, useMemo, useState } from "react";
import { startOfWeek, addDays } from "date-fns";
import { VStack, Text, HStack, Flex } from "@sqymagma/elements";

import { areSameDay, sortEvents } from "@helpers";
import { useApp } from "@contexts";
import { WEEK_DAYS } from "@constants";
import { WidgetBox } from "@components";

import Header from "./Header";
import Day from "./Day";
import Event from "./Event";
import { getEventPosition } from "./utils";
import * as S from "./style";

const noEventsText = "No tienes ningún evento programado";

const CalendarWidget = () => {
  const {
    selectedDay,
    selectedDayEvents,
    changeSelectedDay,
    setCurrentDay,
    time,
    holidays,
  } = useApp();
  const [calendarDays, setCalendarDays] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setCurrentDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calendarDaysOutput = [];
    const firstOfTheWeek = startOfWeek(selectedDay, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      const dateIncrement = addDays(new Date(firstOfTheWeek.getTime()), i);
      calendarDaysOutput.push(
        <Day
          key={i}
          date={dateIncrement}
          selectedDay={selectedDay}
          changeSelectedDay={changeSelectedDay}
        />
      );
    }

    setCalendarDays(calendarDaysOutput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  const holiday = holidays.find((holiday) =>
    areSameDay(holiday.date, selectedDay)
  );

  const renderHoliday = useMemo(
    () => (
      <>
        {!!holiday && (
          <HStack gap="xs" mb="xs">
            <Flex height="100%" alignItems="center">
              <S.HolidayMark />
            </Flex>
            <Text
              textStyle="body"
              color="text01"
              weight="bold"
              style={{ lineHeight: "1.2em" }}
            >
              {holiday.name}
            </Text>
          </HStack>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDayEvents, holidays]
  );

  const sortedDayEvents = sortEvents(selectedDayEvents);

  const renderEvents = useCallback(
    () =>
      sortedDayEvents.map((event, idx) => {
        const { isNextEvent, isLastFinishedEvent } = getEventPosition(
          event,
          idx,
          sortedDayEvents,
          selectedDay
        );
        return (
          <Event
            key={event.id}
            time={time}
            isNextEvent={isNextEvent}
            isLastFinishedEvent={isLastFinishedEvent}
            selectedDay={selectedDay}
            {...event}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedDayEvents, time]
  );

  const renderNoEvents = () => (
    <Text textStyle="body" color="text01">
      {noEventsText}
    </Text>
  );

  const WeekDaysNames = () => (
    <S.WeekDaysNames>
      {WEEK_DAYS.map((weekday, idx) => (
        <div key={idx}>{weekday.slice(0, 2)}</div>
      ))}
    </S.WeekDaysNames>
  );

  return (
    <WidgetBox>
      <Header selectedDay={selectedDay} changeSelectedDay={changeSelectedDay} />
      <WeekDaysNames />
      <S.Calendar>{calendarDays}</S.Calendar>
      <S.Events>
        {renderHoliday}
        <VStack gap="xs">
          {selectedDayEvents.length ? renderEvents() : renderNoEvents()}
        </VStack>
      </S.Events>
    </WidgetBox>
  );
};

export default CalendarWidget;
