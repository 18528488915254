import React, {
  MutableRefObject,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import { Columns, Column, VStack, Box, Flex } from "@sqymagma/elements";
import { useViewportSpy } from "beautiful-react-hooks";

import { remoteApi } from "@services";
import { ApplauseWidget, WidgetBox } from "@components";
import { Applause, ApplausesStats } from "@types";
import { Loader, Tabs } from "@elements";

import UserStats from "./UserStats";
import GlobalStats from "./GlobalStats";
import Timeline from "./Timeline";
import Ranking from "./Ranking";

const itemsPerPage = 5; // number of applauses to retrieve with lazy load
const tabs = ["recibidos", "enviados", "pendientes"];

const Applauses = () => {
  const [applauses, setApplauses] = useState<Array<Applause>>();
  const [stats, setStats] = useState<ApplausesStats>();
  const [activeTab, setActiveTab] = useState(0);
  const [lastActiveTab, setLastActiveTab] = useState(0);

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const isLoadMoreVisible = useViewportSpy(
    loadMoreRef as unknown as MutableRefObject<HTMLElement>
  );
  const [showLoadMore, setShowLoadMore] = useState(true);
  const loadMoreApplauses = showLoadMore && !!isLoadMoreVisible;
  const lastItemTimestamp = applauses?.[applauses.length - 1]?.created._seconds;

  const setRetrievedApplauses = (applauses: Array<Applause>) => {
    applauses && setApplauses((state = []) => [...state, ...applauses]);
    !applauses?.length && setShowLoadMore(false);
  };

  const getApplauses = useCallback(() => {
    if (activeTab === 0) {
      remoteApi
        .getApplausesReceived({ itemsPerPage, lastItemTimestamp })
        .then(setRetrievedApplauses);
    } else {
      const state = activeTab === 1 ? "delivered" : "pending";
      remoteApi
        .getApplausesSent(state, { itemsPerPage, lastItemTimestamp })
        .then(setRetrievedApplauses);
    }
  }, [activeTab, lastItemTimestamp]);

  const resetApplauses = () => {
    setApplauses(undefined);
    setShowLoadMore(true);
  };

  const handleRefreshApplauses = () => {
    if (activeTab === 2) resetApplauses();
  };

  useEffect(() => {
    remoteApi.getApplausesStats().then((stats) => setStats(stats));
  }, []);

  useEffect(() => {
    if (!loadMoreApplauses || !loadMoreRef.current) return;
    const rect = loadMoreRef.current.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.top <= window.innerHeight;
    if (!isVisible) return;
    getApplauses();
  }, [lastItemTimestamp, loadMoreApplauses, getApplauses]);

  useEffect(() => {
    if (lastActiveTab === activeTab) return;
    setLastActiveTab(activeTab);
    resetApplauses();
  }, [activeTab, lastActiveTab]);

  const showRanking =
    !!stats?.lastMonthRanking?.senders &&
    !!Object.keys(stats.lastMonthRanking.senders).length;

  return (
    <Columns
      hs="m"
      vs={{ default: "xs", s: "m" }}
      overflow={{ default: "hidden", s: "visible" }}
    >
      <Column width={[1, 1, "50%", "50%", "30%"]} pb={{ default: 0, xl: "m" }}>
        <VStack gap="m">
          <WidgetBox>
            <UserStats stats={stats} />
          </WidgetBox>
        </VStack>
      </Column>

      <Column
        width={[1, 1, "100%", "100%", "40%"]}
        pb="xxs"
        order={{ default: 3, xl: 0 }}
      >
        <Box mb="m">
          <ApplauseWidget onSubmit={handleRefreshApplauses} />
        </Box>
        <VStack gap="m" minHeight="100vh">
          <VStack gap="m">
            <Tabs tabs={tabs} active={activeTab} change={setActiveTab} />
            {applauses && (
              <Timeline
                applauses={applauses}
                refreshApplauses={handleRefreshApplauses}
              />
            )}
          </VStack>

          {/* loading more spinner */}
          <Box ref={loadMoreRef}>
            <Flex alignItems="center" justifyContent="center">
              {showLoadMore && <Loader />}
            </Flex>
          </Box>
        </VStack>
      </Column>

      <Column width={[1, 1, "50%", "50%", "30%"]} pb={{ default: 0, xl: "m" }}>
        <VStack gap="m">
          {showRanking && (
            <WidgetBox>
              <Ranking lastMonthRanking={stats.lastMonthRanking} />
            </WidgetBox>
          )}
          <WidgetBox>
            <GlobalStats stats={stats} />
          </WidgetBox>
        </VStack>
      </Column>
    </Columns>
  );
};

export default Applauses;
