import jwtDecode from "jwt-decode";
import { getFullNameFromEmail, getUser } from "@helpers";
import { remoteApi } from "@services";
import { Email, Event, GoogleCodeClient, GoogleIdToken } from "@types";

const google = window.google;
const gapi = window.gapi;

const {
  REACT_APP_GOOGLE_CLIENT_ID: googleClientId = "",
  REACT_APP_GOOGLE_API_KEY: googleApiKey,
} = process.env;

const discoveryDocs = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
  "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
  "https://sheets.googleapis.com/$discovery/rest?version=v4",
];
const scope = [
  "openid",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/gmail.modify",
  "https://www.googleapis.com/auth/directory.readonly",
  "https://www.googleapis.com/auth/spreadsheets.readonly",
  "https://www.googleapis.com/auth/spreadsheets",
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/drive.file",
].join(" ");

const loadApi = async () => {
  try {
    await new Promise((resolve) => {
      gapi.load("client", resolve);
    });

    await gapi.client.init({
      apiKey: googleApiKey,
      discoveryDocs,
    });
  } catch (error) {
    console.warn("Error while loading Google APIs platform library.", error);
  }
};

const loadUser = async (token: string) => {
  try {
    const tokens = await remoteApi.refreshToken(token);
    if (tokens) {
      const { access_token, id_token, ...lastUserInfo } = tokens;
      gapi.client.setToken({ access_token });
      const user = getUser(id_token, lastUserInfo);
      return user;
    }
  } catch (error) {
    console.warn("Error while loading Google user.", error);
  }
};

const signIn = async () => {
  try {
    const token = localStorage.getItem("token");
    const hint = token && (jwtDecode(token) as GoogleIdToken).email;

    // get code from Google to exchange with the backend to obtain the access token
    const codeClient = (await new Promise((resolve) => {
      const codeClientConfig = {
        client_id: googleClientId,
        scope,
        callback: resolve,
        ...(hint && { hint }),
      };
      const client = google.accounts.oauth2.initCodeClient(codeClientConfig);
      client.requestCode();
    })) as unknown as GoogleCodeClient;

    if (codeClient.error) return;

    const { access_token, id_token } = await remoteApi.requestAccessToken(
      codeClient.code
    );
    gapi.client.setToken({ access_token });
    const user = getUser(id_token);
    return user;
  } catch (error) {
    console.warn("Error while signing in with Google.", error);
  }
};

const signOut = () => {
  const token = gapi.client.getToken();
  if (token) {
    gapi.client.setToken(null);
  }
  google.accounts.id.disableAutoSelect();
};

const getEvents = async (dates: {
  firstDate: Date;
  lastDate: Date;
}): Promise<Event[] | undefined> => {
  const { firstDate, lastDate } = dates;
  try {
    const getEventsByCalendar = async (
      calendarId: string,
      calendarName: string
    ) => {
      const response = await gapi.client.calendar.events.list({
        calendarId,
        timeMin: firstDate.toISOString(),
        timeMax: lastDate.toISOString(),
        showDeleted: false,
        singleEvents: true,
        orderBy: "startTime",
      });
      const events = response.result.items?.map((item) => {
        return { ...item, calendarName };
      });
      return events;
    };

    const googleCalendarId = process.env.REACT_APP_GOOGLE_CALENDAR_ID;

    const events = await Promise.all([
      getEventsByCalendar("primary", "primary"),
      googleCalendarId && getEventsByCalendar(googleCalendarId, "secuoyas"),
    ]);

    return events.flat() as Event[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while getting events.", reason.result?.error.message);
  }
};

const deleteEvent = async (calendarId: string, eventId: string) => {
  try {
    const params = {
      calendarId,
      eventId,
    };
    return await gapi.client.calendar.events.delete(params);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while deleting event.", reason.result?.error.message);
  }
};

const getEmails = async (maxResults: number): Promise<Email[] | undefined> => {
  try {
    const response = await gapi.client.gmail.users.messages.list({
      userId: "me",
      labelIds: "INBOX",
      maxResults,
    });

    const messagesResult = response.result.messages;
    const messages =
      messagesResult &&
      (await Promise.all(
        messagesResult.map((message) => message.id && getMessage(message.id))
      ));

    const validMessages = messages?.filter(
      Boolean
    ) as gapi.client.Response<gapi.client.gmail.Message>[];

    const messagesFormatted = validMessages?.map((email) => {
      const {
        result: { id, snippet, labelIds, payload },
      } = email;

      const headerNames = ["from", "date", "subject"];
      const headerValues = headerNames.reduce(
        (previous, headerName) => {
          const header =
            payload?.headers &&
            payload.headers.find((h) => h.name?.toLowerCase() === headerName);
          return { ...previous, [headerName]: header?.value || "" };
        },
        {} as { from: string; date: string; subject: string }
      );
      const { from, date, subject } = headerValues;
      const unread = labelIds?.includes("UNREAD");

      return { id, from, date, subject, snippet, unread };
    });

    return messagesFormatted;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while getting emails.", reason.result?.error.message);
  }
};

const getMessage = async (id: string) => {
  try {
    const params = { userId: "me", id };
    return await gapi.client.gmail.users.messages.get(params);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while getting message.", reason.result?.error.message);
  }
};

const markReadEmail = async (id: string) => {
  try {
    const params = {
      userId: "me",
      id,
      resource: { removeLabelIds: ["UNREAD"] },
    };
    return await gapi.client.gmail.users.messages.modify(params);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn(
      "Error while marking message as read.",
      reason.result?.error.message
    );
  }
};

const getPeople = async () => {
  try {
    const {
      result: { people },
    } = await gapi.client.people.people.listDirectoryPeople({
      readMask: "photos,emailAddresses,names",
      sources: [
        "DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE",
        "DIRECTORY_SOURCE_TYPE_DOMAIN_CONTACT",
      ],
    });

    const peopleFormatted = people?.map((person) => {
      const displayName = person.names && person.names[0].displayName;
      const nameFromEmail =
        person.emailAddresses?.[0].value &&
        getFullNameFromEmail(person.emailAddresses[0].value);
      return {
        email: person.emailAddresses && person.emailAddresses[0].value,
        photo: person.photos && person.photos[0].url,
        name: displayName || nameFromEmail,
      };
    });

    return peopleFormatted;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while getting people.", reason.result?.error.message);
  }
};

const getSheet = async (payload: { spreadsheetId: string; range: string }) => {
  try {
    const {
      result: { values },
    } = await gapi.client.sheets.spreadsheets.values.get(payload);
    return values;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while getting sheet.", reason.result?.error.message);
  }
};

const writeSheet = async (
  spreadsheetId: string,
  range: string,
  values: Array<unknown[]>
) => {
  const payload = { spreadsheetId: spreadsheetId };
  const body = {
    data: [
      {
        majorDimension: "ROWS",
        range: range,
        values: values,
      },
    ],
    includeValuesInResponse: true,
    valueInputOption: "USER_ENTERED",
  };

  try {
    const { result } = await gapi.client.sheets.spreadsheets.values.batchUpdate(
      payload,
      body
    );
    return result;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while writing sheet.", reason.result?.error.message);
  }
};

const getChatMessages = async (
  maxResults: number,
  query?: string,
  labelIds?: string
) => {
  try {
    const responseChat = await gapi.client.chat.spaces.messages.list({
      parent: "spaces/Secuoyas",
    });
    return responseChat;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn("Error while getting emails.", reason.result?.error.message);
  }
};

const getMeetInfo = async (
  maxResults: number,
  query?: string,
  labelIds?: string
) => {
  // try {
  //   const responseMeet = gapi.client.reports.userUsageReport.get({
  //       userKey: "all",
  //       applicationName: "meet"
  //     })
  //   console.log("response", responseMeet)
  //   return responseMeet
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // } catch (reason: any) {
  //   console.warn("Error while getting emails.", reason.result?.error.message);
  // }
};

const getAllEmails = async (
  maxResults: number,
  query?: string,
  labelIds?: string,
  pageToken?: string
) => {
  try {
    const response = await gapi.client.gmail.users.messages.list({
      userId: "me",
      labelIds: labelIds,
      maxResults,
      q: query,
      pageToken,
    });
    return response.result;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (reason: any) {
    console.warn(
      "Error while getting all emails.",
      reason.result?.error.message
    );
  }
};

const googleApi = {
  loadApi,
  loadUser,
  signIn,
  signOut,
  getEvents,
  getEmails,
  getChatMessages,
  getAllEmails,
  getMeetInfo,
  markReadEmail,
  getPeople,
  getSheet,
  deleteEvent,
  writeSheet,
};

export default googleApi;
