import { createGlobalStyle } from "styled-components";
import themes from "./.allThemes";

export function createGlobalTheme() {
  return themes.globalTheme;
}

export function createTheme({ theme }) {
  return themes[theme].theme;
}

export function createSubThemes({ theme }) {
  return themes[theme].subThemes;
}

export function createSubTheme({ theme, name }) {
  return createSubThemes({ theme })[name];
}

export const GlobalCSS = createGlobalStyle`
  [style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }

  [style*="--aspect-ratio"] > img {
    height: auto;
  }

  @supports (--custom: property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }
    [style*="--aspect-ratio"]::before {
      height: 0;
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  html, body {
    line-height: ${(p) => p.theme.lineHeightBase};
    font-family: ${(p) => p.theme.fontFamily.primary};
    color: ${(p) => p.theme.colors.text01};
    background-color: ${(p) => p.theme.colors.primaryBackground};
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
  }

  * { box-sizing: border-box; }
  a { color: inherit; text-decoration: none; }

  em { font-style: italic; }

  /* Reset H1 from normalize */
  h1 { margin: 0; font-size: unset; }
`;
