import { useEffect, useState } from "react";
import { normalize } from "@helpers";

const useSearch = <Type>(
  items: Array<Type>,
  params: {
    searchQuery: string;
    searchFields: string[];
  }
): [
  filteredItems: Array<Type>,
  setFilteredItems: React.Dispatch<React.SetStateAction<Type[]>>,
] => {
  const { searchQuery, searchFields } = params;

  const [originalItems] = useState<Array<Type>>(items);
  const [allItems, setAllItems] = useState<Array<Type>>(items);
  const [filteredItems, setFilteredItems] = useState<Array<Type>>(items);

  useEffect(() => {
    const itemsChanged =
      JSON.stringify(originalItems) !== JSON.stringify(items);
    if (itemsChanged) {
      setAllItems(items);
    }
  }, [items, originalItems]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredItems = allItems.filter((item: any) => {
      return searchFields.some(
        (field) =>
          item[field] && normalize(item[field]).includes(normalize(searchQuery))
      );
    });
    setFilteredItems(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems, searchQuery]);

  return [filteredItems, setAllItems];
};

export default useSearch;
