import React, { useState } from "react";
import { VStack, HStack, Text, Flex, Box } from "@sqymagma/elements";
import { format, fromUnixTime } from "date-fns";
import { motion } from "framer-motion";

import { Applause } from "@types";
import { ApplauseWidget, Modal, WidgetBox } from "@components";
import { Avatar, Button, HTMLRender } from "@elements";
import { getUserDataFromEmail } from "@helpers";
import { useApp, useAuth, useToast } from "@contexts";
import { useModal } from "@hooks";
import { remoteApi } from "@services";

import * as S from "./style";
import { getEncodedMessage, isOtherMonth } from "./helpers";

const es = require("date-fns/locale/es").default;

interface ApplauseProps {
  applause: Applause;
  applauses: Array<Applause>;
  refreshApplauses: () => void;
  index: number;
}

const defaultPeopleShown = 9;

const ApplauseComponent = (props: ApplauseProps) => {
  const { applause, applauses, refreshApplauses, index } = props;
  const {
    user: { email },
    claps,
    message,
    emails,
    created,
  } = applause;

  const { people, getRemainingClaps } = useApp();
  const { user } = useAuth();
  const { addToast } = useToast();

  const [hideShowMorePeople, setHideShowMorePeople] = useState(false);

  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } =
    useModal(false);
  const { isOpen: isUpdateOpen, toggleModal: toggleUpdateModal } =
    useModal(false);

  const from = getUserDataFromEmail(email, people);
  const isUserApplause = from?.email === user?.email;
  const responseSubject = encodeURI(
    `Muchas gracias por los ${claps} aplausos 🤗`
  );
  const responseBody = getEncodedMessage(message, from?.name);
  const morePeopleIncluded = emails.filter((email) => email !== user?.email);
  const cc = morePeopleIncluded.join(",");
  const responseUrl = `mailto:${from?.email}?${
    cc.length ? `cc=${cc}&` : ""
  }subject=${responseSubject}&body=${responseBody}`;

  const handleRespond = () => {
    window.open(responseUrl, "_blank", "popup=true,width=700,height=500");
  };

  const handleDelete = async () => {
    toggleDeleteModal();
    const isDeleted = await remoteApi.deleteApplause(applause.id);
    refreshApplauses();
    getRemainingClaps();
    if (isDeleted) {
      addToast("Aplauso eliminado");
    } else {
      addToast("⚠️ ¡Oops! No se pudo eliminar el aplauso");
    }
  };

  const handleOnUpdate = async () => {
    toggleUpdateModal();
    refreshApplauses();
  };

  const monthDivider = (
    <Flex justifyContent="center" pb="s">
      <Text textStyle="bodyInline" color="text03">
        {format(fromUnixTime(created._seconds), "MMMM' 'yyyy", {
          locale: es,
        })}
      </Text>
    </Flex>
  );

  const showMorePeopleBtn =
    !hideShowMorePeople && morePeopleIncluded.length > defaultPeopleShown;
  const morePeopleIncludedSlice = showMorePeopleBtn
    ? defaultPeopleShown
    : undefined;

  return (
    <div key={applause.id}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {/* Month divider */}
        {isOtherMonth(created, index, applauses) &&
          !applause.isPending &&
          monthDivider}

        <WidgetBox key={index}>
          <VStack gap="xs">
            {/* Header */}
            <HStack gap="xxs" alignItems="center">
              <Avatar image={from?.photo} name={from?.name || email} />
              <Text
                textStyle="subtitle01"
                mr="xxs"
                style={{ fontWeight: "bold", wordBreak: "break-all" }}
              >
                {getUserDataFromEmail(email, people)?.name || email}
              </Text>
              <S.ClapsLabel>👏 {claps}</S.ClapsLabel>
            </HStack>

            {/* Message */}
            <HTMLRender>{message}</HTMLRender>

            {/* Footer */}
            <VStack gap="xxs">
              {!!morePeopleIncluded.length && (
                <S.MorePeople>
                  {morePeopleIncluded
                    .slice(0, morePeopleIncludedSlice)
                    .map((email, index) => {
                      const person = getUserDataFromEmail(email, people);
                      return (
                        <Avatar
                          key={index}
                          image={person?.photo}
                          name={person?.name || email}
                          tooltip={person?.name || email}
                          size="s"
                        />
                      );
                    })}
                  {showMorePeopleBtn && (
                    <Button
                      appearance="primary"
                      action={() => setHideShowMorePeople(true)}
                    >
                      {`+${morePeopleIncluded.length - defaultPeopleShown}`}
                    </Button>
                  )}
                </S.MorePeople>
              )}
              {(!isUserApplause || applause.isPending) && (
                <HStack justifyContent="flex-end" gap="xxs">
                  {!isUserApplause && (
                    <Button
                      appearance="primary"
                      action={handleRespond}
                      target="_blank"
                      leftIcon="cornerUpLeft"
                    >
                      Responder
                    </Button>
                  )}
                  {applause.isPending &&
                    applause.user.email === user?.email && (
                      <HStack gap="xxxs">
                        <Button
                          appearance="tertiary"
                          leftIcon="edit"
                          action={toggleUpdateModal}
                          tooltip="Modificar"
                          rounded
                        />
                        <Button
                          appearance="tertiary"
                          leftIcon="trash"
                          action={toggleDeleteModal}
                          tooltip="Eliminar"
                          rounded
                        />
                      </HStack>
                    )}
                </HStack>
              )}
            </VStack>
          </VStack>
        </WidgetBox>
      </motion.div>

      {/* Delete modal */}
      <Modal
        isOpen={isDeleteOpen}
        hide={toggleDeleteModal}
        size="S"
        title="Eliminar aplauso"
        mainAction={{
          title: "Eliminar",
          action: handleDelete,
        }}
        secondaryAction={{ title: "Cancelar", action: toggleDeleteModal }}
      >
        <Box py="s" px="xs">
          <Text textStyle="body" color="text01">
            ¿Seguro que quieres cancelar el envío de este aplauso y eliminarlo?
          </Text>
        </Box>
      </Modal>

      {/* Update modal */}
      <Modal
        isOpen={isUpdateOpen}
        hide={toggleUpdateModal}
        title="Modifica el reconocimiento"
        height="auto"
        allowOverflow
      >
        <ApplauseWidget update={applause} onSubmit={handleOnUpdate} />
      </Modal>
    </div>
  );
};

export default ApplauseComponent;
