import React, { useEffect, useState } from "react";
import {
  setDate,
  startOfWeek,
  addDays,
  differenceInDays,
  endOfMonth,
  subMonths,
  addMonths,
} from "date-fns";

import { areSameDay, sortEvents } from "@helpers";
import { useApp } from "@contexts";

import CalendarHeader from "./CalendarHeader";
import DayHeaders from "./DayHeaders";
import Day from "./Day";

import * as S from "./style";

const Calendar = () => {
  const { monthEvents, selectedMonth, changeSelectedMonth } = useApp();

  const today = new Date();
  const initialDate = selectedMonth.firstDate;
  const [firstOfTheWeek, setFirstOfTheWeek] = useState(
    startOfWeek(initialDate, { weekStartsOn: 1 })
  );
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [calendarDays, setCalendarDays] = useState<JSX.Element[]>([]);

  const daysToCalculate = differenceInDays(
    endOfMonth(currentDate),
    firstOfTheWeek
  );

  const changeMonth = (date: Date) => {
    const firstDayMonth = setDate(date, 1);
    setCurrentDate(firstDayMonth);
    setFirstOfTheWeek(startOfWeek(firstDayMonth, { weekStartsOn: 1 }));
    changeSelectedMonth(firstDayMonth);
  };

  const goToday = () => {
    changeMonth(today);
  };

  const prevMonth = () => {
    const prev = subMonths(currentDate, 1);
    changeMonth(prev);
  };

  const nextMonth = () => {
    const next = addMonths(currentDate, 1);
    changeMonth(next);
  };

  useEffect(() => {
    const calendarDaysOutput = [];

    for (let i = 0; i <= daysToCalculate; i++) {
      const dateIncrement = addDays(new Date(firstOfTheWeek.getTime()), i);
      const dayEvents = monthEvents.filter((event) => {
        const eventDate = event.start.dateTime || event.start.date;
        return areSameDay(dateIncrement, eventDate);
      });
      const sortedDayEvents = sortEvents(dayEvents);
      calendarDaysOutput.push(
        <Day
          key={i}
          date={dateIncrement}
          currentDate={currentDate}
          today={today}
          events={sortedDayEvents}
        />
      );
    }

    setCalendarDays(calendarDaysOutput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthEvents, currentDate]);

  return (
    <>
      <CalendarHeader
        currentDate={currentDate}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
        goToday={goToday}
      />
      <DayHeaders />
      <S.Calendar>{calendarDays}</S.Calendar>
    </>
  );
};

export default Calendar;
