import React, { useLayoutEffect, useRef, useState } from "react";
import { Text, Box, Flex } from "@sqymagma/elements";
import { useWindowResize } from "beautiful-react-hooks";

import { useApp } from "@contexts";
import { ToolLink, WidgetBox } from "@components";
import { Tooltip } from "@elements";
import { getTool } from "@helpers";
import { spacing } from "@themes/globaltheme";

const toolMarginRight = "xxs";

const RecentTools = () => {
  const { recentTools } = useApp();
  const toolsRef = useRef<HTMLDivElement>(null);
  const [numberOfTools, setNumberOfTools] = useState(Infinity);

  const calculateTools = () => {
    if (toolsRef.current?.children?.[0]) {
      const toolSpace =
        toolsRef.current.children[0].getBoundingClientRect().width;
      const toolMargin = parseInt(spacing[toolMarginRight]);
      const toolsWidth =
        toolsRef.current.getBoundingClientRect().width + toolMargin;
      const toolsN = Math.floor(toolsWidth / (toolSpace + toolMargin));
      setNumberOfTools(toolsN);
    }
  };

  useLayoutEffect(() => {
    calculateTools();
  }, [toolsRef.current?.children]);

  useWindowResize(() => {
    calculateTools();
  });

  if (!recentTools.length) return null;

  return (
    <WidgetBox>
      <Box>
        <Text textStyle="subtitle01" color="text01" weight="bold">
          Herramientas recientes
        </Text>
      </Box>
      <Flex mt="xxs" flexWrap="wrap" ref={toolsRef}>
        {recentTools.slice(0, numberOfTools).map((id, idx, arr) => {
          const tool = getTool(id);
          const isLast = idx === arr.length - 1;
          return (
            <Box mr={isLast ? 0 : toolMarginRight} mt="xxs" key={idx}>
              {tool && (
                <Tooltip content={tool.name} bottom hideOnClick>
                  <ToolLink {...tool} tiny />
                </Tooltip>
              )}
            </Box>
          );
        })}
      </Flex>
    </WidgetBox>
  );
};

export default RecentTools;
