import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Mood = styled.div`
  zoom: 0.5;
  @supports (-webkit-touch-callout: none) {
    -webkit-text-size-adjust: auto;
  }
`;

const Empty = styled.div`
  margin-top: -${(p) => p.theme.spacing.xs};

  @media (min-width: ${theme("breakpoints.s")}) {
    margin-top: -${(p) => p.theme.spacing.m};
  }
`;

export { Mood, Empty };
