import React from "react";

import * as S from "./style";

const Toast = (props: Props) => {
  const { children } = props;

  return (
    <S.Wrapper
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut" }}
      layout
    >
      {children}
    </S.Wrapper>
  );
};

interface Props {
  children: JSX.Element | string;
}

export default Toast;
