import styled from "styled-components";

import mask from "@images/masks/mascara-dias.svg";

const GifDays = styled.div<{
  $image: string;
  $right: string;
  $bottom: string;
  $delay?: number;
}>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url(${mask});
  mask-position: center;
  mask-size: 350px;
  mask-repeat: no-repeat;
  width: 350px;
  height: 350px;
  position: absolute;
  right: ${(p) => p.$right};
  bottom: ${(p) => p.$bottom};
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: ${(p) => p.$delay || 500}ms;
  animation-fill-mode: forwards;
`;

export { GifDays };
