import React from "react";

import { isDateWithinRange } from "@helpers";
import { christmasRange } from "@config";

import Christmas from "./Christmas";

const Skins = () => {
  return <>{isDateWithinRange(new Date(), christmasRange) && <Christmas />}</>;
};

export default Skins;
