import * as React from "react";
import { VStack, Text } from "@sqymagma/elements";

import { useAuth } from "@contexts";
import { Avatar, Loader } from "@elements";
import { getUserFromEmail } from "@helpers";
import { ApplausesStats } from "@types";

import { StatsData } from "../atoms";
import * as S from "../style";

const UserStats = (props: { stats: ApplausesStats | undefined }) => {
  const { stats } = props;
  const { user } = useAuth();

  const UserProfile = React.useCallback(
    () =>
      user ? (
        <VStack gap="xs" alignItems="center" mb="xxs">
          <Avatar image={user.image} name={user.name} size="xl" />
          <VStack alignItems="center">
            <Text textStyle="subtitle02" textAlign="center" caps>
              <b>{user.name}</b>
            </Text>
            <Text textStyle="bodyInline" textAlign="center">
              {getUserFromEmail(user.email)}
            </Text>
          </VStack>
        </VStack>
      ) : (
        <></>
      ),
    [user]
  );

  const Stats = React.useCallback(() => {
    const {
      userClapsReceivedAllTime,
      userClapsReceivedLastFourWeeks,
      userClapsSentAllTime,
      userClapsSentLastFourWeeks,
    } = stats || {};
    return (
      <S.StatsContainer>
        <S.StatsColumn gap="xxs">
          <StatsData
            label="aplausos recibidos en total"
            value={userClapsReceivedAllTime}
          />
          <StatsData
            label="aplausos recibidos los últimos 28 días"
            value={userClapsReceivedLastFourWeeks}
          />
        </S.StatsColumn>
        <S.StatsColumn gap="xxs">
          <StatsData
            label="aplausos enviados en total"
            value={userClapsSentAllTime}
          />
          <StatsData
            label="aplausos enviados los últimos 28 días"
            value={userClapsSentLastFourWeeks}
          />
        </S.StatsColumn>
      </S.StatsContainer>
    );
  }, [stats]);

  return (
    <VStack gap="xs">
      <UserProfile />
      {stats ? <Stats /> : <Loader />}
    </VStack>
  );
};

export default UserStats;
