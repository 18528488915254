import styled from "styled-components";

const PersonWrapper = styled.div`
  position: relative;
`;

const Wreath = styled.div`
  position: absolute;
  left: 50%;
  top: 66%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const AnimationWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${(p) => p.theme.radii.l};
`;

const Animation = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  z-index: 0;
`;

export { PersonWrapper, Wreath, Animation, AnimationWrapper };
