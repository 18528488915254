import * as React from "react";
import { VStack, Text, Flex } from "@sqymagma/elements";
import { AnimatePresence } from "framer-motion";

import { Applause } from "@types";

import ApplauseComponent from "./Applause";

interface TimelineProps {
  applauses: Array<Applause>;
  refreshApplauses: () => void;
}

const Timeline = (props: TimelineProps) => {
  const { applauses } = props;

  const notApplausesYet = applauses.length === 0;

  return (
    <>
      {notApplausesYet && (
        <Flex justifyContent="center" pb="s">
          <Text textStyle="body" color="text03" textAlign="center">
            No hay aplausos que mostrar
          </Text>
        </Flex>
      )}

      <AnimatePresence>
        <VStack gap="s">
          {applauses.map((applause, index) => (
            <ApplauseComponent
              key={index}
              applause={applause}
              index={index}
              {...props}
            />
          ))}
        </VStack>
      </AnimatePresence>
    </>
  );
};

export default Timeline;
