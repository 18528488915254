import React, { useRef } from "react";
import { format, isToday } from "date-fns";
import { Flex, Text, HStack, VStack } from "@sqymagma/elements";

import { useApp } from "@contexts";
import { IconLink } from "@elements";
import { Event } from "@types";
import { useModal, useHandleClickOutside } from "@hooks";

import EventModal from "./EventModal";
import { getEventDateTimes } from "./utils";
import * as S from "./style";

const EventComponent = (props: Props) => {
  const {
    start,
    end,
    calendarName,
    responsePending,
    summary,
    meetingLink,
    isNextEvent,
    isLastFinishedEvent,
    time,
    selectedDay,
  } = props;

  const {
    config: { openVideoPopup },
  } = useApp();

  const { isOpen, toggleModal } = useModal(false);
  const wrapper = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target?.closest(".modal-container")) return;
    if (wrapper.current && wrapper.current.contains(e.target as Node)) return;
    toggleModal();
  };
  useHandleClickOutside(isOpen, handleClickOutside);

  const { startTime, endTime } = getEventDateTimes(
    { start: start.dateTime, end: end.dateTime },
    selectedDay
  );
  const nowTime = time && format(time, "HH:mm");
  const isEventToday =
    isToday(new Date(start.dateTime)) || isToday(new Date(end.dateTime));

  const LinkToVideo = () => (
    <IconLink
      action={() =>
        window.open(meetingLink, "_blank", openVideoPopup ? "popup" : undefined)
      }
      icon="video"
      small
      tooltip="Ir a la sala"
    />
  );

  const TimeMark = (props: { last?: boolean }) => {
    const { last = false } = props;
    return (
      <S.TimeMarkWrapper time={nowTime} last={last}>
        <S.TimeMarkJoint />
      </S.TimeMarkWrapper>
    );
  };

  return (
    <>
      {isEventToday && isNextEvent && <TimeMark />}
      <Flex height="100%" alignItems="center" position="relative" ref={wrapper}>
        <S.EventDetails onClick={toggleModal}>
          <HStack gap="xs">
            <Flex height="100%" alignItems="center">
              <S.CalendarMark
                calendarName={calendarName}
                isPending={responsePending}
              />
            </Flex>
            <VStack>
              <Text textStyle="tiny" caps>
                {startTime && `${startTime}`}
                {endTime && ` - ${endTime}`}
              </Text>
              <Text
                textStyle="body"
                weight="bold"
                style={{ lineHeight: "1.2em" }}
              >
                {summary}
              </Text>
            </VStack>
          </HStack>
        </S.EventDetails>
        <S.EventActionsWrapper>
          {meetingLink && <LinkToVideo />}
        </S.EventActionsWrapper>
        {isOpen && (
          <EventModal
            close={toggleModal}
            {...{ ...props, startTime, endTime }}
          />
        )}
      </Flex>
      {isEventToday && isLastFinishedEvent && <TimeMark last />}
    </>
  );
};

interface Props extends Event {
  isNextEvent: boolean;
  isLastFinishedEvent: boolean;
  time: Date;
  selectedDay: Date;
}

export default EventComponent;
