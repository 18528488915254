import React, { useState, useEffect } from "react";

import { getUserDataFromEmail } from "@helpers";
import { Avatar, Icon, Tooltip } from "@elements";
import { ONE_SECOND_IN_MS } from "@constants";
import { Person } from "@types";

import * as S from "../style";

interface PeopleProps {
  emails: Array<[string, number]>;
  people: Array<Person>;
}

const People = (props: PeopleProps) => {
  const { emails, people } = props;

  const [active, setActive] = useState(0);

  const names = emails
    .map((email, index, array) => {
      const name = getUserDataFromEmail(email[0], people)?.name?.split(" ")[0];
      const isPenultimate = index === array.length - 2;
      return isPenultimate ? `${name} y` : `${name},`;
    })
    .join(" ")
    .slice(0, -1);

  const user = getUserDataFromEmail(emails[active][0], people);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((state) => {
        const nextActive = state === emails.length - 1 ? 0 : state + 1;
        return nextActive;
      });
    }, 2.5 * ONE_SECOND_IN_MS);

    return () => clearInterval(interval);
  }, [active, emails.length]);

  return (
    <Tooltip content={names}>
      <S.PersonWrapper>
        <S.Wreath>
          <Icon name="wreath" width={94} />
        </S.Wreath>
        <Avatar image={user?.photo} name={user?.name} size="xl" border="gold" />
      </S.PersonWrapper>
    </Tooltip>
  );
};

export default People;
