import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";

const daySize = "35px";

const Day = styled.div<{
  isSelectedDay: boolean;
  isToday: boolean;
  isHoliday: boolean;
  isVacation: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: ${daySize};
  width: ${daySize};
  border-radius: 50%;
  color: ${(p) =>
    p.isSelectedDay
      ? p.theme.colors.text02
      : p.isHoliday || p.isVacation
      ? p.theme.colors.light
      : p.theme.colors.text01};
  background-color: ${(p) =>
    p.isSelectedDay ? p.theme.colors.secondaryBackground : "transparent"};
  ${theme("textStyle.body")};
  cursor: pointer;
  border: ${(p) => (p.isToday ? `1px solid ${p.theme.colors.text01}` : "none")};
  pointer-events: ${(p) => (p.isSelectedDay ? "none" : "all")};
  font-weight: ${(p) => (p.isSelectedDay ? "bold" : "normal")};

  ${(p) =>
    p.isHoliday &&
    !p.isSelectedDay &&
    css`
      background-color: ${p.theme.colors.holidays};
    `}

  ${(p) =>
    p.isVacation &&
    !p.isSelectedDay &&
    css`
      background-color: ${p.theme.colors.vacations};
    `}

  &:hover {
    font-weight: bold;
  }
`;

export { Day };
