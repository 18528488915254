import styled from "styled-components";
import { Flex } from "@sqymagma/elements";

const StatsContainer = styled(Flex)`
  gap: ${(p) => p.theme.spacing.xxs};
`;

const StatsColumn = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  gap: ${(p) => p.theme.spacing.xxs};
  border-radius: ${(p) => p.theme.radii.m};
`;

const StatsData = styled(Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xxxs};
  background-color: ${(p) => p.theme.colors.septenaryBackground};
  border-radius: ${(p) => p.theme.radii.m};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.xxs};
`;

export { StatsContainer, StatsColumn, StatsData };
