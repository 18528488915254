import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";
import { HStack } from "@sqymagma/elements";

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled.div<{ active: boolean }>`
  ${theme("textStyle.bodyInline")};
  display: flex;
  gap: ${(p) => p.theme.spacing.xxxs};
  align-items: center;
  white-space: nowrap;
  background-color: ${(p) => p.theme.colors.quaternaryBackground};
  border: 1px solid ${(p) => p.theme.colors.quaternaryBackground};
  color: ${(p) => p.theme.colors.text01};
  border-radius: ${(p) => p.theme.radii.l};
  padding: ${(p) => (p.active ? "1px" : p.theme.spacing.xxxs)}
    ${(p) => p.theme.spacing.xxs};
  cursor: pointer;

  &:hover {
    background-color: ${(p) => `${p.theme.colors.ui01}88`};
    border: 1px solid ${(p) => p.theme.colors.ui01};
  }

  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => `${p.theme.colors.ui01}88`};
      border: 1px solid ${(p) => p.theme.colors.ui01};
    `}
`;

const SelectorBox = styled.div`
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 0;
  transform: translate(
    ${(p) => p.theme.spacing.xxs},
    ${(p) => p.theme.spacing.xxs}
  );
  background-color: ${(p) => p.theme.colors.senaryBackground};
  border-radius: ${(p) => p.theme.radii.m};
  padding: ${(p) => p.theme.spacing.xs};
  box-shadow: ${(p) => p.theme.shadows.small};
  min-width: 180px;
  max-height: 300px;
  overflow-y: scroll;
`;

const PeopleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing.xxs};
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const Person = styled(HStack)`
  cursor: pointer;
  width: 100%;

  &:hover span {
    color: ${(p) => p.theme.colors.ui01};
  }
`;

export { Wrapper, Button, SelectorBox, PeopleWrapper, Person };
