import React from "react";
import { Text, Box, Columns, Column } from "@sqymagma/elements";

import { Form } from "@components";
import { ReactComponent as FeedbackImg } from "@images/feedback.svg";

const Feedback = () => {
  const form = {
    placeholder: "Cuéntanos tu sugerencia",
    sentMessage: {
      title: "Gracias por tu sugerencia",
      subtitle: "La sacaremos a la luz en el próximo WeLog",
    },
    errorMessage:
      "No se pudo enviar el mensaje. Por favor, vuelve a intentarlo.",
  };

  return (
    <Columns style={{ overflow: "visible" }} hs="s">
      <Column width={[1, 1, 3 / 5]}>
        <Box>
          <Text textStyle="display02" color="text01" weight="bold">
            Buzón de sugerencias
          </Text>
        </Box>
        <Box mt="xs">
          <Text textStyle="bodyLead" color="text01">
            Si tienes alguna sugerencia para hacer una Secuoyas mejor, este es
            el lugar para enviarlas y recogerlas. Las sugerencias son anónimas
            aunque hayas iniciado sesión, y llegarán al correo{" "}
            <strong>sugerencias@secuoyas.com</strong>.
          </Text>
        </Box>
        <Form
          template="feedback"
          placeholder={form.placeholder}
          sentMessage={form.sentMessage}
          errorMessage={form.errorMessage}
        />
      </Column>
      <Column width={[1, 1, 2 / 5]}>
        <Box px={["s", "l"]} pt={["s", "m"]}>
          <FeedbackImg />
        </Box>
      </Column>
    </Columns>
  );
};

export default Feedback;
