import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const activeClassName = "active";

const Submenu = styled.div<{ hasActive: boolean; bottom?: boolean }>`
  visibility: hidden;
  opacity: 0;
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  width: calc(100% + ${(p) => (p.hasActive ? p.theme.spacing.xxl : 0)});
  background-color: ${(p) => p.theme.colors.secondaryBackground};
  position: absolute;
  color: ${(p) => p.theme.colors.text02};
  border-radius: ${(p) => p.theme.radii.m};
  left: 46px;
  transition: all ${(p) => p.theme.timing.s};
  text-align: left;

  ${(p) =>
    p.bottom
      ? css`
          bottom: 0;
          border-bottom-left-radius: 0;
        `
      : css`
          top: 0;
          border-top-left-radius: 0;
        `}

  &:hover {
    visibility: visible;
    opacity: 1;
  }
`;

const SubmenuTitle = styled.span`
  ${(p) => p.theme.textStyle.headingxs};
  text-transform: uppercase;
  white-space: nowrap;
  pointer-events: none;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SubmenuOption = styled(NavLink as any)<{ active: boolean | undefined }>`
  display: flex;
  align-content: center;
  justify-content: space-between;
  ${(p) => p.theme.textStyle.body};
  margin-top: ${(p) => p.theme.spacing.xxs};
  white-space: nowrap;
  cursor: pointer;
  font-weight: ${(p) => (p.active ? "bold" : "inherit")};

  &:hover {
    font-weight: bold;
  }
`;

const MenuLinkTitle = styled.div`
  visibility: hidden;
  opacity: 0;
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  background-color: ${(p) => p.theme.colors.secondaryBackground};
  position: absolute;
  color: ${(p) => p.theme.colors.text02};
  border-top-right-radius: ${(p) => p.theme.radii.m};
  border-bottom-right-radius: ${(p) => p.theme.radii.m};
  height: 100%;
  left: 100%;
  top: 0;
  transition: all ${(p) => p.theme.timing.s};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(p) => p.theme.textStyle.headingxs};
  text-transform: uppercase;
  white-space: nowrap;
  pointer-events: none;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MenuLink = styled(NavLink as any).attrs({ activeClassName })`
  display: inline-block;
  padding: ${(p) => p.theme.spacing.xxs};
  border-radius: ${(p) => p.theme.radii.m};
  transition: all ${(p) => p.theme.timing.s};
  cursor: pointer;

  &:hover {
    & + ${Submenu}, & + ${MenuLinkTitle} {
      visibility: visible;
      opacity: 1;
    }
  }

  &.${activeClassName} {
    background-color: ${(p) => p.theme.colors.secondaryBackground};

    svg {
      fill: ${(p) => p.theme.colors.text02};
    }
  }
`;

const MenuLinkWrapper = styled.li`
  position: relative;
  display: inline-block;

  &:hover {
    & > ${MenuLink} {
      background-color: ${(p) => p.theme.colors.secondaryBackground};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      svg {
        fill: ${(p) => p.theme.colors.text02};
      }
    }
  }
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export {
  Submenu,
  SubmenuTitle,
  SubmenuOption,
  MenuLinkTitle,
  MenuLink,
  MenuLinkWrapper,
  Label,
};
