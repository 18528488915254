import { Menu } from "@types";

const menu: Array<Menu> = [
  { icon: "home", title: "Inicio", to: "/" },
  { icon: "calendar", title: "Calendario", to: "/calendar" },
  {
    icon: "video",
    title: "Salas de vídeo",
    to: "/meetings",
  },
  {
    icon: "brush",
    title: "Herramientas",
    to: "/tools",
    submenu: {
      options: [
        { title: "Operativas", to: "/tools/operational" },
        { title: "Comunicación", to: "/tools/communication" },
        { title: "Gestión", to: "/tools/management" },
        { title: "Claves de acceso", to: "/tools/passwords" },
      ],
    },
  },
  {
    icon: "bookOpen",
    title: "Biblioteca",
    to: "/library",
    submenu: {
      options: [
        {
          title: "Libros",
          externalUrl:
            "https://drive.google.com/drive/folders/0Bwg6XlShuRmvLTFvVDZ3eXk4bFk",
        },
        { title: "Vídeos", externalUrl: "https://vimeo.com/secuoyas" },
        { title: "Hubbles", to: "/library/hubbles" },
        { title: "Taxonomy", to: "/library/taxonomy" },
      ],
    },
  },
  { icon: "link", title: "Acortador Url", to: "/shorturls" },
  {
    icon: "book",
    title: "Atlas",
    externalUrl: "https://atlas.secuoyas.net/",
  },
  { icon: "bulb", title: "Sugerencias", to: "/feedback" },
];

export default menu;
