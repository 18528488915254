import React, { useLayoutEffect, useState } from "react";
import { HStack, Text } from "@sqymagma/elements";

import { IconLink } from "@elements";
import { ShortUrl } from "@types";

import Row from "./Row";
import * as S from "./style";

const itemsPerPage = 5;

const List = (props: Props) => {
  const { data } = props;

  const [page, setPage] = useState(0);
  const [pageItems, setPageItems] = useState(data.slice(itemsPerPage - 1));

  const totalPages = data.length / itemsPerPage - 1;
  const firstPageItem = itemsPerPage * page;
  const lastPageItem =
    itemsPerPage * page +
    (page < totalPages
      ? itemsPerPage
      : data.length % itemsPerPage || itemsPerPage) -
    1;

  useLayoutEffect(() => {
    setPageItems(data.slice(firstPageItem, lastPageItem + 1));
  }, [page, data, firstPageItem, lastPageItem]);

  const previousPage = () => page > 0 && setPage((page) => page - 1);
  const nextPage = () => page < totalPages && setPage((page) => page + 1);

  if (!pageItems.length)
    return (
      <Text textStyle="body" color="text01">
        Aún no tienes ninguna URL acortada
      </Text>
    );

  return (
    <>
      <S.Wrapper>
        <S.HeaderRow>
          <S.HeaderCell>Url corta</S.HeaderCell>
          <S.HeaderCell>Url original</S.HeaderCell>
          <S.HeaderCell>Fecha</S.HeaderCell>
          <S.HeaderCell>Clics</S.HeaderCell>
        </S.HeaderRow>
        {pageItems.map((row, index) => (
          <Row data={row} key={index} />
        ))}
      </S.Wrapper>
      <S.Wrapper>
        <S.Footer>
          {firstPageItem + 1}-{lastPageItem + 1} de {data.length}
          <HStack gap="xxs" ml="xs">
            <IconLink icon="arrowLeft" small inverted action={previousPage} />
            <IconLink icon="arrowRight" small inverted action={nextPage} />
          </HStack>
        </S.Footer>
      </S.Wrapper>
    </>
  );
};

interface Props {
  data: ShortUrl[];
}

export default List;
