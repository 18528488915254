import React, { useEffect, useState } from "react";
import { Text, Box, VStack, Flex } from "@sqymagma/elements";

import { Password } from "@types";
import { useToast } from "@contexts";
import { googleApi } from "@services";
import { Loader, IconLink, SearchBar } from "@elements";
import { arrayToObject, removeColsFromArr } from "@helpers";
import { useSearch } from "@hooks";

import * as S from "./style";

// TODO: decouple the datasheet into a separate component so that it can be reused

const Passwords = () => {
  const { addToast } = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [passwords, setPasswords] = useSearch<Password>([], {
    searchQuery,
    searchFields: ["account", "user"],
  });

  const clipboardCopy = (text: string) => navigator.clipboard.writeText(text);
  const openUrl = () =>
    window.open(process.env.REACT_APP_PASSWORDS_GSHEET_URL, "_blank");

  useEffect(() => {
    const { REACT_APP_PASSWORDS_GSHEET_ID: spreadsheetId } = process.env;
    if (!spreadsheetId) return;
    const payload = { spreadsheetId, range: "SQY!A3:D1027" };
    googleApi.getSheet(payload).then((rows) => {
      if (rows) {
        const values = removeColsFromArr(rows, [1]);
        const keys = ["account", "user", "password"];
        const rowsObjects = values?.map((row: string[]) =>
          arrayToObject<Password>(row, keys)
        );
        setPasswords(rowsObjects);
      }
    });
  }, [setPasswords]);

  const Cell = (props: {
    children: string;
    clickable?: boolean;
    header?: boolean;
  }) => {
    const { children, clickable, header } = props;

    const handleClick = () => {
      clipboardCopy(children);
      const toastText = (
        <span>
          <strong>{children}</strong> copiado en el portapapeles
        </span>
      );
      addToast(toastText);
    };

    return (
      <S.Cell>
        {header && <S.Header>{children}</S.Header>}
        {!header &&
          (clickable ? (
            <S.Data onClick={handleClick}>{children}</S.Data>
          ) : (
            <S.Data>{children}</S.Data>
          ))}
      </S.Cell>
    );
  };

  return (
    <>
      <Text textStyle="display02" color="text01" weight="bold">
        Claves de acceso
      </Text>

      <Box mt="m" px={[0, 0, "s", "xxxl"]}>
        <Flex alignItems="center" justifyContent="space-between" my="xs">
          <Box width="100%" maxWidth="390px" mr="s">
            <SearchBar
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Busca una cuenta o usuario"
            />
          </Box>
          <IconLink
            action={openUrl}
            icon="sheets"
            tooltip="Abrir en Google Sheets"
            inverted
          />
        </Flex>

        <VStack>
          {passwords.map((row, idx) => {
            const { account, user, password } = row;
            const isHeader = !user && !password;
            if (isHeader && searchQuery.length) return <></>;
            return (
              <S.Row key={idx} cellsSize={["40%", "30%", "30%"]}>
                <Cell header={isHeader}>{account}</Cell>
                <Cell header={isHeader} clickable={!isHeader}>
                  {user}
                </Cell>
                <Cell header={isHeader} clickable={!isHeader}>
                  {password}
                </Cell>
              </S.Row>
            );
          })}
        </VStack>

        {!passwords.length && !searchQuery.length && (
          <Flex justifyContent="center">
            <Loader />
          </Flex>
        )}
      </Box>
    </>
  );
};

export default Passwords;
