import React, { useEffect, useRef, useState } from "react";

import { Note } from "@elements";

import * as S from "./style";

const TextField = (props: Props) => {
  const {
    value,
    onChange,
    onEnter,
    placeholder,
    small,
    prefix,
    note,
    autofocus,
  } = props;

  const [showNote, setShowNote] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!note) return;
    if (document.activeElement === ref?.current) {
      setShowNote(!value.length);
    }
  }, [value, note]);

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!note) return;
    if (e.type === "blur") {
      setShowNote(false);
    } else {
      setShowNote(!value.length);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };

  const handleOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!onEnter) return;
    if (e.key === "Enter") {
      onEnter(value);
    }
  };

  return (
    <S.Wrapper>
      {note && <Note open={showNote}>{note}</Note>}
      {prefix && (
        <S.Prefix prefix={prefix} small={small}>
          {prefix}
        </S.Prefix>
      )}
      <S.Input
        value={value}
        onChange={handleOnChange}
        onKeyPress={handleOnEnter}
        placeholder={placeholder}
        small={small}
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleFocus}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autofocus}
      />
    </S.Wrapper>
  );
};

interface Props {
  value: string;
  onChange: (value: string) => void;
  onEnter?: (value: string) => void;
  placeholder?: string;
  small?: boolean;
  prefix?: string;
  note?: string;
  autofocus?: boolean;
}

export default TextField;
