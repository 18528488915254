const {
  REACT_APP_YOURLS_API_URL: yourlsUrl,
  REACT_APP_YOURLS_API_USERNAME: yourlsUser,
  REACT_APP_YOURLS_API_PASSWORD: yourlsPassword,
} = process.env;

const yourls = async (props: YourlsProps) => {
  if (!yourlsUrl || !yourlsUser || !yourlsPassword) return;
  try {
    const { action = "shorturl", url, keyword, shorturl } = props;
    const requestUrl = new URL(yourlsUrl);
    const params = {
      username: yourlsUser,
      password: yourlsPassword,
      format: "json",
      action,
      ...(!!url && { url }),
      ...(!!keyword && { keyword }),
      ...(!!shorturl && { shorturl }),
      ...(action === "stats" && {
        limit: Number.MAX_SAFE_INTEGER.toString(),
        filter: "last",
      }),
    };
    requestUrl.search = new URLSearchParams(params).toString();
    const response = await fetch(requestUrl);
    return await response.json();
  } catch (error) {
    console.warn(`Error while shortening the URL.`, error);
  }
};

const yourlsApi = { yourls };

interface YourlsProps {
  action?: string;
  url?: string;
  keyword?: string;
  shorturl?: string;
}

export default yourlsApi;
