import styled, { css } from "styled-components";
import { Box } from "@sqymagma/elements";

const EventData = styled.div`
  max-height: 300px;
  overflow: scroll;
`;

const EventModal = styled(Box)<{ $height: number; $fixOutOfBounds: number }>`
  position: absolute;
  width: calc(
    100% + ${(p) => p.theme.spacing.xs} + ${(p) => p.theme.spacing.xs}
  );
  left: 50%;
  margin-left: calc(-50% - ${(p) => p.theme.spacing.xs});
  background-color: ${(p) => p.theme.colors.tertiaryBackground};
  overflow: hidden;
  border-radius: ${(p) => p.theme.radii.m};
  transition: all ${(p) => p.theme.timing.xs} ease-in;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadows.medium};
  animation: zoomIn ${(p) => p.theme.timing.xs};
  transform: translateY(
    calc(
      -50% + ${(p) => `${p.$height / 2}px`} - ${(p) => `${p.$fixOutOfBounds}px`}
    )
  );

  & ${EventData} .event-data-icon > svg {
    fill: ${(p) => p.theme.colors.text01};
  }
`;

const TopActions = styled.div<{ shadow: boolean }>`
  display: flex;
  justify-content: end;
  padding: ${(p) => p.theme.spacing.xxs};
  border-bottom: 1px solid ${(p) => p.theme.colors.tertiaryBackground};
  ${(p) =>
    p.shadow &&
    css`
      border-bottom: 1px solid ${(p) => p.theme.colors.line05};
      box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
    `}
`;

const BottomActions = styled.div<{ shadow: boolean }>`
  display: flex;
  justify-content: center;
  padding: ${(p) => p.theme.spacing.xxs} 0;
  border-top: 1px solid ${(p) => p.theme.colors.tertiaryBackground};
  ${(p) =>
    p.shadow &&
    css`
      border-top: 1px solid ${(p) => p.theme.colors.line05};
      box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
    `}
`;

const AttendeesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing.xxxs};
`;

export { EventModal, TopActions, BottomActions, EventData, AttendeesWrapper };
