import styled, { createGlobalStyle, css } from "styled-components";

const getWidth = (size: string | undefined) => {
  switch (size) {
    case "S":
      return "460px";
    case "M":
      return "640px";
    case "L":
      return "1088px";
    default:
      return "512px";
  }
};

const getHeight = (size: string | undefined) => {
  switch (size) {
    case "S":
      return "240px";
    case "M":
      return "384px";
    case "L":
      return "640px";
    default:
      return "320px";
  }
};

const ModalOverlay = styled.div<{ isChild?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(p) => (p.isChild ? "1060" : "1040")};
  width: 100vw;
  height: 100vh;
  background: ${(p) => p.theme.colors.overlayDark01};
  body.modal-open:not(&) {
    overflow: hidden;
  }
`;

const ModalWrapper = styled.div<{ isChild?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(p) => (p.isChild ? "1070" : "1050")};
  width: 100%;
  height: 100%;
  outline: 0;
`;

const Modal = styled.div<{
  width?: string;
  height?: string;
  size?: string;
}>`
  z-index: 100;
  display: flex;
  flex-flow: column nowrap;
  background: ${(p) => p.theme.colors.tertiaryBackground};
  position: relative;
  margin: auto;
  border-radius: ${(p) => p.theme.radii.m};
  width: ${(p) => p.width || getWidth(p.size)};
  max-width: calc(100vw - ${(p) => p.theme.spacing.xs} * 2);
  height: ${(p) => p.height || getHeight(p.size)};
  box-shadow: ${(p) => p.theme.shadows.big};
  top: 50%;
  transform: translateY(-50%);
`;

const ModalHeader = styled.div<{ lightHeader?: boolean }>`
  background: ${(p) =>
    p.lightHeader
      ? p.theme.colors.tertiaryBackground
      : p.theme.colors.secondaryBackground};
  display: flex;
  padding: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.m} ${(p) => p.theme.radii.m} 0 0;
`;

const ModalContent = styled.div<{ allowOverflow?: boolean }>`
  position: relative;
  flex-grow: 1;
  overflow: ${(p) => (p.allowOverflow ? "visible" : "auto")};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headings};
  color: ${(p) => p.theme.colors.text02};
`;

const ButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${(p) =>
    `0 ${p.theme.spacing.xs} ${p.theme.spacing.xs} ${p.theme.spacing.xs}`};
  button:not(:first-child) {
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

const LockBody = createGlobalStyle<{ lock?: boolean }>`
  body {
    ${(p) =>
      p.lock &&
      css`
        overflow: hidden;
      `}
  }
`;

export {
  ModalOverlay,
  ModalWrapper,
  Modal,
  ModalHeader,
  ModalContent,
  Title,
  ButtonWrapper,
  ModalFooter,
  LockBody,
};
