const WEEK_DAYS = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;

const THEMES = {
  light: "default-theme",
  dark: "dark-theme",
  system: "system-theme",
};

export { WEEK_DAYS, ONE_SECOND_IN_MS, ONE_MINUTE_IN_MS, THEMES };
