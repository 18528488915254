import React, { useRef } from "react";
import { createPortal } from "react-dom";

import { useHandleClickOutside } from "@hooks";
import { IconLink } from "@elements";

import * as S from "./style";

const Modal = (props: Props) => {
  const { isOpen, hide, children, title } = props;

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (modalRef.current && modalRef.current.contains(e.target as Node)) return;
    hide();
  };
  useHandleClickOutside(isOpen, handleClickOutside);

  return isOpen
    ? createPortal(
        <S.Modal ref={modalRef}>
          <S.ModalHeader>
            <S.Title>{title}</S.Title>
            <S.ButtonWrapper>
              <IconLink icon="close" action={hide} small inverted />
            </S.ButtonWrapper>
          </S.ModalHeader>
          <S.ModalContent>{children}</S.ModalContent>
        </S.Modal>,
        document.body
      )
    : null;
};

interface Props {
  isOpen: boolean;
  hide: () => void;
  children: JSX.Element;
  title: string;
}

export default Modal;
