import { motion } from "framer-motion";
import styled from "styled-components";

const Wrapper = styled.div<{ $clickable: boolean }>`
  position: relative;
  cursor: ${(p) => (p.$clickable ? "pointer" : "default")};
`;

const BadgeWrapper = styled.div<{ children: JSX.Element }>`
  position: absolute;
  z-index: 1;
  top: -8px;
  left: 100%;
`;

const Menu = styled(motion.div)`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 22px;
  border-radius: ${(p) => p.theme.radii.s};
  border: 1px solid ${(p) => p.theme.colors.line01};
  overflow-y: scroll;
  box-shadow: ${(p) => p.theme.shadows.small};
  max-width: 290px;
  max-height: 400px;
  background-color: ${(p) => p.theme.colors.tertiaryBackground};
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.xxs};
  padding: ${(p) => p.theme.spacing.xxs};
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.colors.interactive04Hover};
  }
  &:not(:first-child) {
    border-top: 1px solid ${(p) => p.theme.colors.line07};
  }
`;

const TextWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xxxs};
`;

export { Wrapper, BadgeWrapper, Menu, MenuItem, TextWrapper };
