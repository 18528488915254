const createImg = (url: string, size?: number) => {
  const image = new Image();
  image.src = url;
  if (size) {
    image.width = size;
    image.height = size;
  }
  return image;
};

const getBlobFromFile = (file: File): Promise<Blob | null | ""> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const blob =
        reader.result && new Blob([reader.result], { type: file.type });
      resolve(blob);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export { createImg, getBlobFromFile };
