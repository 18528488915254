import React, { useCallback, useEffect, useState } from "react";
import { Text, Box, Flex, Columns, Column } from "@sqymagma/elements";
import { XMasonry, XBlock } from "react-xmasonry";

import { googleApi } from "@services";
import { Loader, SearchBar, Select } from "@elements";
import { arrayToObject, getYearFromDate } from "@helpers";
import { useSearch } from "@hooks";
import { SelectOption, Taxonomy } from "@types";

import Item from "./Item";
import * as S from "./style";

const TaxonomyComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [taxonomies, setTaxonomies] = useSearch<Taxonomy>([], {
    searchQuery,
    searchFields: ["title", "description"],
  });
  const [yearFilter, setYearFilter] = useState<SelectOption>(null);
  const [numberOfTaxonomies, setNumberOfTaxonomies] = useState<number>();

  useEffect(() => {
    const { REACT_APP_TAXONOMY_GSHEET_ID: spreadsheetId } = process.env;
    if (!spreadsheetId) return;
    const payload = { spreadsheetId, range: "A2:E1000" };
    googleApi.getSheet(payload).then((rows) => {
      const keys = ["title", "description", "date", "url", "image"];
      const rowsObjects = rows?.map((row: string[]) =>
        arrayToObject<Taxonomy>(row, keys)
      );
      if (rowsObjects) {
        setTaxonomies(rowsObjects.reverse());
        setNumberOfTaxonomies(rowsObjects.length);
      }
    });
  }, [setTaxonomies]);

  const handleYearFilter = (value: SelectOption) => {
    setYearFilter(value);
  };

  const yearOptions = useCallback(() => {
    const years = taxonomies.map((taxonomy) => getYearFromDate(taxonomy.date));
    return [...new Set(years)].map((year) => ({ value: year, label: year }));
  }, [taxonomies]);

  const filteredTaxonomies = taxonomies.filter((taxonomy) => {
    const year = getYearFromDate(taxonomy.date);
    return !yearFilter || year === yearFilter.value;
  });

  const noResults = !filteredTaxonomies.length && !!searchQuery.length;
  const showingMessage =
    numberOfTaxonomies &&
    `Mostrando ${filteredTaxonomies.length} de ${numberOfTaxonomies}`;

  const NoSearchResults = () => (
    <Text textStyle="body" color="text01">
      No se ha encontrado ninguna taxonomy en la búsqueda
    </Text>
  );

  const Loading = () => (
    <Flex justifyContent="center">
      <Loader />
    </Flex>
  );

  return (
    <>
      <Box>
        <Text textStyle="display02" color="text01" weight="bold">
          Taxonomy
        </Text>
      </Box>
      <Box width={{ default: "100%", xl: "60%", xxl: "50%" }}>
        <Box mt="xs">
          <Text textStyle="bodyLead" color="text01">
            Si te has perdido algún número de nuestra newsletter interna o
            quieres volver a echarle un vistazo a alguna, aquí puedes encontrar
            todas las que hemos enviado hasta el momento.
          </Text>
        </Box>
      </Box>

      <Box mt="m">
        <Columns hs="s" vs="xxs" overflow="visible" alignItems="center">
          <Column width={[1, 1, 1 / 2, 1 / 2, 1 / 3]}>
            <SearchBar
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Busca una taxonomy"
            />
          </Column>
          <Column width={[1, 1, 1 / 2, 1 / 4, 1 / 5]}>
            <Select
              value={yearFilter}
              options={yearOptions()}
              onChange={handleYearFilter}
              placeholder="Todos los años"
            />
          </Column>
          <Column>
            {showingMessage && (
              <Text textStyle="bodyInline" color="text03">
                {showingMessage}
              </Text>
            )}
          </Column>
        </Columns>
      </Box>

      <Box mt="l">
        {filteredTaxonomies?.length ? (
          <S.Masonry>
            <XMasonry responsive targetBlockWidth={450} center={false}>
              {filteredTaxonomies.map((taxonomy) => (
                <XBlock key={taxonomy.url}>
                  <Item {...taxonomy} />
                </XBlock>
              ))}
            </XMasonry>
          </S.Masonry>
        ) : noResults ? (
          <NoSearchResults />
        ) : (
          <Loading />
        )}
      </Box>
    </>
  );
};

export default TaxonomyComponent;
