import * as React from "react";
import { Item, Nav, CustomButton, Player, WrappedHeader } from "./style";
import { NavLink } from "react-router-dom";
import { Icon } from "@elements";

interface StepsProps {
  active: number;
  total: number;
  duration: Array<number>;
}

const Steps = ({ active, total, duration }: StepsProps) => {
  return (
    <WrappedHeader>
      <Nav>
        {new Array(total).fill(0).map((value, index) => (
          <Item
            key={index}
            $active={active === index}
            $duration={duration[index] || 9000}
          />
        ))}
      </Nav>

      <Player>
        {/* <CustomButton>
          <Icon name="wrappedPause" width={32} />
        </CustomButton> */}
        <CustomButton onClick={() => window.location.reload()}>
          <Icon name="wrappedRestart" width={32} />
        </CustomButton>
        <NavLink to="/">
          <Icon name="wrappedClose" width={32} />
        </NavLink>
      </Player>
    </WrappedHeader>
  );
};

export default Steps;
