import { useEffect, useRef } from "react";

const useEffectExceptOnMount = (
  effect: () => unknown,
  dependencies: unknown[]
) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export { useEffectExceptOnMount };
