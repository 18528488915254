import React from "react";

import * as icons from "@icons/index";
import * as S from "./style";

const sizes = {
  xs: 12,
  small: 20,
  medium: 30,
  large: 40,
};

const Icon = (props: IconProps) => {
  const { name, size, width, height, color } = props;

  const widthPx = size ? sizes[size] : width;
  const heightPx = size ? sizes[size] : height;

  if (name && icons[name] !== undefined) {
    const IconComponent = icons[name];
    return (
      <S.IconWrapper $color={color}>
        <IconComponent width={widthPx} height={heightPx} />
      </S.IconWrapper>
    );
  }

  console.error(`The Icon ${name} doesn't exist in the icon library.`);
  return null;
};

export type IconNames = keyof typeof icons;

interface IconProps extends React.ComponentPropsWithoutRef<"div"> {
  name: IconNames;
  size?: keyof typeof sizes;
  width?: number;
  height?: number;
  color?: string;
}

export default Icon;
