import { Tool } from "@types";

interface Tools {
  [x: string]: {
    name: string;
    types: Array<{ name?: string; items: Tool[] }>;
  };
}

const tools: Tools = {
  operational: {
    name: "Operativas",
    types: [
      {
        name: "Diseño",
        items: [
          {
            id: "figma",
            name: "Figma",
            url: "https://www.figma.com/files/recent",
          },
        ],
      },
      {
        name: "Documentación",
        items: [
          {
            id: "drive",
            name: "Google Drive",
            url: "https://drive.google.com/drive/my-drive",
          },
          {
            id: "notion",
            name: "Notion",
            url: "https://www.notion.so/",
          },
        ],
      },
      {
        name: "Dev",
        items: [
          {
            id: "github",
            name: "Github",
            url: "https://github.com/Secuoyas-Experience",
          },
          {
            id: "bitbucket",
            name: "Bitbucket",
            url: "https://bitbucket.org/dashboard/overview",
          },
        ],
      },
      {
        name: "Marketing",
        items: [
          {
            id: "analytics",
            name: "Google Analytics",
            url: "https://analytics.google.com/",
          },
          {
            id: "tagManager",
            name: "Tag Manager",
            url: "https://tagmanager.google.com/",
          },
          {
            id: "easymailing",
            name: "Easymailing",
            url: "https://easymailing.com/",
          },
          {
            id: "hotjar",
            name: "Hotjar",
            url: "https://www.hotjar.com/",
          },
          {
            id: "semrush",
            name: "Semrush",
            url: "https://es.semrush.com/",
          },
        ],
      },
      {
        name: "Otros",
        items: [
          {
            id: "droove",
            name: "IO",
            url: "http://droove.es/",
          },
        ],
      },
    ],
  },
  communication: {
    name: "Comunicación",
    types: [
      {
        items: [
          {
            id: "chat",
            name: "Google Chat",
            url: "https://chat.google.com/",
          },
          {
            id: "slack",
            name: "Slack",
            url: "https://slack.com/intl/es-es/",
          },
          {
            id: "gmail",
            name: "Gmail",
            url: "https://mail.google.com/mail/u/0/#inbox",
          },
          {
            id: "telegram",
            name: "Telegram",
            url: "https://web.telegram.org/",
          },
          {
            id: "gather",
            name: "Gather",
            url: "https://app.gather.town/invite?token=66br1mpEzW3Q0g07aoMPcznaFJfd2Ggi",
          },
        ],
      },
    ],
  },
  management: {
    name: "Gestión",
    types: [
      {
        items: [
          {
            id: "trello",
            name: "Trello",
            url: "https://trello.com/secuoyas/home",
          },
          {
            id: "clubhouse",
            name: "Shortcut",
            url: "https://app.shortcut.com/secuoyas/dashboard",
          },
          {
            id: "taiga",
            name: "Taiga",
            url: "https://tree.taiga.io/",
          },
          {
            id: "hubplanner",
            name: "Hubplanner",
            url: "https://secuoyas.hubplanner.com/auth#/login",
          },
        ],
      },
      {
        name: "Consulta",
        items: [
          {
            id: "claves",
            name: "Accesos y claves",
            url: "https://docs.google.com/spreadsheets/d/1HWeTih7h7e61zunU4V0Z5hR8haPLW1vACNw_5nZiI1w/edit#gid=380676741",
          },
          {
            id: "vacaciones",
            name: "Vacaciones",
            url: "https://docs.google.com/spreadsheets/d/1Cj9wrxvStkpuXkGzinh6VX1JsU9wTC2mwoKJ2GkgXoo/edit",
          },
        ],
      },
    ],
  },
};

export default tools;
