import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import { Box } from "@sqymagma/elements";
import { AnimatePresence, motion } from "framer-motion";
import "@lottiefiles/lottie-player";

import { ONE_SECOND_IN_MS } from "@constants";

const animations = {
  applause:
    "https://lottie.host/87190a21-3f16-440a-a035-847a0bb3563a/vZsTzr8uFp.json",
  confetti:
    "https://lottie.host/0ee68722-5d0a-429e-82e5-745209ee4672/GVN0rLkMaw.json",
  lights:
    "https://lottie.host/883f9866-5327-44d2-9099-8063e245c084/KqPP7Y4k13.json",
  santa:
    "https://lottie.host/71ecdd2d-00cd-4a59-b041-1c3431ffd008/TnXDaGO9dM.json",
};

const Animation = (props: AnimationProps) => {
  const {
    name,
    modal,
    duration,
    animate = true,
    onFinish,
    width,
    height,
  } = props;

  // fetch json to caching it
  React.useEffect(() => {
    try {
      fetch(animations[name], { cache: "force-cache" });
    } catch (error) {
      console.warn(error);
    }
  }, [name]);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (animate && duration && onFinish) {
      timeout = setTimeout(() => {
        onFinish();
      }, duration * ONE_SECOND_IN_MS);
    }
    return () => timeout && clearTimeout(timeout);
  }, [duration, onFinish, animate]);

  const LottiePlayer = useCallback(
    () => (
      <lottie-player
        background="Transparent"
        speed="1"
        direction="1"
        mode="normal"
        loop
        autoplay
        src={animations[name]}
        style={{ width, height }}
      ></lottie-player>
    ),
    [height, name, width]
  );

  const renderAnimation = modal ? (
    createPortal(
      <Box
        position="fixed"
        left="50%"
        top="50%"
        style={{ transform: "translate(-50%,-50%)" }}
        zIndex={9}
      >
        <LottiePlayer />
      </Box>,
      document.body
    )
  ) : (
    <LottiePlayer />
  );

  return (
    <AnimatePresence>
      {animate && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {renderAnimation}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface AnimationProps {
  name: keyof typeof animations;
  duration?: number;
  animate?: boolean;
  onFinish?: () => void;
  modal?: boolean;
  width?: string;
  height?: string;
}

export default Animation;
