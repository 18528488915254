import React, { useEffect, useRef, useState } from "react";
import { VStack, Text, Flex } from "@sqymagma/elements";
import { useHistory } from "react-router-dom";

import { Modal } from "@components";
import { Button, Icon, Editor, Loader } from "@elements";
import { remoteApi } from "@services";
import { MoodReplyState } from "@types";
import MoodReply from "./MoodReply";

import * as S from "./style";

interface MoodReplyModalProps {
  id: string;
  repliesCount: number;
  moodContent: JSX.Element;
  isOpen: boolean;
  toggleModal: () => void;
  setRepliesCount: (count: number) => void;
  fromNotification?: boolean;
}

const MoodReplyModal = (props: MoodReplyModalProps) => {
  const {
    id,
    repliesCount,
    moodContent,
    isOpen,
    toggleModal,
    setRepliesCount,
    fromNotification,
  } = props;

  const history = useHistory();

  const isMountingRef = useRef(false);
  const [state, setState] = useState<string>("");
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [replies, setReplies] = useState<MoodReplyState[]>();

  useEffect(() => {
    isMountingRef.current = true;
    getReplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMountingRef.current) {
      setRepliesCount(replies?.length || 0);
    } else {
      isMountingRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replies]);

  const handleSendComment = async () => {
    setIsSending(true);
    const createOk = await remoteApi.createMoodReply(id, state);
    if (createOk) {
      setState("");
      getReplies();
    }
    setIsSending(false);
  };

  const getReplies = async () => {
    setIsLoading(true);
    await remoteApi.getMoodReplies(id).then((replies) => setReplies(replies));
    setIsLoading(false);
  };

  const closeModal = () => {
    toggleModal();
    if (fromNotification) {
      history.replace("/mood", { openMood: null });
    }
  };

  const placeholder = "Escribe aquí tu mensaje.";

  return (
    <Modal
      isOpen={isOpen}
      hide={closeModal}
      title=""
      width="600px"
      height="auto"
      lightHeader={true}
      allowOverflow
    >
      <S.Content pl="s" pb="s" pr="s">
        <VStack gap="xs">
          {moodContent}
          {repliesCount > 0 && (
            <S.Replays gap="xxxs">
              <Icon name="comment" width={20} height={20} />
              <Text textStyle="button" color="text01">
                {repliesCount}
              </Text>
            </S.Replays>
          )}
          {isLoading && !!repliesCount ? (
            <Loader />
          ) : (
            replies &&
            replies.length > 0 && (
              <VStack gap="xxs">
                {replies.map((reply, index) => (
                  <MoodReply
                    key={index}
                    reloadReplies={getReplies}
                    {...reply}
                  />
                ))}
              </VStack>
            )
          )}
          <S.EditorBox pt="s" mt="xxs">
            <Editor
              value={state}
              onChange={setState}
              placeholder={placeholder}
              buttons={["youtube"]}
            />
          </S.EditorBox>
          <Flex justifyContent="flex-end">
            <Button
              rightIcon="arrowRight2"
              disabled={isSending || !state.length}
              action={handleSendComment}
              animateHover
            >
              Enviar
            </Button>
          </Flex>
        </VStack>
      </S.Content>
    </Modal>
  );
};

export default MoodReplyModal;
