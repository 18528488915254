const stringToColor = (str: string, saturation = 100, lightness = 75) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

const darkenHexColor = (hex: string, factor: number) => {
  hex = hex.replace(/^#/, "");

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const darkening = 1 - factor;

  const newR = Math.max(0, Math.floor(r * darkening));
  const newG = Math.max(0, Math.floor(g * darkening));
  const newB = Math.max(0, Math.floor(b * darkening));

  const componentR = newR.toString(16).padStart(2, "0");
  const componentG = newG.toString(16).padStart(2, "0");
  const componentB = newB.toString(16).padStart(2, "0");

  const newColor = `#${componentR}${componentG}${componentB}`;

  return newColor;
};

export { stringToColor, darkenHexColor };
