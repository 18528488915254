import React, { useState } from "react";
import { Box, Text, VStack, Flex } from "@sqymagma/elements";

import { Modal } from "@components";
import { TextField } from "@elements";

const YoutubeModal = (props: {
  isOpen: boolean;
  toggleModal: () => void;
  action: (url: string) => void;
}) => {
  const { isOpen, toggleModal, action } = props;

  const [youtubeUrl, setYoutubeUrl] = useState("");

  const hide = () => {
    toggleModal();
    setYoutubeUrl("");
  };

  const handleAction = () => {
    action(youtubeUrl);
    hide();
  };

  return (
    <Modal
      isChild
      isOpen={isOpen}
      hide={hide}
      size="S"
      title="Añadir vídeo de YouTube"
      mainAction={{
        title: "Añadir",
        action: handleAction,
      }}
      secondaryAction={{ title: "Cancelar", action: hide }}
    >
      <VStack py="s" px="xs" gap="xs">
        <Text textStyle="body" color="text01">
          Escribe la URL del vídeo de YouTube que quieres añadir.
        </Text>
        <TextField
          value={youtubeUrl}
          onChange={setYoutubeUrl}
          placeholder="https://www.youtube.com/watch?v="
          autofocus
          onEnter={handleAction}
        />
      </VStack>
    </Modal>
  );
};

const LinkModal = (props: {
  isOpen: boolean;
  toggleModal: () => void;
  action: (url: string, link: string) => void;
}) => {
  const { isOpen, toggleModal, action } = props;

  const [url, setUrl] = useState("");
  const [text, setText] = useState("");

  const hide = () => {
    toggleModal();
    setUrl("");
    setText("");
  };

  const handleAction = () => {
    const isValidUrl = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/.*)?$/.test(
      url
    );
    if (isValidUrl) {
      action(url, text);
      hide();
    }
  };

  return (
    <Modal
      isChild
      isOpen={isOpen}
      hide={hide}
      size="S"
      title="Añadir enlace"
      mainAction={{
        title: "Añadir",
        action: handleAction,
      }}
      secondaryAction={{ title: "Cancelar", action: hide }}
    >
      <VStack py="s" px="xs" gap="xs">
        <Text textStyle="body" color="text01">
          Escribe la URL y el texto del enlace que quieres añadir.
        </Text>
        <Flex gap="xs">
          <Box flexGrow={1}>
            <TextField
              value={url}
              onChange={setUrl}
              placeholder="https://"
              autofocus
              onEnter={handleAction}
            />
          </Box>
          <Box ml="xs" flexGrow={1}>
            <TextField
              value={text}
              onChange={setText}
              placeholder="texto del enlace"
              onEnter={handleAction}
            />
          </Box>
        </Flex>
      </VStack>
    </Modal>
  );
};

export { YoutubeModal, LinkModal };
