import React from "react";
import { Text, Box } from "@sqymagma/elements";

const Header = ({ title }: { title: string }) => (
  <>
    <Box>
      <Text textStyle="display02" color="text01" weight="bold">
        Salas de vídeo
      </Text>
    </Box>
    <Box>
      <Text textStyle="bodyLead" color="text01">
        {title}
      </Text>
    </Box>
  </>
);

export { Header };
