import { HubPlannerResource, Person } from "@types";
import { invalidGoogleAccounts } from "@config";
import { getFullNameFromEmail } from "./emails";

const addPersonPhoto = <PersonType extends { email: string }>(
  originPerson: PersonType,
  people: Person[]
): PersonType & { photo?: string } => {
  const foundPerson = people.find(
    (person) => person.email === originPerson.email
  );
  if (!foundPerson) return originPerson;
  const { photo } = foundPerson;
  return { ...originPerson, photo };
};

const removeInvalidGoogleAccounts = (googlePeople: Person[] | undefined) => {
  return googlePeople?.filter(
    (person) =>
      !invalidGoogleAccounts.some((account) => account === person.email)
  );
};

const addNamesFromHubPlanner = (
  googlePeople: Person[] | undefined,
  hubPlannerResources: HubPlannerResource[]
) => {
  return googlePeople?.map((person) => {
    const hpResource = hubPlannerResources?.find(
      (resource) => resource.email === person.email
    );
    if (hpResource) {
      person.name = `${hpResource.firstName} ${hpResource.lastName}`;
    }
    return person;
  });
};

const sortByName = (a: Person, b: Person) =>
  a.name?.localeCompare(b.name || "") || 0;

const getUserDataFromEmail = (email: string, people: Person[]) => {
  const foundPerson = people.find((person) => person.email === email);
  if (!foundPerson) {
    const fullName = getFullNameFromEmail(email);
    return { email, name: fullName, photo: "" };
  }
  return foundPerson;
};

export {
  addPersonPhoto,
  removeInvalidGoogleAccounts,
  addNamesFromHubPlanner,
  sortByName,
  getUserDataFromEmail,
};
