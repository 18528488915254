import { Box, Button as MagmaButton } from "@sqymagma/elements";
import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";
import { motion } from "framer-motion";

const hoverAnimation = css`
  & .left-icon svg {
    transform: ${(p) => `translateX(-${p.theme.spacing.xxs})`};
  }
  & .right-icon svg {
    transform: ${(p) => `translateX(${p.theme.spacing.xxs})`};
  }
`;

const StyledButton = styled(MagmaButton)`
  position: relative;
  padding: ${(p) => p.theme.spacing.xxxs};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  background: transparent;
  border: none;
  transition: background-color ${(p) => p.theme.timing.hover};
  box-shadow: ${(p) => (p.shadow ? p.theme.shadows.small : "none")};

  ${(p) => p.appearance === "secondary" && Secondary}
  ${(p) => p.appearance === "tertiary" && Tertiary}

  &:focus {
    outline: none;
  }

  ${(p) => p.$hover && hoverAnimation}

  &:hover {
    ${(p) => !p.disabled && p.$animateHover && hoverAnimation}
    ${(p) =>
      !p.$animateHover &&
      p.appearance === "primary" &&
      css`
        span {
          color: ${(p) => p.theme.colors.interactive01Hover};
        }
        .left-icon svg,
        .right-icon svg {
          fill: ${(p) => p.theme.colors.interactive01Hover};
        }
      `}
  }

  svg {
    transition: transform 0.33s cubic-bezier(0.8, 0, 0.2, 1);
  }

  &:active {
    outline: none;
    box-shadow:
      0 0 0 0 transparent,
      0 0 0 0 transparent;
    color: ${theme("colors.textOnInteractive01Pressed")};
  }
`;

const IconPlace = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Span = styled.span<{ disabled: boolean }>`
  ${(p) => p.theme.textStyle.button}
  color: ${(p) =>
    p.color ? theme(`color.${p.color}`) : theme("colors.textOnInteractive01")};
  ${(p) =>
    p.disabled &&
    css`
      color: ${theme("colors.disabled01")};
    `}
`;

const Secondary = css<{ rounded?: boolean }>`
  background-color: ${theme("colors.secondaryBackground")};
  padding: ${(p) =>
    `${p.theme.spacing.buttonS.paddingY} ${p.theme.spacing.buttonS.paddingX}`};
  border-radius: ${(p) => (p.rounded ? p.theme.radii.l : p.theme.radii.s)};

  &:hover {
    background-color: ${(p) => p.theme.colors.interactive01Hover};
  }

  & ${Span} {
    color: ${theme("colors.text02")};
  }
`;

const Tertiary = css<{ rounded?: boolean }>`
  padding: ${(p) => p.theme.spacing.xxxs};
  border-radius: ${(p) => (p.rounded ? p.theme.radii.l : p.theme.radii.s)};
  border: 1px solid ${(p) => p.theme.colors.line01};
  background-color: ${(p) => p.theme.colors.quinaryBackground};

  &:hover {
    background-color: ${(p) => `${p.theme.colors.interactive01Hover}88`};
    border: 1px solid ${(p) => p.theme.colors.interactive01Hover};
  }

  & ${Span} {
    ${(p) => p.theme.textStyle.buttonM}
  }
`;

const Menu = styled(motion.div)<{
  $position: Array<"down" | "up" | "left" | "right">;
}>`
  position: absolute;
  z-index: 1;
  border-radius: ${(p) => p.theme.radii.s};
  border: 1px solid ${(p) => p.theme.colors.line01};
  overflow: hidden;
  box-shadow: ${(p) => p.theme.shadows.small};
  ${(p) =>
    p.$position.includes("right") &&
    (p.$position.some((pos) => ["up", "down"].includes(pos))
      ? css`
          left: 0;
        `
      : css`
          left: calc(100% + ${theme("spacing.xxxs")});
        `)}
  ${(p) =>
    p.$position.includes("left") &&
    (p.$position.some((pos) => ["up", "down"].includes(pos))
      ? css`
          right: 0;
        `
      : css`
          right: calc(100% + ${theme("spacing.xxxs")});
        `)}
  ${(p) =>
    p.$position.includes("down") &&
    css`
      top: calc(100% + ${theme("spacing.xxxs")});
    `}
  ${(p) =>
    p.$position.includes("up") &&
    css`
      bottom: calc(100% + ${theme("spacing.xxxs")});
    `}
`;

const MenuItem = styled.div`
  ${theme("textStyle.body")};
  white-space: nowrap;
  padding: ${(p) => p.theme.spacing.xxs};
  background-color: ${(p) => p.theme.colors.tertiaryBackground};
  color: ${(p) => p.theme.colors.text01};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.xxs};

  &:hover {
    background-color: ${(p) => p.theme.colors.interactive04Hover};
  }
`;

export { StyledButton, IconPlace, Span, Menu, MenuItem };
