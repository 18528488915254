import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Data = styled.div`
  background-color: ${theme("colors.interactive04")};
  color: ${theme("colors.textOnInteractive04")};
  padding: ${theme("spacing.xxxs")} ${theme("spacing.xxs")};
  ${theme("textStyle.body")};
  overflow: hidden;
  border: 2px solid ${theme("colors.primaryBackground")};
  transition: ${theme("timing.xs")};
  word-break: break-all;
  height: 100%;
`;

const Cell = styled.div`
  &:not(:first-child) {
    cursor: pointer;

    &:hover {
      ${Data} {
        color: ${theme("colors.textOnInteractive04Hover")};
        border: 2px solid ${theme("colors.brand01")};
      }
    }
  }

  &:first-child {
    ${Data} {
      font-weight: bold;
      border-radius: ${theme("radii.s")} 0 0 ${theme("radii.s")};
    }
  }

  &:last-child {
    ${Data} {
      border-radius: 0 ${theme("radii.s")} ${theme("radii.s")} 0;
    }
  }
`;

const Header = styled.div`
  background-color: ${theme("colors.primaryBackground")};
  padding: ${theme("spacing.xxxs")} ${theme("spacing.xxs")};
  ${theme("textStyle.subtitle01")};
  margin-top: ${theme("spacing.xxs")};
  font-weight: bold;
  text-transform: uppercase;
`;

const Row = styled.div<{ cellsSize?: Array<string> }>`
  overflow: visible;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${(p) => p.cellsSize?.join(" ")};
`;

export { Data, Row, Cell, Header };
