const getEmailsWithMoreClaps = (emails: Array<[string, number]>) => {
  return emails.reduce(
    (prev, curr) =>
      curr[1] > prev[0]?.[1]
        ? [curr]
        : curr[1] < prev[0]?.[1]
        ? prev
        : [...prev, curr],
    [] as Array<[string, number]>
  );
};

export { getEmailsWithMoreClaps };
