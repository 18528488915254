import styled from "styled-components";

const ImagesWrapper = styled.div`
  position: relative;
  padding-top: 87px;

  > * {
    position: absolute;
    left: 50%;
    box-shadow: 0px 5px 26px 0px rgba(16, 16, 16, 0.12);
    border-radius: 30px;
  }
`;

export { ImagesWrapper };
