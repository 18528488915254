import React from "react";
import * as S from "./style";

const Background = () => (
  <S.Background>
    <S.Circle1 />
    <S.Circle2 />
  </S.Background>
);

export { Background };
