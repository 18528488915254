import * as React from "react";
import { VStack, Text } from "@sqymagma/elements";

import { Loader } from "@elements";
import { ApplausesStats } from "@types";

import { StatsData } from "../atoms";
import * as S from "../style";
import Chart from "./Chart";
import { getChartLabel } from "./helpers";

const GlobalStats = (props: { stats: ApplausesStats | undefined }) => {
  const { stats } = props;

  const Stats = React.useCallback(
    () => (
      <S.StatsContainer>
        <S.StatsColumn gap="xxs">
          <StatsData
            label="aplausos enviados por el equipo"
            value={stats?.globalClapsSentAllTime}
          />
        </S.StatsColumn>
        <S.StatsColumn gap="xxs">
          <StatsData
            label="aplausos enviados los últimos 28 días"
            value={stats?.globalClapsSentLastFourWeeks}
          />
        </S.StatsColumn>
      </S.StatsContainer>
    ),
    [stats]
  );

  const ClapsChart = React.useCallback(() => {
    const labels = stats?.globalClapsSentMonthly?.map(getChartLabel);
    const data = stats?.globalClapsSentMonthly?.map((data) => data[1]) || [];
    const datasets = [{ label: "Aplausos", data, borderColor: "#3cc3dd" }];
    return (
      <Chart
        title="Aplausos enviados por el equipo"
        labels={labels}
        datasets={datasets}
        stepSize={5}
      />
    );
  }, [stats]);

  const ApplausesChart = React.useCallback(() => {
    const labels = stats?.monthlySenders.map(getChartLabel);
    const monthlySenders = stats?.monthlySenders?.map((data) => data[1]) || [];
    const monthlyReceivers =
      stats?.monthlyReceivers?.map((data) => data[1]) || [];
    const datasets = [
      {
        label: "Envían",
        data: monthlySenders,
        borderColor: "#a950cf",
      },
      {
        label: "Reciben",
        data: monthlyReceivers,
        borderColor: "#3dcd7c",
      },
    ];
    return (
      <Chart
        title="Personas que envían y reciben aplausos"
        labels={labels}
        datasets={datasets}
      />
    );
  }, [stats]);

  return (
    <VStack gap="xs">
      <Text textStyle="subtitle01" color="text01" weight="bold">
        Estadísticas globales
      </Text>
      {!stats && <Loader />}
      {stats && (
        <>
          <Stats />
          <ClapsChart />
          <ApplausesChart />
        </>
      )}
    </VStack>
  );
};

export default GlobalStats;
