const meetings = [
  {
    id: "konstelacion",
    title: "Konstelación",
    externalUrl: "https://meet.google.com/doe-zoah-aub",
    icon: "meet",
    description: "meet.google.com/doe-zoah-aub",
  },
  {
    id: "kilauea",
    title: "Kilauea",
    externalUrl: "https://meet.google.com/hnn-tdxg-nzf",
    icon: "meet",
    description: "meet.secuoyas.io/kilauea",
  },
  {
    id: "krakatoa",
    title: "Krakatoa",
    externalUrl: "https://meet.google.com/tim-msev-aro",
    icon: "meet",
    description: "meet.secuoyas.io/krakatoa",
  },
  {
    id: "karymsky",
    title: "Karymsky",
    externalUrl: "https://meet.google.com/wcr-aqcb-rpu",
    icon: "meet",
    description: "meet.secuoyas.io/karymsky",
  },
  {
    id: "katla",
    title: "Katla",
    externalUrl: "https://meet.google.com/yme-rpbt-khi",
    icon: "meet",
    description: "meet.secuoyas.io/katla",
  },
  {
    id: "kilimanjaro",
    title: "Kilimanjaro",
    externalUrl: "https://meet.google.com/cyc-nggg-adk",
    icon: "meet",
    description: "meet.secuoyas.io/kilimanjaro",
  },
  {
    id: "kadovar",
    title: "Kadovar",
    externalUrl: "https://meet.google.com/hzq-gyqi-rmd",
    icon: "meet",
    description: "meet.secuoyas.io/kadovar",
  },
  {
    id: "katete",
    title: "Katete",
    externalUrl: "https://meet.google.com/qzf-yuin-xbb",
    icon: "meet",
    description: "meet.google.com/qzf-yuin-xbb",
  },
] as const;

export type MeetingId = (typeof meetings)[number]["id"];
export type MeetingIcon = (typeof meetings)[number]["icon"];

export default meetings;
