import { meetings } from "@data";
import { MeetingId } from "@data/meetings";
import { Meeting } from "@types";

const getMeeting = (id: MeetingId) => {
  return meetings.find((meeting) => meeting.id === id) as Meeting;
};

const extractMeetingLink = (
  stringsWithPossibleMeeting: (string | undefined)[]
) => {
  const zoomUrl = "zoom.secuoyas.io";

  return stringsWithPossibleMeeting.reduce(
    (foundLink?: string, currentStr?: string) => {
      if (foundLink || !currentStr) return foundLink;

      const zoomRegex = /https*:\/\/.*?zoom\..*?(?="|$)/i;
      let zoomLink = zoomRegex.exec(currentStr)?.[0];
      if (!zoomLink && currentStr?.includes(zoomUrl))
        zoomLink = `https://${zoomUrl}`;

      const meetRegex =
        /https*:\/\/meet\.(google|secuoyas)\.(com|io)\/.*?(?="|$)/i;
      const meetLink = meetRegex.exec(currentStr)?.[0];

      const teamsRegex =
        /<https:\/\/teams\.microsoft\.com\/l\/meetup-join\/.*?(?=>)/i;
      const teamsLink = teamsRegex.exec(currentStr)?.[0]?.slice(1);

      return meetLink || zoomLink || teamsLink;
    },
    undefined
  );
};

export { getMeeting, extractMeetingLink };
