import styled from "styled-components";
import { Flex } from "@sqymagma/elements";
import { theme } from "@sqymagma/theme";

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xxs};
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const ChartContainer = styled.div`
  flex-grow: 1;
  padding-left: ${(p) => p.theme.spacing.xxxs};

  @media (min-width: ${theme("breakpoints.s")}) {
    padding-left: ${(p) => p.theme.spacing.xs};
    padding-right: ${(p) => p.theme.spacing.xxs};
  }
`;

export { Wrapper, ChartWrapper, ChartContainer };
