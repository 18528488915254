import React, { useEffect, useRef, useState } from "react";

import { Icon } from "@elements";
import { IconNames } from "@elements/Icon";

import * as S from "./style";

const MenuItem = (props: Props) => {
  const { icon, to, submenu, externalUrl, title, action, bottom } = props;
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLAnchorElement>(null);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.addEventListener("mouseover", handleOpen);
    }
    const currentMenuRef = menuRef.current;
    return () => currentMenuRef?.removeEventListener("mouseover", handleOpen);
  }, [menuRef]);

  const handleOnClick = (event: React.MouseEvent) => {
    !!submenu && event.preventDefault();
    externalUrl && window.open(externalUrl, "_blank");
    action && action();
  };

  const showSubmenu = submenu && isOpen;
  const showTitle = !submenu && title && isOpen;
  const hasActive = !!submenu?.options.some((option) => option.active);

  return (
    <S.MenuLinkWrapper>
      <S.MenuLink
        to={to}
        {...(!to && { as: "a" })}
        ref={menuRef}
        exact={!submenu}
        onClick={handleOnClick}
      >
        <Icon name={icon} size="medium" color="text01" />
      </S.MenuLink>
      {showTitle && (
        <S.MenuLinkTitle>
          <S.Label>
            {title}
            {externalUrl && (
              <Icon name="externalLink" width={16} color="text02" />
            )}
          </S.Label>
        </S.MenuLinkTitle>
      )}
      {showSubmenu && (
        <S.Submenu hasActive={hasActive} bottom={bottom}>
          <S.SubmenuTitle>{title}</S.SubmenuTitle>
          {submenu.options.map((option, idx) => {
            const { title, to, action, active, externalUrl } = option;
            const handleOnClick = () => {
              action && action();
              externalUrl && window.open(externalUrl, "_blank");
              handleClose();
            };
            return (
              <S.SubmenuOption
                key={idx}
                to={to}
                {...(!to && { as: "a" })}
                onClick={handleOnClick}
                active={active}
              >
                <S.Label>
                  {title}
                  {externalUrl && (
                    <Icon name="externalLink" width={14} color="text02" />
                  )}
                </S.Label>
                {active && <Icon name="checkmark" width={19} color="text02" />}
              </S.SubmenuOption>
            );
          })}
        </S.Submenu>
      )}
    </S.MenuLinkWrapper>
  );
};

interface Props {
  icon: IconNames;
  title: string;
  to?: string;
  externalUrl?: string;
  submenu?: {
    options: Array<{
      title: string;
      to?: string;
      externalUrl?: string;
      active?: boolean;
      action?: () => void;
    }>;
  };
  action?: () => void;
  bottom?: boolean;
}

export default MenuItem;
