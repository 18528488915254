import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Header = styled.span`
  ${theme("textStyle.subtitle01")};
  color: ${theme("colors.text01")};
  font-weight: bold;
  cursor: pointer;
  margin-right: ${(p) => p.theme.spacing.xs};
`;

export { Header };
