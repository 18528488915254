import React from "react";
import { Flex } from "@sqymagma/elements";

import * as S from "./style";

const Loading = (props: IProps) => {
  const { fadeIn } = props;

  return (
    <Flex
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <S.TextContainer
        initial={{ opacity: fadeIn ? 0 : 1 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
      >
        <div>r</div>
        <div>e</div>
        <div>m</div>
        <div>o</div>
        <div>t</div>
        <div>e</div>
      </S.TextContainer>
    </Flex>
  );
};

interface IProps {
  fadeIn?: boolean;
}

export default Loading;
