import styled from "styled-components";

import mask from "@images/masks/mascara-inicio.svg";

const GifWelcome = styled.div<{ $image: string }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url(${mask});
  mask-position: center;
  mask-size: 355px;
  mask-repeat: no-repeat;
  width: 360px;
  height: 360px;
  position: absolute;
  right: -15px;
  bottom: -30px;
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
`;

export { GifWelcome };
