import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { ReactComponent as CheckMark } from "@icons/system/checkmark.svg";

import * as S from "./style";

const MenuItem = (props: Props) => {
  const { to, submenu, externalUrl, title, toggleModal, action } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubMenu = () => setIsOpen((state) => !state);

  const handleOnClick = (event: React.MouseEvent) => {
    if (submenu) {
      event.preventDefault();
      toggleSubMenu();
    } else {
      toggleModal && toggleModal();
    }
    externalUrl && window.open(externalUrl, "_blank");
    action && action();
  };

  const showSubmenu = submenu && isOpen;

  return (
    <>
      <S.MenuLink to={to} {...(!to && { as: "a" })} onClick={handleOnClick}>
        {title}
      </S.MenuLink>
      <AnimatePresence>
        {showSubmenu && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ ease: "easeOut", duration: 0.3 }}
          >
            <S.Submenu pl="xs" pt="xxs" gap="xxs" textAlign="left">
              {submenu.options.map((option, idx) => {
                const { title, to, action, active, externalUrl } = option;
                const handleOnClick = () => {
                  externalUrl && window.open(externalUrl, "_blank");
                  action && action();
                  toggleModal && toggleModal();
                };
                return (
                  <S.SubmenuLink
                    key={idx}
                    to={to}
                    {...(!to && { as: "a" })}
                    onClick={handleOnClick}
                    active={active}
                  >
                    {title}
                    {active && <CheckMark width="19px" />}
                  </S.SubmenuLink>
                );
              })}
            </S.Submenu>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

interface Props {
  title: string;
  to?: string;
  externalUrl?: string;
  submenu?: {
    options: Array<{
      title: string;
      to?: string;
      externalUrl?: string;
      active?: boolean;
      action?: () => void;
    }>;
  };
  action?: () => void;
  toggleModal?: () => void;
}

export default MenuItem;
