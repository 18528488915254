import styled from "styled-components";
import { Flex } from "@sqymagma/elements";

const ChartContainer = styled(Flex)`
  position: relative;
  width: 100%;
  margin-top: ${(p) => p.theme.spacing.xxs};
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xxs};
  background-color: ${(p) => p.theme.colors.septenaryBackground};
  border-radius: ${(p) => p.theme.radii.m};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.xxs};
`;

export { ChartContainer };
