import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const ClapsLabel = styled.div`
  ${theme("textStyle.bodyInline")};
  font-weight: bold;
  display: flex;
  gap: ${(p) => p.theme.spacing.xxxs};
  align-items: center;
  white-space: nowrap;
  background-color: ${(p) => `${p.theme.colors.ui01}88`};
  color: ${(p) => p.theme.colors.text01};
  border-radius: ${(p) => p.theme.radii.l};
  padding: ${(p) => p.theme.spacing.xxxs} ${(p) => p.theme.spacing.xxs};
`;

const MorePeople = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-bottom: ${(p) => p.theme.spacing.xxxs};
  }
  & > *:not(:last-child) {
    margin-right: ${(p) => p.theme.spacing.xxs};
  }
`;

export { ClapsLabel, MorePeople };
