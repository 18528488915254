import {
  format,
  isValid,
  parse,
  getYear,
  isWithinInterval,
  differenceInDays,
  fromUnixTime,
  startOfDay,
} from "date-fns";
import { secuoyasBirthday } from "@config";
import { Reward, User } from "@types";
import { googleApi } from "@services";

const es = require("date-fns/locale/es").default;
const { REACT_APP_PEOPLE_GSHEET_ID: peopleSpreadsheetId } = process.env;

const areSameDay = (date1: Date | string, date2: Date | string) => {
  if (!isValid(new Date(date1)) || !isValid(new Date(date2))) return false;
  const d1 = format(new Date(date1), "y MM dd");
  const d2 = format(new Date(date2), "y MM dd");
  return d1 === d2;
};

const getMonthDates = (date: Date) => {
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59
  );
  return { firstDate, lastDate };
};

const getDayDates = (date: Date) => {
  const firstDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59
  );
  return { firstDate, lastDate };
};

const beautifyDate = (date: string) => {
  const parsedDate = parse(date, "d/M/yyyy", new Date());
  const beautyDate = isValid(parsedDate)
    ? format(parsedDate, "PPPP", { locale: es })
    : date;
  return beautyDate;
};

const getYearFromDate = (date: string) => {
  const parsedDate = parse(date, "d/M/yyyy", new Date());
  return getYear(parsedDate);
};

const getReward = async (user: User): Promise<Reward> => {
  let reward: Reward = { type: null }; // Default reward
  const today = format(new Date(), "dd/MM");

  // Check if today is Secuoyas birthday
  if (secuoyasBirthday === today) {
    reward = { type: "secuoyasBirthday" };
  }

  if (peopleSpreadsheetId) {
    const rows = await googleApi.getSheet({
      spreadsheetId: peopleSpreadsheetId,
      range: "SECUOYAS!A1:ZZ100",
    });
    if (rows) {
      const headers = rows[0];
      const emailIndex = headers.findIndex((h) => h.trim() === "Email");
      const birthdayIndex = headers.findIndex(
        (h) => h.trim() === "Fecha nacimiento"
      );
      const hiringIndex = headers.findIndex(
        (h) => h.trim() === "Fecha incorporación"
      );
      const userData = rows.find(
        (row) => row[emailIndex]?.trim() === user?.email
      );

      // Check if today is hiring anniversary
      if (userData?.[hiringIndex]) {
        const [day, month, year] = userData[hiringIndex].split("/");
        const hiringDate = new Date();
        hiringDate.setDate(Number(day));
        hiringDate.setMonth(Number(month) - 1);
        hiringDate.setFullYear(Number(year));
        hiringDate.setHours(0, 0, 0, 0);
        if (format(hiringDate, "dd/MM") === today) {
          const years = differenceInDays(new Date(), hiringDate) / 365;
          reward = { type: "hiringAnniversary", payload: Math.floor(years) };
        }
      }

      // Check if today is user birthday
      if (userData?.[birthdayIndex]) {
        const [day, month] = userData[birthdayIndex].split("/");
        const birthday = new Date();
        birthday.setDate(Number(day));
        birthday.setMonth(Number(month) - 1);
        birthday.setHours(0, 0, 0, 0);
        if (format(birthday, "dd/MM") === today) {
          reward = { type: "birthday" };
        }
      }
    }
  }

  return reward;
};

const dateString2Date = (dateString: string) => {
  const dateParts = dateString.split("/");
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const millennium = Number(
    new Date().getFullYear().toString().substring(0, 1)
  );
  const year = millennium * 1000 + parseInt(dateParts[2], 10);
  return new Date(year, month, day);
};

const date2DateString = (date: Date) => {
  return date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
};

const getFirstDayOfThisWeek = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  const firstDay = new Date(today.setDate(diff));
  firstDay.setHours(0, 0, 0, 0);
  return firstDay;
};

const isDateWithinRange = (date: string | Date, range: Array<string>) => {
  const today = new Date(date);

  const [d1, m1, y1] = range[0].split("/");
  const firstDay = new Date();
  firstDay.setDate(Number(d1));
  firstDay.setMonth(Number(m1) - 1);
  firstDay.setFullYear(y1 ? Number(y1) : today.getFullYear());

  const [d2, m2, y2] = range[1].split("/");
  const lastDay = new Date();
  lastDay.setDate(Number(d2));
  lastDay.setMonth(Number(m2) - 1);
  lastDay.setFullYear(y2 ? Number(y2) : today.getFullYear());

  const yearsSpecified = !!y1 && !!y2;

  if (!yearsSpecified && firstDay >= lastDay) {
    if (today > lastDay) {
      lastDay.setFullYear(firstDay.getFullYear() + 1);
    } else {
      firstDay.setFullYear(firstDay.getFullYear() - 1);
    }
  }

  return isWithinInterval(today, { start: firstDay, end: lastDay });
};

const getPostTimeFromUnix = (time: number) => {
  const daysFromToday = differenceInDays(
    startOfDay(new Date()),
    startOfDay(fromUnixTime(time))
  );

  const timeFormats = [
    "'Hoy a las' H:mm",
    "'Ayer a las' H:mm",
    "d 'de' MMMM 'a las' H:mm",
  ];

  return format(
    fromUnixTime(time),
    timeFormats[daysFromToday > 1 ? 2 : daysFromToday],
    { locale: es }
  );
};

const showWrappedWidget = (wrappedDay: number) => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();

  let show = false;

  try {
    const queryParameters = new URLSearchParams(window.location.search);
    const wrapped = queryParameters.get("wrapped");

    show = wrapped === "show";
  } catch (error) {
    /* empty */
  }

  return (
    show ||
    (day >= wrappedDay && month === 11) ||
    (day <= wrappedDay && month === 0)
  );
};

export {
  areSameDay,
  getMonthDates,
  getDayDates,
  beautifyDate,
  getYearFromDate,
  getReward,
  dateString2Date,
  getFirstDayOfThisWeek,
  date2DateString,
  isDateWithinRange,
  getPostTimeFromUnix,
  showWrappedWidget,
};
