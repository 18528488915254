import styled from "styled-components";

import mask1 from "@images/masks/mascara-aplausos1.svg";
import mask2 from "@images/masks/mascara-aplausos2.svg";

const GifClaps1 = styled.div<{ $image: string }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url(${mask1});
  mask-position: center;
  mask-size: 450px;
  mask-repeat: no-repeat;
  width: 450px;
  height: 450px;
  position: absolute;
  right: -110px;
  top: 45vh;
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 8s;
  animation-fill-mode: forwards;
`;

const GifClaps2 = styled.div<{ $image: string; $bottom?: boolean }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url(${mask2});
  mask-position: center;
  mask-size: 390px;
  mask-repeat: no-repeat;
  width: 390px;
  height: 390px;
  position: absolute;
  left: ${(p) => (p.$bottom ? "auto" : "-100px")};
  right: ${(p) => (p.$bottom ? "-70px" : "auto")};
  top: ${(p) => (p.$bottom ? "auto" : "40vh")};
  bottom: ${(p) => (p.$bottom ? "-60px" : "auto")};
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: ${(p) => (p.$bottom ? "1.5s" : "8s")};
  animation-fill-mode: forwards;
`;

export { GifClaps1, GifClaps2 };
