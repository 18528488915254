import styled, { keyframes } from "styled-components";
import { theme } from "@sqymagma/theme";
import { motion } from "framer-motion";

const zoomAnimation = keyframes`
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.1;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.1;
  }
`;

const TextContainer = styled(motion.div)`
  color: ${theme("colors.text01")};
  font-family: "Moderna", sans-serif;
  font-size: 70px;
  letter-spacing: 0.2rem;
  pointer-events: none;
  user-select: none;

  & > div {
    animation: ${zoomAnimation} 3.6s ease-in-out infinite;
    display: inline-block;
    opacity: 0.1;
  }

  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }

  & > div:nth-child(4) {
    animation-delay: 0.6s;
  }

  & > div:nth-child(5) {
    animation-delay: 0.8s;
  }

  & > div:nth-child(6) {
    animation-delay: 1s;
  }
`;

export { TextContainer };
