import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import { AnimatePresence } from "framer-motion";

import { Toast } from "@elements";
import { ONE_SECOND_IN_MS } from "@constants";

import { ToastsWrapper } from "./style";

const toastTime = 4 * ONE_SECOND_IN_MS;

const ToastContext = createContext({} as ProviderValue);

const ToastProvider = (props: ProviderProps) => {
  const { children } = props;
  const toast = useToastProvider();
  const root = document.getElementById("root");

  return (
    <ToastContext.Provider value={toast}>
      {children}
      {root &&
        createPortal(
          <ToastsWrapper>
            <AnimatePresence>
              {toast.toasts.map((toast) => (
                <Toast key={JSON.stringify(toast)}>{toast}</Toast>
              ))}
            </AnimatePresence>
          </ToastsWrapper>,
          root
        )}
    </ToastContext.Provider>
  );
};

const useToast = () => {
  return useContext(ToastContext);
};

const useToastProvider = () => {
  const [toasts, setToasts] = useState<(JSX.Element | string)[]>([]);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  const addToast = useCallback(
    (toast: JSX.Element | string) => {
      setToasts((toasts) => [...toasts, toast]);
      timer.current = setTimeout(
        () => setToasts((toasts) => toasts.slice(1)),
        toastTime
      );
    },
    [setToasts]
  );

  return { toasts, addToast };
};

interface ProviderProps {
  children: JSX.Element;
}

interface ProviderValue {
  toasts: (JSX.Element | string)[];
  addToast: (toast: JSX.Element | string) => void;
}

export { ToastProvider, useToast };
