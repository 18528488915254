import * as React from "react";
import { Text } from "@sqymagma/elements";

import * as S from "./style";

const StatsData = (props: { label: string; value?: number }) => {
  const { label, value } = props;

  return (
    <S.StatsData>
      <Text textStyle="display03" color="text01" textAlign="center">
        <b>{value?.toLocaleString()}</b>
      </Text>
      <Text textStyle="bodyInline" color="text01" textAlign="center">
        {label}
      </Text>
    </S.StatsData>
  );
};

export { StatsData };
