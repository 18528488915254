import * as React from "react";
import { Text } from "@sqymagma/elements";

import { useApp } from "@contexts";
import { Toggle } from "@elements";

import * as S from "./style";

const Config = () => {
  const { config, changeConfig } = useApp();
  const { openVideoPopup, notifyEvents, applausesReminder } = config;

  const changeNotifyEvents = (value: boolean) =>
    changeConfig({ notifyEvents: value });

  const changeNotifyApplauses = (value: boolean) =>
    changeConfig({ applausesReminder: value });

  const changeOpenVideoPopup = (value: boolean) =>
    changeConfig({ openVideoPopup: value });

  const notificationsDenied =
    "Notification" in window && Notification.permission === "denied";

  return (
    <>
      <S.Section>Notificaciones</S.Section>
      {notificationsDenied && (
        <S.Warning>
          Tu navegador no permite las notificaciones. Modifica la configuración
          para poder recibir las notificaciones activadas aquí.
        </S.Warning>
      )}
      <S.Option>
        <Text textStyle="body">
          Avisar del inicio de eventos del calendario
        </Text>
        <Toggle
          name="notify-events"
          value={notifyEvents}
          onChange={changeNotifyEvents}
          disabled={notificationsDenied}
        />
      </S.Option>
      <S.Option>
        <Text textStyle="body">Recordar aplausos pendientes de enviar</Text>
        <Toggle
          name="notify-applauses"
          value={applausesReminder}
          onChange={changeNotifyApplauses}
          disabled={notificationsDenied}
        />
      </S.Option>

      <S.Section>Salas de vídeo</S.Section>
      <S.Option>
        <Text textStyle="body">Abrir salas de vídeo en una ventana nueva</Text>
        <Toggle
          name="open-video-popup"
          value={openVideoPopup}
          onChange={changeOpenVideoPopup}
        />
      </S.Option>
    </>
  );
};

export default Config;
