import styled from "styled-components";
import mask from "@images/masks/mascara-sobre-google.svg";

const GifEmails = styled.div<{ $image: string }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url(${mask});
  mask-position: bottom;
  mask-size: 270px;
  mask-repeat: no-repeat;
  width: 171px;
  height: 153px;
  position: absolute;
  top: 32px;
  left: 50px;
`;

export { GifEmails };
