const extractEmailAndSender = (from: string) => {
  const regex = /<([^;]*)>/;
  const data = regex.exec(from);
  const emailAddress = data?.[1];
  const emailSender = from
    .slice(0, data?.index)
    .replaceAll('"', "")
    .trim();
  return { emailAddress, emailSender };
};

const getFullNameFromEmail = (email: string) => {
  const nameWithLastname = email.split("@")[0];
  const namesArray = nameWithLastname.split(".");
  const capitalizedNames = namesArray.map(
    (name) => name.charAt(0).toUpperCase() + name.slice(1)
  );
  const fullName = capitalizedNames.join(" ");
  return fullName;
};

export { extractEmailAndSender, getFullNameFromEmail };
