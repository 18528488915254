import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const daySize = "35px";

const Calendar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WeekDaysNames = styled.div`
  ${theme("textStyle.body")};
  display: flex;
  justify-content: space-between;

  & > div {
    width: ${daySize};
    box-sizing: border-box;
    padding: ${(p) => p.theme.spacing.xxs} 0;
    color: ${(p) => p.theme.colors.text01};
    font-weight: bold;
    text-align: center;
  }
`;

const Events = styled.div`
  color: ${(p) => p.theme.colors.ui02};
  padding: ${(p) => p.theme.spacing.xs};
  margin-top: ${(p) => p.theme.spacing.xxs};
`;

const HolidayMark = styled.div`
  background-color: ${(p) => p.theme.colors.holidays};
  width: 9px;
  height: 9px;
  border-radius: 50%;
`;

export { Calendar, WeekDaysNames, Events, HolidayMark };
