import React, { FunctionComponent, useCallback } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { Box, Flex, Text, VStack } from "@sqymagma/elements";

import { useStyle } from "@contexts";
import { Icon } from "@elements";
import { IconNames } from "@elements/Icon";
import firebase from "@database";

import * as S from "./style";

const MiniWidget = (props: Props) => {
  const { title, description, color, icon, image, action, large } = props;
  const {
    globalTheme: { breakpoints },
  } = useStyle();

  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.l})`);
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.m})`);
  const isLarge = !!((isTablet && !isDesktop) || large);

  const handleOnClick = () => {
    action();
    firebase.logEvent("widget_click", { widget_title: title });
  };

  const WidgetIcon = useCallback(
    (props: { color?: string; icon: IconNames; pl?: string }) => {
      const { color, icon, pl } = props;
      return (
        <S.IconWrapper pl={pl}>
          <Icon name={icon} size="medium" color={color} />
        </S.IconWrapper>
      );
    },
    []
  );

  return (
    <S.MiniWidgetWrapper color={color} onClick={handleOnClick} $large={isLarge}>
      <Flex justifyContent="space-between" pb={!isLarge && "xxs"}>
        {image && (
          <Flex mr="xs" height={60} alignItems="center">
            {React.createElement(image)}
          </Flex>
        )}
        {!isLarge && <WidgetIcon color={color} icon={icon} />}
      </Flex>
      <VStack minHeight={52}>
        <Box>
          <Text textStyle="subtitle03" weight="bold" caps>
            {title}
          </Text>
        </Box>
        <Box mt="xxxs">
          <Text textStyle="subtitle03">{description}</Text>
        </Box>
      </VStack>
      {isLarge && <WidgetIcon color={color} icon={icon} pl="xs" />}
    </S.MiniWidgetWrapper>
  );
};

interface Props {
  title: string;
  description: string;
  color?: string;
  icon: IconNames;
  image: FunctionComponent;
  action: () => void;
  large?: boolean;
}

export default MiniWidget;
