import React, { useCallback, useEffect, useState } from "react";
import { Text, Box, Flex, Columns, Column } from "@sqymagma/elements";

import { googleApi } from "@services";
import { Loader, SearchBar, Select } from "@elements";
import {
  arrayToObject,
  beautifyDate,
  getVimeoEmbedUrl,
  getYearFromDate,
} from "@helpers";
import { useSearch } from "@hooks";
import { Hubble, SelectOption } from "@types";

import * as S from "./style";

const Hubbles = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [hubbles, setHubbles] = useSearch<Hubble>([], {
    searchQuery,
    searchFields: ["title", "description"],
  });
  const [yearFilter, setYearFilter] = useState<SelectOption>(null);
  const [numberOfHubbles, setNumberOfHubbles] = useState<number>();

  useEffect(() => {
    const { REACT_APP_HUBBLE_GSHEET_ID: spreadsheetId } = process.env;
    if (!spreadsheetId) return;
    const payload = { spreadsheetId, range: "A2:D1000" };
    googleApi.getSheet(payload).then((rows) => {
      const keys = ["title", "description", "date", "url"];
      const rowsObjects = rows?.map((row: string[]) =>
        arrayToObject<Hubble>(row, keys)
      );
      if (rowsObjects) {
        setHubbles(rowsObjects.reverse());
        setNumberOfHubbles(rowsObjects.length);
      }
    });
  }, [setHubbles]);

  const handleYearFilter = (value: SelectOption) => {
    setYearFilter(value);
  };

  const yearOptions = useCallback(() => {
    const years = hubbles.map((hubble) => getYearFromDate(hubble.date));
    return [...new Set(years)].map((year) => ({ value: year, label: year }));
  }, [hubbles]);

  const filteredHubbles = hubbles.filter((hubble) => {
    const year = getYearFromDate(hubble.date);
    return !yearFilter || year === yearFilter.value;
  });

  const noResults = !hubbles.length && !!searchQuery.length;
  const showingMessage =
    numberOfHubbles &&
    `Mostrando ${filteredHubbles.length} de ${numberOfHubbles}`;

  const NoSearchResults = () => (
    <Text textStyle="body" color="text01">
      No se ha encontrado ningún hubble en la búsqueda
    </Text>
  );

  const Loading = () => (
    <Flex justifyContent="center">
      <Loader />
    </Flex>
  );

  return (
    <>
      <Box>
        <Text textStyle="display02" color="text01" weight="bold">
          Hubbles
        </Text>
      </Box>
      <Box mt="xs">
        <Text textStyle="bodyLead" color="text01">
          Aquí podrás encontrar todos los vídeos de nuestros eventos de los
          lunes.
        </Text>
      </Box>
      <Box mt="m">
        <Columns hs="s" vs="xxs" overflow="visible" alignItems="center">
          <Column width={[1, 1, 1 / 2, 1 / 2, 1 / 3]}>
            <SearchBar
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Busca un hubble"
            />
          </Column>
          <Column width={[1, 1, 1 / 2, 1 / 4, 1 / 5]}>
            <Select
              value={yearFilter}
              options={yearOptions()}
              onChange={handleYearFilter}
              placeholder="Todos los años"
            />
          </Column>
          <Column>
            {showingMessage && (
              <Text textStyle="bodyInline" color="text03">
                {showingMessage}
              </Text>
            )}
          </Column>
        </Columns>
      </Box>
      <Box mt="l">
        {filteredHubbles.length ? (
          <Flex justifyContent="flex-start" flexWrap="wrap">
            {filteredHubbles.map((hubble) => {
              const src = getVimeoEmbedUrl(hubble.url);
              const date = beautifyDate(hubble.date);
              const handleClick = () => window.open(hubble.url, "_blank");
              return (
                <Box
                  key={hubble.url}
                  px="xs"
                  pb="m"
                  width={[1, 1, 1, 1 / 2, 1 / 3]}
                >
                  <S.Video>
                    <S.VideoLoader>
                      <Loader light />
                    </S.VideoLoader>
                    <S.Iframe>
                      <iframe src={src} title={hubble.title} />
                    </S.Iframe>
                  </S.Video>
                  <S.VideoInformation pt="xxxs" onClick={handleClick}>
                    <Text textStyle="tiny" color="text03">
                      {date}
                    </Text>
                    <Text textStyle="headings">{hubble.title}</Text>
                    <Text textStyle="body" color="text03">
                      {hubble.description}
                    </Text>
                  </S.VideoInformation>
                </Box>
              );
            })}
          </Flex>
        ) : noResults ? (
          <NoSearchResults />
        ) : (
          <Loading />
        )}
      </Box>
    </>
  );
};

export default Hubbles;
