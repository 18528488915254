import styled from "styled-components";
import { Box } from "@sqymagma/elements";
import { motion } from "framer-motion";

import { NAVBAR_WIDTH } from "./constants";

const Wrapper = styled(Box)`
  height: 100vh;
  position: sticky;
  top: 0;
  order: -1;
  z-index: 1;
`;

const Logo = styled.div`
  cursor: pointer;
  position: relative;
`;

const Menu = styled.div`
  background-color: ${(p) => p.theme.colors.ui04};
  width: ${NAVBAR_WIDTH};
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
`;

const Fold = styled.div`
  height: 0;
  width: 80px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: -160px;
    height: 160px;
    width: 80px;
    border-top-left-radius: 80px;
    box-shadow: 0 -80px 0 0 ${(p) => p.theme.colors.ui04};
  }
`;

const PartyHat = styled(motion.img)`
  position: absolute;
  width: 34px;
  top: -37px;
  left: 3px;
  z-index: 1;
  transform: rotate(-7deg);
`;

export { Wrapper, Logo, Menu, Fold, PartyHat };
