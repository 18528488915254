import { format } from "date-fns";
import { Weather, WeatherApi } from "@types";

const dateFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

const getWeather = async (coords: GeolocationCoordinates) => {
  try {
    const { latitude, longitude } = coords;
    const response = await fetch(
      `${process.env.REACT_APP_WEATHER_API_URL}/weather/?lat=${latitude}&lon=${longitude}&units=metric&lang=es&APPID=${process.env.REACT_APP_WEATHER_API_KEY}`
    );
    const weather = await response.json();
    if (Object.entries(weather).length) {
      const mappedData = mapDataToWeatherInterface(weather);
      return mappedData;
    }
  } catch (error) {
    console.warn("Error while getting weather.", error);
  }
};

const getTimeOfDay = (weather: Omit<Weather, "timeOfDay">): "day" | "night" => {
  const currentTime = format(weather.date, dateFormat);
  const sunrise = format(weather.sunrise, dateFormat);
  const sunset = format(weather.sunset, dateFormat);
  const timeOfDay =
    currentTime > sunrise && currentTime < sunset ? "day" : "night";
  return timeOfDay;
};

const mapDataToWeatherInterface = (data: WeatherApi) => {
  const mapped = {
    city: data.name,
    condition: data.cod,
    country: data.sys.country,
    date: data.dt * 1000, // convert from seconds to milliseconds
    description: data.weather[0].description,
    feelsLike: Math.round(data.main.feels_like),
    humidity: data.main.humidity,
    iconId: data.weather[0].id,
    sunrise: data.sys.sunrise * 1000, // convert from seconds to milliseconds
    sunset: data.sys.sunset * 1000, // convert from seconds to milliseconds
    temperature: Math.round(data.main.temp),
    timezone: data.timezone / 3600, // convert from seconds to hours
    windSpeed: Math.round(data.wind.speed * 3.6), // convert from m/s to km/h
    max: Math.round(data.main.temp_max),
    min: Math.round(data.main.temp_min),
  };

  const _mapped = {
    ...mapped,
    timeOfDay: getTimeOfDay(mapped),
  };

  return _mapped;
};

const weatherApi = { getWeather };

export default weatherApi;
