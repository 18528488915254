import * as React from "react";
import { Text, Box, Flex } from "@sqymagma/elements";
import { googleApi } from "@services";
import { useAuth, useApp } from "@contexts";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";
import meetings from "@gifs/meetings.gif";
import { GifMeetings } from "./style";
import moment from "moment";
require("moment-precise-range-plugin");

type GoogleProps = {
  active: boolean;
  wrappedStart: Date;
  wrappedEnd: Date;
};

const Google = ({ active, wrappedStart, wrappedEnd }: GoogleProps) => {
  const [totalEvents, setTotalEvents] = React.useState(0);
  const [totalHoursEvents, setTotalHoursEvents] = React.useState(0);
  const [shorterEvent, setShorterEvent] = React.useState(0);
  const [longerEvent, setLongerEvent] = React.useState(0);
  const [topTenCoworkers, setTotalTenCoworkers] = React.useState<Array<string>>(
    []
  );
  const [activeItem, setActiveItem] = React.useState(0);
  const auth = useAuth();
  const { user } = auth;
  const { people } = useApp();
  const config = WRAPPED_STEPS.find(({ step }) => step === "Google");
  const durations = config?.children;

  const countOccurrences = (arr: Array<string>) => {
    const counts = new Map();

    arr.forEach((element) => {
      if (!counts.has(element)) {
        counts.set(element, 1);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        counts.set(element, counts.get(element)! + 1);
      }
    });
    return counts;
  };

  const getEvents = async () => {
    const events = await googleApi.getEvents({
      firstDate: new Date(wrappedStart),
      lastDate: new Date(wrappedEnd),
    });

    setTotalEvents(events?.length || 0);
    let totalHoursEvents = 0;
    const totalAttendees: Array<string> = [];
    let minutesEvents: Array<number> = [];

    events?.forEach((event) => {
      const startDate = moment(event.start.dateTime);
      const endDate = moment(event.end.dateTime);
      if (
        event.start.dateTime &&
        event.end.dateTime &&
        startDate.isSame(endDate, "day")
      ) {
        const starts = moment(event?.start.dateTime);
        const ends = moment(event.end.dateTime);
        const duration = moment.duration(ends.diff(starts));
        const hours = duration.asHours();
        const minutes = duration.asMinutes();
        minutesEvents.push(minutes);

        totalHoursEvents += hours;
      }
      if (event.attendees && event.attendees.length) {
        event.attendees.map((attendee) => totalAttendees.push(attendee.email));
      }
    });

    minutesEvents = minutesEvents.sort((a, b) => b - a);
    setShorterEvent(minutesEvents[minutesEvents.length - 1]);
    setLongerEvent(minutesEvents[0]);

    setTotalHoursEvents(Number(totalHoursEvents.toFixed(0)));
    const occurrences = countOccurrences(totalAttendees);
    const orderOccurrences = Array.from(occurrences.entries())
      .sort((a, b) => b[1] - a[1])
      .map((element) => element[0])
      .filter((element) => element !== user?.email);
    setTotalTenCoworkers(orderOccurrences.slice(0, 10));
  };

  React.useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  React.useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="#F78787"
      $deg1="rgba(182, 255, 255, 0.70)"
      $deg2="rgba(119, 255, 133, 0.70)"
      $rotate="56.676deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <div style={{ height: "100%", overflow: "hidden" }}>
          <Text
            color="#1145FC"
            width="100%"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "56px",
              fontWeight: "400",
              letterSpacing: "0.6px",
            }}
          >
            Desde rechazados hasta recurrentes, nos hemos sumergido en un montón
            de encuentros
          </Text>
        </div>
      )}

      {/* 2 */}
      {activeItem === 1 && (
        <div style={{ height: "100%", overflow: "hidden" }}>
          <Box>
            <Text
              color="#1145FC"
              width="100%"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "66px",
                fontWeight: "400",
                letterSpacing: "0.6px",
              }}
            >
              Has estado más de
            </Text>
          </Box>
          <Box>
            <Text
              color="#F7FF9C"
              width="100%"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "102px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.6px",
              }}
            >
              {Number(totalHoursEvents).toLocaleString("es-ES")}
            </Text>
          </Box>
          <Box>
            <Text
              color="#1145FC"
              width="100%"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "66px",
                fontWeight: "400",
                letterSpacing: "0.6px",
              }}
            >
              horas reunida/o este año
            </Text>
          </Box>
          <GifMeetings $image={meetings} />
        </div>
      )}

      {/* 3 */}
      {activeItem === 2 && (
        <div>
          <Box>
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Noto Serif', serif",
                fontSize: "24px",
                fontWeight: "500",
                letterSpacing: "0.48px",
                lineHeight: "155%",
              }}
            >
              Vamos a desglosarlo un poco...
            </Text>
          </Box>
          <Box mt="20px">
            <Text
              color="#1145FC"
              width="100%"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "38px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.6px",
              }}
            >
              {Number(totalEvents).toLocaleString("es-ES")}
            </Text>
          </Box>
          <Box style={{ borderBottom: "1px solid #fff" }} pb="16px">
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Noto Serif', serif",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              reuniones en total
            </Text>
          </Box>
          <Box mt="20px">
            <Text
              color="#1145FC"
              width="100%"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "38px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.6px",
              }}
            >
              {Number(longerEvent).toLocaleString("es-ES") + " mins"}
            </Text>
          </Box>
          <Box style={{ borderBottom: "1px solid #fff" }} pb="16px">
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Noto Serif', serif",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              reunión más larga
            </Text>
          </Box>
          <Box mt="20px">
            <Text
              color="#1145FC"
              width="100%"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "38px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.6px",
              }}
            >
              {Number(shorterEvent).toLocaleString("es-ES") + " mins"}
            </Text>
          </Box>
          <Box style={{ borderBottom: "1px solid #fff" }} pb="16px">
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Noto Serif', serif",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              reunión más corta
            </Text>
          </Box>
        </div>
      )}

      {/* 4 */}
      {activeItem === 3 && (
        <div style={{ height: "100%", overflow: "hidden" }}>
          <Text
            color="#fff"
            width="100%"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "24px",
              fontWeight: "500",
              letterSpacing: "0.48px",
              lineHeight: "155%",
            }}
          >
            Top <strong>10 compis</strong> con los que te has reunido
          </Text>
          <Flex
            flexDirection="column"
            justifyContent="space-around"
            height="85%"
          >
            {topTenCoworkers.map((top, i) => {
              const findPerson = people.find((person) => person.email === top);
              const teamMeeting = top === "secuoyas@secuoyas.com";
              return (
                <Box
                  key={top}
                  style={{
                    borderBottom: "1px solid #fff",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  // mt="20px"
                  maxWidth="100%"
                >
                  <Text
                    color="#fff"
                    width="100%"
                    style={{
                      fontFamily: "'Noto Serif', serif",
                      fontSize: "30px",
                      fontWeight: "500",
                      letterSpacing: "0.48px",
                      lineHeight: "155%",
                    }}
                    mr="20px"
                  >
                    {i + 1 < 10 ? `0${i + 1}` : i + 1}
                  </Text>
                  <Text
                    color="#1145FC"
                    maxWidth="100%"
                    style={{
                      fontFamily: "'Abril Fatface', serif",
                      fontSize: "30px",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.6px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {findPerson
                      ? findPerson.name
                      : teamMeeting
                      ? "Reunión de equipo"
                      : top}
                  </Text>
                </Box>
              );
            })}
          </Flex>
        </div>
      )}
    </StepWrapper>
  );
};

export default Google;
