import React from "react";

import * as S from "./style";

interface HTMLRenderProps {
  children: string;
}

const HTMLRender = ({ children }: HTMLRenderProps) => {
  const message = children
    // remove unnecessary line breaks
    .replace(new RegExp("<p>&nbsp;</p>", "g"), "")
    .replace(new RegExp("<br><br>", "g"), "<br>")
    .replace(new RegExp("<p><br>", "g"), "<p>")
    // open images on click
    .replace(new RegExp("<img", "g"), '<img onclick="window.open(this.src)"');

  return <S.Container dangerouslySetInnerHTML={{ __html: message }} />;
};

export default HTMLRender;
