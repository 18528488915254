import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => p.theme.spacing.xxs};
  bottom: 0;
`;

export const Tab = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${(p) => p.theme.spacing.xxs};

  & > * {
    opacity: 0;
    animation:
      fadeIn ${(p) => p.theme.timing.s} forwards,
      zoomIn ${(p) => p.theme.timing.s};
    animation-delay: ${(p) => p.theme.timing.m};
  }
`;

export const SpotifyBox = styled.div<{ isOpen: boolean }>`
  width: 320px;
  transition: all ${(p) => p.theme.timing.m};
  height: ${(p) => (p.isOpen ? `calc(152px + ${p.theme.spacing.xxs})` : 0)};
`;

export const Iframe = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  border-radius: ${(p) => p.theme.radii.m};
  overflow: hidden;
`;
