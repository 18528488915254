import { handleRequest } from "@helpers";
import { Applause, ApplausePayload, ApplausesStats } from "@types";
import { getHeaders } from "./helpers";

const remoteApiUrl = process.env.REACT_APP_REMOTE_API_URL;

const createApplause = async (payload: ApplausePayload) => {
  const urlencoded = new URLSearchParams();
  for (const [key, value] of Object.entries(payload)) {
    urlencoded.append(key, value);
  }
  const params = {
    url: `${remoteApiUrl}/applauses`,
    options: {
      ...getHeaders(),
      method: "POST",
      body: urlencoded,
    },
    errorMsg: "Error creating applause.",
  };
  return handleRequest(params);
};

const updateApplause = async (id: string, payload: ApplausePayload) => {
  const urlencoded = new URLSearchParams();
  for (const [key, value] of Object.entries(payload)) {
    urlencoded.append(key, value);
  }
  const params = {
    url: `${remoteApiUrl}/applauses/${id}`,
    options: {
      ...getHeaders(),
      method: "PUT",
      body: urlencoded,
    },
    errorMsg: "Error updating applause.",
  };
  return handleRequest(params);
};

const deleteApplause = async (id: string) => {
  const params = {
    url: `${remoteApiUrl}/applauses/${id}`,
    options: {
      ...getHeaders(),
      method: "DELETE",
    },
    errorMsg: "Error deleting applause.",
  };
  return handleRequest(params);
};

const getRemainingClaps = async (): Promise<number> => {
  const params = {
    url: `${remoteApiUrl}/applauses/remaining-claps`,
    options: getHeaders(),
    errorMsg: "Error getting the remaining claps.",
  };
  const response = await handleRequest(params);
  return response?.remainingClaps;
};

const getApplausesStats = async (): Promise<ApplausesStats> => {
  const params = {
    url: `${remoteApiUrl}/applauses/stats`,
    options: getHeaders(),
    errorMsg: "Error getting applauses stats.",
  };
  const stats = await handleRequest(params);
  return stats;
};

const getApplausesReceived = async (pagination?: {
  itemsPerPage: number;
  lastItemTimestamp?: number;
}): Promise<Array<Applause>> => {
  let url = `${remoteApiUrl}/applauses/received`;
  if (pagination) {
    const { itemsPerPage, lastItemTimestamp } = pagination;
    url = url + `?itemsPerPage=${itemsPerPage}`;
    if (lastItemTimestamp)
      url = url + `&lastItemTimestamp=${lastItemTimestamp}`;
  }
  const params = {
    url,
    options: getHeaders(),
    errorMsg: "Error getting applauses received.",
  };
  const applauses = await handleRequest(params);
  return applauses;
};

const getApplausesSent = async (
  state: "pending" | "delivered",
  pagination?: {
    itemsPerPage: number;
    lastItemTimestamp?: number;
  }
): Promise<Array<Applause>> => {
  let url = `${remoteApiUrl}/applauses/sent/${state}`;
  if (pagination) {
    const { itemsPerPage, lastItemTimestamp } = pagination;
    url = url + `?itemsPerPage=${itemsPerPage}`;
    if (lastItemTimestamp)
      url = url + `&lastItemTimestamp=${lastItemTimestamp}`;
  }
  const params = {
    url,
    options: getHeaders(),
    errorMsg: "Error getting applauses sent.",
  };
  const applauses = await handleRequest(params);
  return applauses;
};

const getLastYearApplauses = async (
  start: Date,
  end: Date
): Promise<{
  received: Array<Applause>;
  sent: Array<Applause>;
}> => {
  const url = `${remoteApiUrl}/applauses/last-year?startTime=${start}&endTime=${end}`;
  const params = {
    url,
    options: getHeaders(),
    errorMsg: "Error getting last year applauses.",
  };
  const applauses = await handleRequest(params);
  return applauses;
};

export {
  createApplause,
  updateApplause,
  deleteApplause,
  getRemainingClaps,
  getApplausesStats,
  getApplausesReceived,
  getApplausesSent,
  getLastYearApplauses,
};
