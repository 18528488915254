import React, { useEffect, useRef, useState } from "react";
import {
  VStack,
  Text,
  Flex,
  Columns,
  Column,
  Box,
  HStack,
} from "@sqymagma/elements";
import { format } from "date-fns";

import { useApp, useToast } from "@contexts";
import { Event } from "@types";
import { IconLink, Button, Avatar, Icon } from "@elements";
import { addPersonPhoto, capitalizeFirstLetter } from "@helpers";
import { useModal, useOutOfBounds } from "@hooks";
import { Modal } from "@components";

import { CalendarMark } from "../style";
import * as S from "./style";

const es = require("date-fns/locale/es").default;

const EventModal = (props: Props) => {
  const {
    close,
    htmlLink,
    calendarName,
    id,
    responsePending,
    summary,
    startTime,
    endTime,
    description,
    attendees,
    meetingLink,
    start: { date, dateTime },
    recurringEventId,
  } = props;

  const {
    deleteEvent,
    people,
    config: { openVideoPopup },
  } = useApp();
  const { addToast } = useToast();

  const { isOpen, toggleModal } = useModal(false);

  const eventModalRef = useRef() as React.MutableRefObject<HTMLElement>;
  const modalBounds = useOutOfBounds(eventModalRef);

  const eventDataRef = useRef<HTMLDivElement>(null);

  const [dataHeight, setDataHeight] = useState({
    scrollHeight: 0,
    offsetHeight: 0,
    scrollTop: 0,
  });

  const showTopShadow = dataHeight.scrollTop > 0;
  const showBottomShadow = dataHeight.scrollHeight > dataHeight.offsetHeight;

  const updateDataHeight = () => {
    if (eventDataRef.current) {
      const dataHeight = {
        scrollHeight: eventDataRef.current.scrollHeight,
        offsetHeight: eventDataRef.current.offsetHeight,
        scrollTop: eventDataRef.current.scrollTop,
      };
      setDataHeight(dataHeight);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") close();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateDataHeight();
  }, [eventDataRef]);

  const handleOpenEvent = () => window.open(htmlLink, "_blank");

  const handleDelete = async () => {
    await deleteEvent(calendarName, id);
    addToast("Evento eliminado");
    close();
  };

  const day = format(new Date(dateTime || date), "EEEE, d 'de' MMMM", {
    locale: es,
  });

  const eventAttendees = attendees?.map((person) =>
    addPersonPhoto(person, people)
  );

  const isDeleteAllowed = calendarName === "primary";

  const DeleteEvent = () => (
    <IconLink icon="trash" action={toggleModal} small inverted />
  );

  const EventVideo = () => (
    <IconLink
      icon="video"
      action={() =>
        window.open(meetingLink, "_blank", openVideoPopup ? "popup" : undefined)
      }
      small
      inverted
    />
  );

  const RecurringEventWarning = () => (
    <Text textStyle="body" color="text01">
      {" "}
      Se trata de un evento recurrente, pero solo se borrará este evento en
      particular.
    </Text>
  );

  return (
    <>
      <S.EventModal
        ref={eventModalRef}
        $height={modalBounds.height}
        $fixOutOfBounds={modalBounds.fixOutOfBounds}
      >
        <S.TopActions shadow={showTopShadow}>
          <HStack gap="xxs">
            {meetingLink && <EventVideo />}
            {isDeleteAllowed && <DeleteEvent />}
            <IconLink icon="close" action={close} small />
          </HStack>
        </S.TopActions>

        <S.EventData ref={eventDataRef} onScroll={updateDataHeight}>
          <VStack gap="xxs" py="xs" mx="xs">
            <Box>
              <Columns hs="m">
                <Column width="20px" mt="xxs">
                  <Flex width="20px" justifyContent="center">
                    <CalendarMark
                      calendarName={calendarName}
                      isPending={responsePending}
                    />
                  </Flex>
                </Column>
                <Column width="calc(100% - 32px)">
                  <Box>
                    <Text textStyle="display04" color="text01">
                      {summary}
                    </Text>
                  </Box>
                  <Box mt="xxxs">
                    <Text textStyle="bodyInline" color="text01">
                      {capitalizeFirstLetter(day)}
                      {(startTime || endTime) && " ⋅ "}
                      {startTime && `${startTime}`}
                      {endTime && ` - ${endTime}`}
                    </Text>
                  </Box>
                </Column>
              </Columns>
            </Box>

            {attendees && (
              <Box mt="xxs">
                <Columns hs="m" overflow="visible">
                  <Column width="20px" mt="xxxs" className="event-data-icon">
                    <Icon name="people" size="small" color="interactive01" />
                  </Column>
                  <Column width="calc(100% - 32px)">
                    <S.AttendeesWrapper>
                      {eventAttendees?.map((person, idx) => (
                        <Avatar
                          name={person.email}
                          image={person.photo}
                          status={person.responseStatus}
                          size="s"
                          key={idx}
                        />
                      ))}
                    </S.AttendeesWrapper>
                  </Column>
                </Columns>
              </Box>
            )}

            {description && (
              <Box mt="xxs">
                <Columns hs="m">
                  <Column width="20px" className="event-data-icon">
                    <Icon name="fileText" size="small" color="interactive01" />
                  </Column>
                  <Column width="calc(100% - 32px)">
                    <Text textStyle="body" color="text01">
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    </Text>
                  </Column>
                </Columns>
              </Box>
            )}
          </VStack>
        </S.EventData>

        <S.BottomActions shadow={showBottomShadow}>
          <Button action={handleOpenEvent} rightIcon="arrowRight2" animateHover>
            Ver en Google
          </Button>
        </S.BottomActions>
      </S.EventModal>

      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        size="S"
        title="Eliminar evento"
        mainAction={{ title: "Eliminar", action: handleDelete }}
        secondaryAction={{ title: "Cancelar", action: toggleModal }}
      >
        <Box py="s" px="xs">
          <Text textStyle="body" color="text01">
            ¿Seguro que quieres eliminar <strong>{summary}</strong>?
          </Text>
          {!!recurringEventId && <RecurringEventWarning />}
        </Box>
      </Modal>
    </>
  );
};

interface Props extends Event {
  close: () => void;
  startTime: string | null;
  endTime: string | null;
}

export default EventModal;
