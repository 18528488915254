import React, { memo, useEffect, useRef } from "react";
import { useKeyboard } from "@hooks";
import * as S from "./style";

const SliderField = (props: ITextFieldProps): JSX.Element => {
  const {
    value,
    min = 0,
    max = 100,
    defaultValue = min,
    step = 1,
    prefix,
    suffix,
    onChange,
    onEnterPress,
    autofocus = false,
  } = props;
  const bubbleRef = useRef<HTMLOutputElement>(null);

  const enterPress = useKeyboard(["enter"]);
  useEffect(() => {
    enterPress && onEnterPress && onEnterPress();
  }, [enterPress, onEnterPress]);

  const val =
    typeof value === "undefined" || value === null
      ? defaultValue
      : value > max
      ? max
      : value < min
      ? min
      : value;

  useEffect(() => {
    onChange(Number(val));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bubbleRef.current) {
      bubbleRef.current.style.left = setBubbleLeft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bubbleRef, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(Number(newValue));
  };

  const setBubbleLeft = () => {
    const half = Number(((val - min) * 100) / (max || 1 - min));
    return `calc(${half}% + (${8 - half * 0.16}px))`;
  };

  return (
    <S.Slider>
      <S.Bubble ref={bubbleRef}>
        {prefix && `${prefix}`}
        {val}
        {suffix && `${suffix}`}
      </S.Bubble>
      <S.Input
        value={val}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autofocus}
      />
    </S.Slider>
  );
};

export interface ITextFieldProps {
  value: number | undefined | null;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  suffix?: string;
  onChange: (value: number) => void;
  onEnterPress?: () => void;
  autofocus?: boolean;
}

export default memo(SliderField);
