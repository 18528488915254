import { handleRequest } from "@helpers";
import { HubPlannerResource } from "@types";
import { getHeaders } from "./helpers";

const remoteApiUrl = process.env.REACT_APP_REMOTE_API_URL;

const getHolidays = async () => {
  const params = {
    url: `${remoteApiUrl}/hubplanner/holidays`,
    options: getHeaders(),
    errorMsg: "Error while getting holidays.",
  };
  return handleRequest(params);
};

const getVacations = async () => {
  const params = {
    url: `${remoteApiUrl}/hubplanner/vacations`,
    options: getHeaders(),
    errorMsg: "Error while getting vacations.",
  };
  return handleRequest(params);
};

const getTimesheets = async (start: Date, end: Date) => {
  const params = {
    url: `${remoteApiUrl}/hubplanner/timesheets?startTime=${start}&endTime=${end}`,
    options: getHeaders(),
    errorMsg: "Error while getting timesheets.",
  };
  return handleRequest(params);
};

const getResources = async (): Promise<HubPlannerResource[]> => {
  const params = {
    url: `${remoteApiUrl}/hubplanner/resources`,
    options: getHeaders(),
    errorMsg: "Error while getting resources.",
  };
  return handleRequest(params);
};

export { getHolidays, getVacations, getTimesheets, getResources };
