import styled, { keyframes } from "styled-components";

import mask from "@images/masks/mascara-proyectos1.svg";

const getBackground = (index: number) => {
  const colors = ["#ffccbb", "#F4C9FF", "#D7C9FF", "#D4E0F7"];
  return colors[index % colors.length];
};

const getPercentageBackground = (index: number) => {
  const colors = ["#e2a28e", "#D76FF1", "#9E8DCE", "#829AC8"];
  return colors[index % colors.length];
};

const getEventBackground = (index: number) => {
  const colors = [
    "#FEE0D6",
    "#FFC9E0",
    "#F4C9FF",
    "#D5C7FF",
    "#D4E3FF",
    "#E0FBFF",
    "#E9FFEC",
    "#FFFBE9",
  ];
  return colors[index % colors.length];
};

const Circle = styled.div<{
  $index: number;
  $percentage: string;
}>`
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: ${(p) => getBackground(p.$index)};

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: ${(p) => p.$percentage}%;
    height: ${(p) => p.$percentage}%;
    border-radius: 50%;
    background: ${(p) => getPercentageBackground(p.$index)};
  }
`;

const EventCircle = styled.div<{
  $index: number;
}>`
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background: ${(p) => getEventBackground(p.$index)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Scrolling = keyframes`
0% { transform: translateX(0) }
100% { transform: translateX(-124%) }
`;

const Marquee = styled.div`
  overflow: hidden;
  margin-inline: -24px;
  margin-top: 32px;

  > ul {
    display: flex;
    animation: ${Scrolling} 5s linear infinite;
  }
`;

const GifHubPlanner = styled.div<{
  $image: string;
}>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url(${mask});
  mask-position: center;
  mask-size: 350px;
  mask-repeat: no-repeat;
  width: 350px;
  height: 350px;
  position: absolute;
  top: -10px;
  left: -100px;
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 3000ms;
  animation-fill-mode: forwards;
`;

const GifTraining = styled.div<{ $image: string }>`
  position: absolute;
  bottom: 48px;
  right: 0;
  z-index: -1;
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50% 0 0 50%;
  width: 313px;
  height: 245px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 5000ms;
  animation-fill-mode: forwards;
`;

const Appear = keyframes`
0% { transform: translateY(100%) }
100% { transform: translateY(0) }
`;

const GifEnd = styled.div<{ $image: string }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: calc(-329px / 2);
  z-index: -1;
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: calc(329px / 2) calc(329px / 2) 0 0;
  width: 329px;
  height: 567px;
  transform: translateY(100%);
  animation-name: ${Appear};
  animation-duration: 500ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
`;

export { Circle, EventCircle, Marquee, GifHubPlanner, GifTraining, GifEnd };
