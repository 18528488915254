import React, { useState } from "react";
import { VStack, Flex, Columns, Column, Text } from "@sqymagma/elements";
import { useHistory } from "react-router";
import { format } from "date-fns";

import { useAuth } from "@contexts";
import { googleApi } from "@services";
import { TextField, Button } from "@elements";
import { WidgetBox } from "@components";

import * as S from "./style";

const CamelSteps = () => {
  const [steps, setSteps] = useState("");
  const [error, setError] = useState(false);
  const history = useHistory();
  const { REACT_APP_CAMELS_GSHEET_ID: spreadsheetId } = process.env;
  const auth = useAuth();
  const { user } = auth;

  const sendSteps = () => {
    if (!spreadsheetId) return;
    const payload = { spreadsheetId, range: "Carreras!A1:ZZ100" };
    googleApi.getSheet(payload).then((rows) => {
      if (rows && rows.length) {
        const today = format(new Date(), "dd/MM/yyyy");
        const values = rows;
        if (values[0][values[0].length - 1] !== today) {
          values[0].push(today);
          values.forEach((value, index) => {
            if (index !== 0) {
              value[values[0].length - 1] = "0";
            }
          });
        }
        const existingCamel = values.find((value) => value[0] === user?.email);
        if (!existingCamel) {
          const newCamel: Array<string> = [];
          newCamel.push(user?.email || "");
          values[0].forEach((element, i) => {
            if (!newCamel[i]) {
              newCamel[i] = "0";
            }
          });
          values.push(newCamel);
        }

        values.forEach((value) => {
          if (value[0] === user?.email) {
            if (value[values[0].length - 1]) {
              value[values[0].length - 1] = steps.replace(/\D/g, "");
            } else {
              value.push(steps.replace(/\D/g, ""));
            }
          }
        });

        googleApi
          .writeSheet(spreadsheetId, "Carreras!A1:ZZ100", values)
          .then(() => {
            history.push("/camels");
          });
        setTimeout(() => {
          setError(true);
        }, 2000);
      }
    });
  };

  const buttonProps = {
    disabled: !steps.length || Number.isNaN(Number(steps)),
    action: sendSteps,
    children: "Enviar",
  };

  return (
    <WidgetBox>
      <VStack gap="xxs">
        <S.CamelIcon />
        <Text textStyle="subtitle01" color="text01" weight="bold">
          Participa en la carrera de Kamellos
        </Text>
        <Flex minHeight={30} alignItems="center">
          <Columns
            hs="xxs"
            alignItems="flex-end"
            width="100%"
            overflow="visible"
          >
            <Column width="80%">
              <TextField
                value={steps}
                onChange={setSteps}
                placeholder="Introduce tus pasos semanales"
              />
            </Column>
            {error && (
              <Column width="100%" my="s">
                <Text textStyle="bodyInline" color="red">
                  Parece que hubo un error al enviar los pasos. Al iniciar
                  sesión en Remote acepta los permisos de Google e inténtalo de
                  nuevo.
                </Text>
              </Column>
            )}
            <Column width="20%">
              <Button {...buttonProps} rightIcon="arrowRight2" animateHover />
            </Column>
          </Columns>
        </Flex>
      </VStack>
    </WidgetBox>
  );
};

export default CamelSteps;
