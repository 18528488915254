import firebase from "./config";
import { getAnalytics, logEvent as log } from "firebase/analytics";

const signIn = async (idToken: string) => {
  const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
  return await firebase.auth().signInWithCredential(credential);
};

const logEvent = (event: string, params?: Record<string, string>) => {
  const analytics = getAnalytics();
  log(analytics, event, params);
};

const methods = { signIn, logEvent };

const firebaseWithMethods = Object.assign(firebase, methods);

export default firebaseWithMethods;
