import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { VStack } from "@sqymagma/elements";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MenuLink = styled(NavLink as any)`
  ${(p) => p.theme.textStyle.headingxxl};
  color: ${(p) => p.theme.colors.text01};
  cursor: pointer;
`;

const Submenu = styled(VStack)`
  width: fit-content;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SubmenuLink = styled(NavLink as any)<{ active: boolean | undefined }>`
  display: flex;
  align-content: center;
  ${(p) => p.theme.textStyle.headingxl};
  font-weight: ${(p) => (p.active ? "bold" : "inherit")};
  cursor: pointer;

  svg {
    fill: ${(p) => p.theme.colors.text01};
    margin-left: ${(p) => p.theme.spacing.xxs};
  }
`;

export { MenuLink, Submenu, SubmenuLink };
