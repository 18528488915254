const averageMsg = [
  [
    "Hoy estamos bajitos de ánimo",
    "Pide un abrazo",
    "No hay día malo que se nos resista",
    "Necesitamos un 'Ctrl + Alt + Suprimir’",
    "Necesitamos abrazos virtuales",
  ],
  [
    "Necesitamos unos manolitos",
    "Necesitamos extra de chocolate",
    "¡Ánimo equipo!",
  ],
  [
    "Así sí que mola",
    "Hoy volvemos a ser niños",
    "Hasta las plantas sonríen hoy",
    "Estamos que nos salimos",
    "¡Esta emoción contagia!",
  ],
];

export { averageMsg };
