import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { VStack, HStack, Text, Box } from "@sqymagma/elements";
import { useWindowResize } from "beautiful-react-hooks";
import { motion } from "framer-motion";

import { useAuth } from "@contexts";
import { Icon } from "@elements";

import bg1 from "@videos/video-1.mp4";
import bg2 from "@videos/video-2.mp4";
import bg3 from "@videos/video-3.mp4";
import bg4 from "@videos/video-4.mp4";
import bg5 from "@videos/video-5.mp4";

import * as S from "./style";

const bgArr = [bg1, bg2, bg3, bg4, bg5];
const n = Math.floor(Math.random() * bgArr.length);
const bg = bgArr[n];

const SignIn = () => {
  const auth = useAuth();
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { user } = auth;

  useEffect(() => {
    if (user) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSignIn = () => {
    auth.signIn();
  };

  useWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  return (
    <motion.div
      exit="exit"
      variants={{
        exit: {
          opacity: 0,
          transition: { when: "afterChildren", ease: "easeIn" },
        },
      }}
    >
      <S.HeaderContainer>
        <S.Logo href="https://www.secuoyas.com" />
      </S.HeaderContainer>
      <S.Video
        autoPlay
        loop
        muted
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
      >
        <source src={bg} type="video/mp4" />
      </S.Video>
      <S.TitleWrapper
        style={{
          margin: windowWidth > 1215 ? "0 0 0 100px" : "0 auto",
        }}
      >
        <S.SectionContainer>
          <S.TiltWrapper perspective={800} scale={1.02} trackOnWindow={true}>
            <S.HeroTextName>remote</S.HeroTextName>
          </S.TiltWrapper>
          <S.HeroTextSlogan>No te pierdas nada</S.HeroTextSlogan>
        </S.SectionContainer>
      </S.TitleWrapper>

      <S.AccessWrapper
        width={windowWidth > 1215 ? "450px" : "100%"}
        variants={{
          exit: { x: "100%", transition: { ease: "easeIn" } },
        }}
      >
        <S.AccessContainer>
          <VStack gap="xs" textAlign="center">
            <S.AppName display={{ default: "block", xl: "none" }}>
              remote
            </S.AppName>
            <S.GoogleBtn type="button" onClick={handleSignIn}>
              Iniciar sesión con Google
            </S.GoogleBtn>
            <HStack gap="xxs" mt="xxs" alignItems="center">
              <Icon name="info" width={18} color="light" />
              <Box textAlign="left">
                <Text color="light" textStyle="bodyInline">
                  Al iniciar sesión concede acceso a los servicios de Google
                  solicitados para poder utilizar todas las funcionalidades.
                </Text>
              </Box>
            </HStack>
          </VStack>
        </S.AccessContainer>
      </S.AccessWrapper>
    </motion.div>
  );
};

export default SignIn;
