import styled from "styled-components";
import { Box, VStack } from "@sqymagma/elements";

const Video = styled(Box)`
  position: relative;
  background-color: #111111;
  outline: 1px solid ${(p) => p.theme.colors.line01};
  border-radius: ${(p) => p.theme.radii.m};
  overflow: hidden;
`;

const VideoInformation = styled(VStack)`
  color: ${(p) => p.theme.colors.text01};
  cursor: pointer;
  transition: color ${(p) => p.theme.timing.hover};

  &:hover {
    color: ${(p) => p.theme.colors.interactive01Hover};
  }
`;

const VideoLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Iframe = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  min-height: fit-content;

  & iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
`;

export { Video, VideoInformation, VideoLoader, Iframe };
