import styled from "styled-components";

import { ReactComponent as ArrowRight } from "@icons/system/arrow-circle-right.svg";

const Arrow = styled(ArrowRight)`
  fill: ${(p) => p.theme.colors.ui01};
  opacity: 0;
  transition: all ${(p) => p.theme.timing.m};
`;

const MessageWrapper = styled.div`
  display: flex;
  cursor: pointer;

  &:hover ${Arrow} {
    opacity: 1;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const Unread = styled.div`
  background-color: ${(p) => p.theme.colors.ui01};
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-left: ${(p) => p.theme.spacing.xxs};
`;

export { Arrow, MessageWrapper, Message, Unread };
