import styled from "styled-components";
import { Flex } from "@sqymagma/elements";

const Background = styled(Flex)<{ $image: string }>`
  background-image: ${(p) => `url(${p.$image})`};
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(10px) grayscale(1);
  transition: all ${(p) => p.theme.timing.xs} ease-in;
`;

const Overlay = styled.div`
  background-color: ${(p) => p.theme.colors.senaryBackground};
  opacity: 0.9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity ${(p) => p.theme.timing.xs} ease-in;
`;

const Item = styled(Flex)`
  border: 1px solid ${(p) => p.theme.colors.line03};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.theme.spacing.xs};
  margin: ${(p) => p.theme.spacing.xxs};
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);

  &:hover {
    cursor: pointer;
    box-shadow: ${(p) => p.theme.shadows.tiny};
    transform: scale(1.02);

    ${Background} {
      filter: none;
    }

    ${Overlay} {
      opacity: 0.85;
    }
  }
`;

export { Item, Background, Overlay };
