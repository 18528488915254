import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { isSameDay } from "date-fns";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Auth as FirebaseAuth } from "firebase/auth";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { useReward } from "react-rewards";
import { useHistory } from "react-router-dom";

import { useAuth, useStyle } from "@contexts";
import {
  googleApi,
  weatherApi,
  coffeesApi,
  yourlsApi,
  remoteApi,
} from "@services";
import {
  getMonthDates,
  getDayDates,
  mapResponsePending,
  filterDeclinedEvent,
  notifyEvent,
  requestNotificationPermission,
  addMeetingLink,
  getTool,
  getReward,
  removeInvalidGoogleAccounts,
  addNamesFromHubPlanner,
  geolocalize,
  notifyAppEvent,
} from "@helpers";
import { useEffectExceptOnMount } from "@hooks";
import { ONE_MINUTE_IN_MS } from "@constants";
import firebase from "@database";
import { events as appEvents } from "@data";
import { updateAppTime } from "@config";

import { AppState, initialState, reducer } from "./reducer";
import { AppContext } from "./provider";

export const useApp = () => {
  return useContext(AppContext);
};

export const useAppProvider = () => {
  const { user, signOut, refreshToken } = useAuth();
  const { setLocalStorageTheme } = useStyle();

  const history = useHistory();

  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef<AppState>(state);
  stateRef.current = state;

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [fbUser] = useAuthState(firebase.auth() as unknown as FirebaseAuth);
  const db = firebase.firestore();
  const userRef = db.collection("users").doc(fbUser?.uid);
  const [userDoc, loadingDoc] = useDocument(
    userRef as unknown as DocumentReference<DocumentData>
  );

  const { reward, isAnimating } = useReward("reward", "balloons", {
    elementCount: 30,
    elementSize: 40,
    lifetime: 1000,
    spread: 60,
  });

  const getWeather = () => {
    geolocalize(async (position) => {
      const { coords } = position;
      const weather = await weatherApi.getWeather(coords);
      dispatch({ weather });
    });
  };

  const getDayEvents = async (day: Date) => {
    const dayDates = getDayDates(day);
    const response = await googleApi.getEvents(dayDates);
    const dayEvents = response
      ?.filter((event) => filterDeclinedEvent(event, user))
      .map((event) => mapResponsePending(event, user))
      .map(addMeetingLink);
    return dayEvents || [];
  };

  const getSelectedDayEvents = async () => {
    const { selectedDay } = stateRef.current;
    const selectedDayEvents = await getDayEvents(selectedDay);
    if (selectedDayEvents) dispatch({ selectedDayEvents });
  };

  const getTodayEvents = async () => {
    const now = new Date();
    const todayEvents = await getDayEvents(now);
    if (todayEvents) dispatch({ todayEvents });
  };

  const getMonthEvents = async () => {
    const { selectedMonth } = stateRef.current;
    const response = await googleApi.getEvents(selectedMonth);
    const monthEvents = response
      ?.filter((event) => filterDeclinedEvent(event, user))
      .map((event) => mapResponsePending(event, user));

    if (monthEvents) dispatch({ monthEvents });
  };

  const changeSelectedDay = (selectedDay: Date) => {
    dispatch({ selectedDay });
  };

  const changeSelectedMonth = (date: Date) => {
    const selectedMonth = getMonthDates(date);
    dispatch({ selectedMonth });
  };

  const getEmails = async (number: number) => {
    const emails = await googleApi.getEmails(number);
    if (emails) dispatch({ emails, numberOfEmails: number });
  };

  const markReadEmail = async (id: string) => {
    await googleApi.markReadEmail(id);
    getEmails(state.numberOfEmails);
  };

  const deleteEvent = async (calendarName: string, eventId: string) => {
    await googleApi.deleteEvent(calendarName, eventId);
    getSelectedDayEvents();
    getMonthEvents();
  };

  const getPeople = async () => {
    const googlePeople = await googleApi.getPeople();
    const hubPlannerResources = await remoteApi.getResources(); // get resources from Hub Planner to retrieve people names
    const validPeople = removeInvalidGoogleAccounts(googlePeople);
    const people = addNamesFromHubPlanner(validPeople, hubPlannerResources);
    if (people) dispatch({ people });
  };

  const addRecentTools = async (newTool: string) => {
    const { recentTools } = stateRef.current;
    const toolsArr = recentTools.filter((tool) => tool !== newTool);
    toolsArr.unshift(newTool);
    await userRef.set({ recentTools: toolsArr }, { merge: true });
  };

  const updateRecentTools = async (recentTools: string[]) => {
    await userRef.set({ recentTools }, { merge: true });
  };

  const updateMeetings = async (meetings: string[]) => {
    await userRef.set({ meetings }, { merge: true });
  };

  const setCurrentDay = () => {
    const { selectedDay } = stateRef.current;
    const now = new Date();
    const isDifferentDay = !isSameDay(selectedDay, now);
    if (isDifferentDay) {
      dispatch({ selectedDay: now });
    }
  };

  const getCoffees = async () => {
    const coffees = await coffeesApi.getCoffees();
    const { todayEvents, selectedDayEvents } = stateRef.current;
    if (coffees) dispatch({ coffees, todayEvents, selectedDayEvents });
  };

  const addShortUrl = async (newUrl: string) => {
    const { shortUrls } = stateRef.current;
    const urlsArr = shortUrls.filter((url) => url !== newUrl);
    urlsArr.unshift(newUrl);
    await userRef.set({ shortUrls: urlsArr }, { merge: true });
  };

  const removeShortUrl = async (
    deletedUrl: string,
    deletedShortUrl: string
  ) => {
    yourlsApi.yourls({ action: "delete", shorturl: deletedShortUrl });
    const { shortUrls } = stateRef.current;
    const urlsArr = shortUrls.filter((url) => url !== deletedUrl);
    await userRef.set({ shortUrls: urlsArr }, { merge: true });
  };

  const getHolidays = async () => {
    const holidays = await remoteApi.getHolidays();
    if (holidays) dispatch({ holidays });
  };

  const getVacations = async () => {
    const vacations = user && (await remoteApi.getVacations());
    if (vacations) dispatch({ vacations });
  };

  const changeConfig = async (option: Record<string, unknown>) => {
    const { config } = stateRef.current;
    const newConfig = { ...config, ...option };
    await userRef.set({ config: newConfig }, { merge: true });
  };

  const getRewards = async () => {
    if (user) {
      const reward = await getReward(user);
      dispatch({ reward });
    }
  };

  const getRemainingClaps = async () => {
    const remainingClaps = await remoteApi.getRemainingClaps();
    const { applauses } = stateRef.current;
    dispatch({ applauses: { ...applauses, remainingClaps } });
  };

  const getLastMoods = async () => {
    const lastMoods = await remoteApi.getMoods("all", {
      itemsPerPage: 10,
    });
    if (lastMoods) dispatch({ lastMoods });
  };

  const updateDataOnLoad = () => {
    getRewards();
    getHolidays();
    getVacations();
    getPeople();
  };

  const updateData = async () => {
    await refreshToken();

    const { numberOfEmails, lastUpdate } = stateRef.current;

    const now = new Date();
    const isDifferentDay = lastUpdate && !isSameDay(lastUpdate, now);
    if (isDifferentDay) {
      changeSelectedDay(now);
      updateDataOnLoad();
    }

    getWeather();
    getEmails(numberOfEmails);
    getSelectedDayEvents();
    getTodayEvents();
    getMonthEvents();
    getCoffees();

    dispatch({ lastUpdate: now });
  };

  const handleWindowFocus = () => {
    if (document.visibilityState === "visible") {
      updateData();
      dispatch({ time: new Date() });
    }
  };

  const notifyEvents = () => {
    const {
      todayEvents,
      config: { openVideoPopup, notifyEvents, applausesReminder },
    } = stateRef.current;
    notifyEvents &&
      todayEvents.forEach((event) => notifyEvent(event, openVideoPopup));
    applausesReminder &&
      notifyAppEvent(appEvents.applausesReminder, stateRef.current, history);
    dispatch({ time: new Date() });
  };

  const checkValidData = (data?: DocumentData) => {
    if (!data) return true;
    const { recentTools = [] } = data;
    const validRecentTools = recentTools.filter(getTool);
    const isValidRecentTools = validRecentTools.length === recentTools.length;
    if (!isValidRecentTools) updateRecentTools(validRecentTools);
    return isValidRecentTools;
  };

  const fireReward = () => {
    if (!isAnimating) reward();
  };

  useEffect(() => {
    if (!fbUser) signOut();
  }, [fbUser, signOut]);

  useEffect(() => {
    updateDataOnLoad();
    updateData();
    const dataUpdater = setInterval(updateData, updateAppTime);
    window.addEventListener("focus", handleWindowFocus);

    requestNotificationPermission();
    const eventsNotifier = setInterval(notifyEvents, ONE_MINUTE_IN_MS);

    return () => {
      clearInterval(dataUpdater);
      clearInterval(eventsNotifier);
      window.removeEventListener("focus", handleWindowFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ensure that the necessary data from Firebase is loaded
  useEffect(() => {
    const data = userDoc?.data();
    if (data) {
      const { theme, config, ...rest } = data;
      theme && setLocalStorageTheme(theme);
      config && dispatch({ config: { ...state.config, ...config } });
      rest && dispatch(rest);
    }
    if (!loadingDoc && checkValidData(data)) setIsDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDoc, loadingDoc]);

  // add email to user's collection
  useEffect(() => {
    !loadingDoc && userRef.set({ email: user?.email }, { merge: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDoc, user]);

  useEffectExceptOnMount(getSelectedDayEvents, [state.selectedDay]);
  useEffectExceptOnMount(getMonthEvents, [state.selectedMonth]);

  useEffect(() => {
    isDataLoaded && state.reward.type && fireReward();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded, state.reward.type]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigator = window.navigator as any;
    const numberOfComments = state.moodsWithNewComments.length;
    if (numberOfComments > 0) {
      navigator.setAppBadge && navigator.setAppBadge(numberOfComments);
    } else {
      navigator.clearAppBadge && navigator.clearAppBadge();
    }
  }, [state.moodsWithNewComments]);

  return {
    ...state,
    getEmails,
    markReadEmail,
    addRecentTools,
    updateMeetings,
    changeSelectedDay,
    changeSelectedMonth,
    setCurrentDay,
    isDataLoaded,
    addShortUrl,
    removeShortUrl,
    fireReward,
    deleteEvent,
    changeConfig,
    getRemainingClaps,
    getLastMoods,
  };
};
