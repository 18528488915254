import React, { useEffect, useState } from "react";
import { Flex, Text } from "@sqymagma/elements";

import taxonomy1 from "@images/taxonomy1.png";
import taxonomy2 from "@images/taxonomy2.png";
import taxonomy3 from "@images/taxonomy3.png";
import taxonomy4 from "@images/taxonomy4.png";
import taxonomy5 from "@images/taxonomy5.png";
import taxonomy6 from "@images/taxonomy6.png";
import taxonomy7 from "@images/taxonomy7.png";
import taxonomy8 from "@images/taxonomy8.png";
import taxonomy9 from "@images/taxonomy9.png";
import taxonomy10 from "@images/taxonomy10.png";
import taxonomy11 from "@images/taxonomy11.png";
import taxonomy12 from "@images/taxonomy12.png";

import { googleApi } from "@services";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";
import { ImagesWrapper } from "./style";

type TaxonomyProps = {
  active: boolean;
  wrappedStart: Date;
  wrappedEnd: Date;
};

const Taxonomy = ({ active, wrappedStart, wrappedEnd }: TaxonomyProps) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "Taxonomy");
  const durations = config?.children;

  const [activeItem, setActiveItem] = useState(0);
  const [taxonomyCount, setTaxonomyCount] = useState(0);
  const taxonomies = [
    { src: taxonomy1, rotate: "0" },
    { src: taxonomy2, rotate: "3.691deg" },
    { src: taxonomy3, rotate: "-2.603deg" },
    { src: taxonomy4, rotate: "0.62deg" },
    { src: taxonomy5, rotate: "-1.518deg" },
    { src: taxonomy6, rotate: "3.306deg" },
    { src: taxonomy7, rotate: "-1.559deg" },
    { src: taxonomy8, rotate: "3.451deg" },
    { src: taxonomy9, rotate: "-2.126deg" },
    { src: taxonomy10, rotate: "0" },
    { src: taxonomy11, rotate: "-2.976deg" },
    { src: taxonomy12, rotate: "7.106deg" },
  ];

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    const { REACT_APP_TAXONOMY_GSHEET_ID: taxonomySpreadsheetId } = process.env;

    if (active && taxonomySpreadsheetId) {
      if (durations) {
        setTimeout(() => {
          nextItem(activeItem + 1);
        }, durations[activeItem] * 1000);
      }

      googleApi
        .getSheet({
          spreadsheetId: taxonomySpreadsheetId,
          range: "Taxonomies!A1:ZZ100",
        })
        .then((rows) => {
          if (rows) {
            const headers = rows[0];
            const dateIndex = headers.findIndex((h) => h.trim() === "date");
            let taxonomyCount = 0;

            rows.forEach((r) => {
              if (r[dateIndex] && r[dateIndex].trim() !== "date") {
                const date = r[dateIndex].split("/");
                const taxonomyDate = new Date(
                  `${date[1]}/${date[0]}/${date[2]}`
                );

                if (
                  taxonomyDate >= wrappedStart &&
                  taxonomyDate <= wrappedEnd
                ) {
                  taxonomyCount++;
                }
              }
            });

            setTaxonomyCount(taxonomyCount);
            return;
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="linear-gradient(12deg, #AAB2FF 0%, #6F7BF7 97.4%)"
      $deg1="#84FFC9"
      $deg2="#AAB2FF"
      $rotate="47.351deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <Flex flexDirection="column" justifyContent="center">
          <Text
            color="#fff"
            width="154px"
            display="block"
            mb="40px"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "112px",
              lineHeight: "97px",
            }}
          >
            ta xo no my
          </Text>
          <Text
            color="#fff"
            width="90%"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "27px",
              fontWeight: "500",
              lineHeight: "31px",
            }}
          >
            ha tratado de mantenernos al día y{" "}
            <strong style={{ fontWeight: "900" }}>
              darle voz a nuestras ideas.
            </strong>
          </Text>
        </Flex>
      )}
      {activeItem === 1 && (
        <Flex flexDirection="column" justifyContent="space-between">
          <ImagesWrapper>
            {activeItem === 1 &&
              taxonomies.map(({ src, rotate }, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Taxonomy #${index + 1}`}
                  width="287px"
                  height="359px"
                  style={{
                    transform: `rotate(${rotate}) translateX(-50%)`,
                    opacity: index === 0 ? 1 : 0,
                    animationName: "fadeIn",
                    animationDuration: "200ms",
                    animationDelay: `${index * 0.5}s`,
                    animationFillMode: "forwards",
                  }}
                />
              ))}
          </ImagesWrapper>

          {activeItem === 1 && (
            <div
              style={{
                position: "relative",
                opacity: 0,
                animationName: "fadeIn",
                animationDuration: "200ms",
                animationDelay: "5.5s",
                animationFillMode: "forwards",
                textAlign: "right",
              }}
            >
              <Text
                color="#FCE883"
                display="block"
                align="right"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "126px",
                }}
              >
                {taxonomyCount}
              </Text>
              <Text
                color="#fff"
                width="90%"
                style={{
                  fontFamily: "'Noto Serif', serif",
                  fontSize: "27px",
                  fontWeight: "500",
                  lineHeight: "31px",
                }}
              >
                han sido las{" "}
                <strong style={{ fontWeight: "900" }}>ediciones</strong> que has
                recibido este año.
              </Text>
            </div>
          )}
        </Flex>
      )}
    </StepWrapper>
  );
};

export default Taxonomy;
