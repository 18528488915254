import React, { useEffect, useState } from "react";
import { Box } from "@sqymagma/elements";
import { XMasonry, XBlock } from "react-xmasonry";
import { io } from "socket.io-client";
import { useHistory } from "react-router-dom";

import { getExcerptFromHTML } from "@helpers";
import { useApp } from "@contexts";
import { WidgetBox } from "@components";
import { Button } from "@elements";
import MoodCard from "@screens/Desktop/Mood/Timeline/MoodCard";

import * as S from "./style";

const numberOfMoods = 4;

const LastMoods = () => {
  const { lastMoods, getLastMoods } = useApp();
  const history = useHistory();
  const [isHover, setIsHover] = useState(false);
  const widgetRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    getLastMoods();

    // WebSocket to know when there is an update in moods to retrieve them
    const socket = io(process.env.REACT_APP_REMOTE_API_URL as string);
    socket.on("mood update", getLastMoods);
    return () => {
      socket.off("mood update", getLastMoods);
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.addEventListener("mouseenter", () => setIsHover(true));
      widgetRef.current.addEventListener("mouseleave", () => setIsHover(false));
    }
  }, [widgetRef, lastMoods]);

  const handleOnClick = () => {
    history.push("/mood");
  };

  if (!lastMoods.length) return <S.Empty />;

  return (
    <WidgetBox cursor="pointer" onClick={handleOnClick} ref={widgetRef}>
      <Box mb="xxs">
        <Button rightIcon="arrowRight2" to="/mood" animateHover hover={isHover}>
          Últimas publicaciones
        </Button>
      </Box>
      <XMasonry responsive targetBlockWidth={150} center={false}>
        {lastMoods.slice(0, numberOfMoods).map((mood) => {
          return (
            <XBlock key={mood.id}>
              <S.Mood>
                {mood && (
                  <MoodCard
                    {...mood}
                    message={getExcerptFromHTML(mood.message, 90)}
                    readOnly
                  />
                )}
              </S.Mood>
            </XBlock>
          );
        })}
      </XMasonry>
    </WidgetBox>
  );
};

export default LastMoods;
