import React, { useState, useEffect } from "react";
import { Text, VStack, HStack, Flex } from "@sqymagma/elements";
import { addDays, format, isAfter, isSameDay, subDays } from "date-fns";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  ChartDataset,
} from "chart.js";

import { useStyle } from "@contexts";
import { THEMES } from "@constants";
import {
  createImg,
  date2DateString,
  dateString2Date,
  getFirstDayOfThisWeek,
} from "@helpers";
import { MoodStats } from "@types";
import { remoteApi } from "@services";
import { IconLink, Toggle } from "@elements";
import { useEffectExceptOnMount } from "@hooks";

import * as S from "./style";

import reallyBad from "@icons/mood/really-bad_on.svg";
import bad from "@icons/mood/bad_on.svg";
import neutral from "@icons/mood/neutral_on.svg";
import happy from "@icons/mood/happy_on.svg";
import reallyHappy from "@icons/mood/really-happy_on.svg";

const customPoints = [
  createImg(reallyBad, 30),
  createImg(bad, 30),
  createImg(neutral, 30),
  createImg(happy, 30),
  createImg(reallyHappy, 30),
];

ChartJS.register(CategoryScale, LinearScale, LineElement);
ChartJS.defaults.font.family = "'Gilroy', sans-serif";

interface AverageChartProps {
  data: Array<[string, number]> | undefined;
  setStats: React.Dispatch<React.SetStateAction<MoodStats | undefined>>;
}

const es = require("date-fns/locale/es").default;

const AverageChart = (props: AverageChartProps) => {
  const { data, setStats } = props;

  const labels = data?.map((entry) => entry[0]);
  const values = data?.map((entry) => entry[1]);

  const [groupBy, setGroupBy] = useState<"day" | "week">("day");
  const [startTime, setStartTime] = useState(
    date2DateString(getFirstDayOfThisWeek())
  );
  const [datesRange, setDatesRange] = useState("");
  const isLastAvailableWeek = isSameDay(
    dateString2Date(startTime),
    getFirstDayOfThisWeek()
  );

  useEffectExceptOnMount(() => {
    const filters = { groupBy, startTime };
    remoteApi.getMoodStats(filters).then((stats) => setStats(stats));
  }, [groupBy, startTime]);

  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;

  const datasets: ChartDataset<"line", number[] | undefined>[] = [
    {
      data: values,
      tension: 0,
      borderColor: isDarkTheme ? "#ececec" : "#A6B0BF",
      borderWidth: 2,
      hoverBorderWidth: 2,
      pointBackgroundColor: isDarkTheme ? "#1a1e24" : "#ffffff",
      pointStyle: values?.map((value) => customPoints[value - 1]),
    },
  ];

  useEffect(() => {
    if (!labels) return;
    if (groupBy === "day") {
      const startDate = dateString2Date(labels[0]);
      const weekNumber = format(startDate, "w", {
        locale: es,
        firstWeekContainsDate: 7,
      });
      setDatesRange(`Semana ${weekNumber}`);
    } else {
      setDatesRange(`De la semana ${labels[0]} a la ${labels[4]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const changeGroupBy = (value: boolean) => setGroupBy(value ? "week" : "day");

  const go = (bound: "before" | "next") => () => {
    const date = dateString2Date(startTime);
    const days = groupBy === "day" ? 7 : 35;
    let newDate =
      bound === "before" ? subDays(date, days) : addDays(date, days);
    if (isAfter(newDate, getFirstDayOfThisWeek()))
      newDate = getFirstDayOfThisWeek();
    setStartTime(date2DateString(newDate));
  };

  return (
    <S.Wrapper>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <VStack gap="xxxs">
          <Text textStyle="headings" color="text01">
            Resumen {groupBy === "day" ? "semanal" : "mensual"}
          </Text>
          <Text textStyle="body" color="text01">
            {datesRange || <>&nbsp;</>}
          </Text>
        </VStack>
        <HStack gap="xxs" alignItems="center">
          <Text textStyle="body" color="text01">
            Semanal
          </Text>
          <Toggle
            name="stats-view"
            value={groupBy === "week"}
            onChange={changeGroupBy}
          />
          <Text textStyle="body" color="text01">
            Mensual
          </Text>
        </HStack>
      </Flex>

      <S.ChartWrapper>
        <IconLink icon="arrowLeft" inverted action={go("before")} />
        <S.ChartContainer>
          <Line
            height={175}
            data={{
              labels,
              datasets,
            }}
            options={{
              indexAxis: "x" as const,
              responsive: true,
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  offset: true,
                  grid: { color: isDarkTheme ? "#333333" : "#cccccc" },
                  ticks: {
                    color: isDarkTheme ? "#ffffff" : "#101010",
                  },
                },
                y: {
                  beginAtZero: true,
                  max: 6,
                  border: { width: 0 },
                  grid: {
                    display: true,
                    color: isDarkTheme ? "#333333" : "#cccccc",
                  },
                  ticks: {
                    display: false,
                    stepSize: 1,
                  },
                },
              },
            }}
          />
        </S.ChartContainer>
        <IconLink
          icon="arrowRight"
          inverted
          action={go("next")}
          disabled={isLastAvailableWeek}
        />
      </S.ChartWrapper>
    </S.Wrapper>
  );
};

export default AverageChart;
