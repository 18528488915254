import React, { useEffect, useState } from "react";
import { Text, Box, Columns, Column, VStack, Flex } from "@sqymagma/elements";

import { yourlsApi } from "@services";
import { useApp } from "@contexts";
import { isReqOk } from "@helpers";
import { UrlShortener } from "@components";
import { Loader } from "@elements";
import { ShortUrl } from "@types";

import shortUrlsImg from "@images/short-urls.png";

import List from "./List";
import { Background } from "./atoms";

const ShortUrls = () => {
  const { shortUrls } = useApp();
  const [yourlsUrls, setYourlsUrls] = useState<ShortUrl[] | null>(null);
  const [myYourlsUrls, setMyYourlsUrls] = useState<ShortUrl[]>();

  const getShortUrls = async () => {
    const response = await yourlsApi.yourls({ action: "stats" });
    if (!response) return;
    const { statusCode, links } = response;
    if (isReqOk(statusCode)) {
      setYourlsUrls(links);
    }
  };

  useEffect(() => {
    getShortUrls();
  }, [shortUrls]);

  useEffect(() => {
    if (!yourlsUrls) return;

    const myUrls = [];
    for (const yourlsUrl in yourlsUrls) {
      const yourlsUrlObj = yourlsUrls[yourlsUrl];
      if (shortUrls.includes(yourlsUrlObj.url)) {
        myUrls.push(yourlsUrlObj);
      }
    }
    setMyYourlsUrls(myUrls);
  }, [shortUrls, yourlsUrls]);

  return (
    <>
      <Background />
      <Columns style={{ overflow: "visible" }} position="relative" hs="s">
        <Column width={{ xl: 3 / 5 }}>
          <Box>
            <Text textStyle="display02" color="text01" weight="bold">
              Acortador de Url
            </Text>
          </Box>
          <Box mt="xs" mb="s">
            <Text textStyle="bodyLead" color="text01">
              Pega el enlace que quieres acortar en la caja de texto y haz click
              en acortar para obtener el resultado. Haz click en personalizar
              para poder indicar tu URL personalizada.
            </Text>
          </Box>
          <UrlShortener />
          <VStack mt="l" gap="xxs">
            <Text textStyle="subtitle01" color="text01" caps>
              Historial
            </Text>
            {myYourlsUrls ? (
              <List data={myYourlsUrls} />
            ) : (
              <Flex justifyContent="center">
                <Loader />
              </Flex>
            )}
          </VStack>
        </Column>
        <Column width={{ default: 0, xl: 2 / 5 }}>
          <Box p={["s", "m"]}>
            <img src={shortUrlsImg} alt="Short Urls" width="100%" />
          </Box>
        </Column>
      </Columns>
    </>
  );
};

export default ShortUrls;
