import React from "react";
import { Flex, Text, Box } from "@sqymagma/elements";
import { Avatar, Loader } from "@elements";
import { Camel } from "@types";
import medal from "@images/medal.svg";
import CamelsSvg from "./CamelsSvg";
import { WidgetBox } from "@components";
interface Props {
  ranking?: Array<Camel>;
}

const Ranking = (props: Props) => {
  const { ranking } = props;

  const Loading = () => (
    <Flex justifyContent="center">
      <Loader />
    </Flex>
  );

  return (
    <>
      {!ranking && (
        <Flex mt="m" justifyContent="center">
          <Loading />
        </Flex>
      )}

      {ranking && (
        <Flex justifyContent="center" mt="m">
          <CamelsSvg
            first={
              ranking[0]
                ? ranking[0]
                : { name: "", steps: 0, photo: "", email: "", average: 0 }
            }
            second={
              ranking[1]
                ? ranking[1]
                : { name: "", steps: 0, photo: "", email: "", average: 0 }
            }
            third={
              ranking[2]
                ? ranking[2]
                : { name: "", steps: 0, photo: "", email: "", average: 0 }
            }
          />
        </Flex>
      )}

      {ranking && !!ranking.length && (
        <>
          <Flex
            justifyContent="center"
            style={{ gap: "50px" }}
            flexWrap="wrap"
            mt={{ default: "xs", m: "m", l: "xl", xl: "xxxl" }}
          >
            {ranking?.slice(0, 3).map((person, idx) => (
              <Box
                key={idx}
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="end"
              >
                {idx === 0 && <img src={medal} alt="medal" width="40px" />}
                <Box
                  mt="xxs"
                  p="m"
                  border="10px solid"
                  borderColor="gold"
                  borderBottom="10px solid transparent"
                  borderRight={idx !== 0 && "10px solid"}
                  borderRightColor={idx !== 0 && "quaternaryBackground"}
                  borderTop={idx === 2 && "10px solid"}
                  borderTopColor={idx === 2 && "quaternaryBackground"}
                  borderRadius="50%"
                >
                  <Avatar image={person?.photo} name={person.name} size="l" />
                </Box>
                <Text textStyle="display04" mt="xxs">
                  {Number(person.steps).toLocaleString("es-ES")}
                </Text>
                <Text textStyle="body" color="text01">
                  pasos
                </Text>
                <Text mt="xxs" textStyle="display04" color="text01">
                  {person.name}
                </Text>
              </Box>
            ))}
          </Flex>
          <Flex
            mt="xxxl"
            justifyContent="center"
            style={{ gap: "100px" }}
            flexWrap="wrap"
            mb="m"
          >
            <Flex flexDirection="column">
              {ranking?.slice(3, 10).map((person, idx) => (
                <Box key={idx} display="flex" alignItems="center" mt="s">
                  <Text
                    textStyle="bodyLead"
                    color="text01"
                    width="20px"
                    textAlign="right"
                  >
                    {idx + 4}
                  </Text>
                  <Box ml="xs">
                    <Avatar image={person?.photo} name={person.name} size="m" />
                  </Box>
                  <Box fontSize="24px" ml="xs">
                    <Text textStyle="display04" color="text01" display="block">
                      {person.name}
                    </Text>
                    <Text textStyle="bodyLead" color="text01">
                      {Number(person.steps).toLocaleString("es-ES")}
                    </Text>
                    <Text textStyle="body" color="text01">
                      {" "}
                      pasos
                    </Text>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Flex>
          {!!ranking?.slice(10).filter((person) => Number(person.steps) > 0)
            .length && (
            <WidgetBox
              borderColor="line01"
              borderWidth={0}
              borderStyle="solid"
              mt="xl"
              mb="xxl"
              style={{ padding: 0 }}
              width="70%"
              mx="auto"
            >
              <Flex
                flexWrap="wrap"
                style={{ gap: "35px" }}
                justifyContent="flex-start"
                mb="m"
                p="xl"
                mx="auto"
              >
                {ranking
                  ?.slice(10)
                  .filter((person) => Number(person.steps) > 0)
                  .map((person, idx) => (
                    <Flex
                      key={idx}
                      width="25%"
                      minWidth="180px"
                      flexGrow="2"
                      maxWidth="30%"
                    >
                      <Avatar
                        image={person?.photo}
                        name={person.name}
                        size="m"
                      />
                      <Flex flexDirection="column" ml="s">
                        <Text mb="xxs" textStyle="display04" color="text01">
                          {person.name}
                        </Text>
                        <Text textStyle="body">
                          {Number(person.steps).toLocaleString("es-ES")} pasos
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            </WidgetBox>
          )}
        </>
      )}
    </>
  );
};

export default Ranking;
