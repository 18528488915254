import styled from "styled-components";

import { ReactComponent as Search } from "@icons/system/search.svg";
import { ReactComponent as Close } from "@icons/system/close.svg";

const SearchButton = styled(Search)`
  width: 23px;
  height: 23px;
  margin-right: ${(p) => p.theme.spacing.xxs};
  fill: ${(p) => p.theme.colors.interactive03};
`;

const ClearButton = styled(Close)`
  width: 23px;
  height: 23px;
  margin-right: ${(p) => p.theme.spacing.xxs};
  fill: ${(p) => p.theme.colors.interactive03};
  transition: all ${(p) => p.theme.timing.s};
  cursor: pointer;

  &:hover {
    fill: ${(p) => p.theme.colors.interactive03Hover};
  }
`;

const Input = styled.input`
  ${(p) => p.theme.textStyle.body}
  border: none;
  background: none;
  color: ${(p) => p.theme.colors.text01};
  padding: ${(p) => p.theme.spacing.xxs};
  width: 100%;

  &:focus {
    outline: 0;
    cursor: text;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid ${(p) => p.theme.colors.line02};
  background-color: transparent;

  &:hover {
    border-color: ${(p) => p.theme.colors.brand01};
  }

  &:focus-within {
    border-color: ${(p) => p.theme.colors.line02};

    ${SearchButton} {
      fill: ${(p) => p.theme.colors.interactive03Active};
    }
  }
`;

export { Wrapper, Input, SearchButton, ClearButton };
