import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { Text, Box, VStack, HStack } from "@sqymagma/elements";

import { ToolLink } from "@components";
import { tools as toolsSections } from "@data";

import Passwords from "./Passwords";

const Tools = () => {
  return (
    <Switch>
      <Route exact path="/tools/passwords">
        <Passwords />
      </Route>
      <Route exact path="/tools/:section">
        <RenderTools />
      </Route>
      <Route exact path="/tools">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

const RenderTools = () => {
  const { section } = useParams<{ section: string }>();
  const toolsSection = toolsSections[section];
  const { types } = toolsSection;

  return (
    <>
      <Box>
        <Text textStyle="display02" color="text01" weight="bold">
          Herramientas
        </Text>
      </Box>
      <Box>
        <Text textStyle="bodyLead" color="text01">
          {toolsSection.name}
        </Text>
      </Box>

      <VStack pt="m" gap="xxs">
        {types.map((toolType, idx) => (
          <Box key={idx} mb="xs">
            <Box mb="xxxs">
              <Text textStyle="subtitle02" color="text01">
                {toolType.name}
              </Text>
            </Box>
            <HStack flexWrap="wrap">
              {toolType.items.map((tool, idx) => (
                <Box key={idx} mt="xxs" mr="xs">
                  <ToolLink {...tool} />
                </Box>
              ))}
            </HStack>
          </Box>
        ))}
      </VStack>
    </>
  );
};

export default Tools;
