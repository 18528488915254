import React from "react";
import { Switch, Route } from "react-router-dom";
import { Flex, Box } from "@sqymagma/elements";

import {
  Spotify,
  Header,
  Keyboard,
  MobileNavBar,
  DesktopNavBar,
  Skins,
} from "@components";

import { routes } from "./routes";
import * as S from "./style";

const Desktop = () => {
  return (
    <>
      <Keyboard />
      <S.Reward id="reward" />

      <Flex flexDirection={{ default: "column-reverse", l: "row" }}>
        <Skins />

        <S.Container
          width={{ default: "100%", l: "calc(100vw - 247px)" }}
          pt={{ default: "xs", s: "m", l: "s" }}
          pb={{ default: "xxs", s: "xs", l: "s" }}
          pl={{ default: "xs", s: "m", l: 0 }}
          pr={{ default: "xs", s: "m", l: "xl" }}
        >
          <Flex flexDirection="column-reverse">
            <Box>
              <Switch>
                {routes.map((route, index) => (
                  <Route path={route.path} key={index}>
                    {route.component}
                  </Route>
                ))}
              </Switch>
            </Box>
            <Header />
          </Flex>
          <Spotify />
        </S.Container>

        <MobileNavBar />
        <DesktopNavBar />
      </Flex>
    </>
  );
};

export default Desktop;
