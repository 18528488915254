import React from "react";
import { Box, Text, VStack } from "@sqymagma/elements";

import { useApp } from "@contexts";
import { Avatar } from "@elements";
import { CoffeeParticipant } from "@types";
import { addPersonPhoto } from "@helpers";

import * as S from "./style";

const AllCoffees = () => {
  const {
    coffees,
    people,
    config: { openVideoPopup },
  } = useApp();
  const coffeeMatches = coffees?.matchs;

  const maxParticipantsPerMatch =
    coffeeMatches &&
    Math.max(...coffeeMatches.map((match) => match.participants.length));

  return (
    <Box px="s" py="xs">
      <Box>
        {coffeeMatches?.map((coffee, idx) => {
          const participants = coffee.participants.map((participant) =>
            addPersonPhoto(participant, people)
          );
          const openUrl = () =>
            window.open(
              coffee.chatroom,
              "_blank",
              openVideoPopup ? "popup" : undefined
            );
          return (
            <S.CoffeeParticipants
              onClick={openUrl}
              key={idx}
              number={JSON.stringify(idx + 1)}
              columns={maxParticipantsPerMatch}
            >
              {participants.map((participant, idx) => (
                <Participant participant={participant} key={idx} />
              ))}
            </S.CoffeeParticipants>
          );
        })}
      </Box>
    </Box>
  );
};

const Participant = (props: {
  participant: CoffeeParticipant & { photo?: string };
}) => {
  const { participant } = props;
  const { photo, displayName, firstName, lastName } = participant;
  const fullName = `${firstName} ${lastName}`;

  return (
    <VStack alignItems="center" gap="xxxs">
      <Avatar image={photo} name={fullName} tooltip={fullName} />
      <Text textStyle="tiny" color="text01" textAlign="center" caps>
        {displayName}
      </Text>
    </VStack>
  );
};

export { AllCoffees, Participant };
