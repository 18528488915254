import React, { useCallback } from "react";
import HeadwayWidget from "@headwayapp/react-widget";

import { Icon } from "@elements";

import config from "./config.json";
import * as S from "./style";

const Changelog = () => {
  const Widget = useCallback(
    () => (
      <S.Wrapper>
        <HeadwayWidget options={config} badgePosition="top-right">
          <S.IconWrapper>
            <Icon name="bell" size="small" color="interactive01" />
          </S.IconWrapper>
        </HeadwayWidget>
      </S.Wrapper>
    ),
    []
  );

  return <Widget />;
};

export default Changelog;
