const successMsg = [
  {
    text: [
      "¡Gracias por compartir! 🤗 No dudes en buscar un hombro en el que apoyarte.",
      "¡Gracias por compartir! 🤗 Nos gustaría tener la fórmula para mejorar tu estado de ánimo.",
      "¡Gracias por compartir! 🤗 Si necesitas que te escuchemos, ya sabes que puedes contar con Ana y con cualquier otro compi en quien confíes.",
      "¡Gracias por compartir! 🤗 Estamos aquí para ayudarte.",
      "¡Gracias por compartir! 🤗 Aunque no demos con la solución siempre podemos tomarnos un café.",
    ],
    color: "#FFCCE1",
  },
  {
    text: [
      "¡Gracias por compartir! 🤗 Entendemos que todos los días no pueden ser siempre buenos.",
      "¡Gracias por compartir! 🤗 A veces nos ayuda a levantar el ánimo el pensar en algo por lo que nos sentimos agradecidos, y así pierde fuerza lo que nos preocupa.",
      "¡Gracias por compartir! 🤗 Los días complicados también terminan.",
      "¡Gracias por compartir! 🤗 A veces el trabajo no es como esperamos, y otras veces hay algo en nuestra vida personal que nos hace sentir tristes. Te escuchamos si lo necesitas.",
      "¡Gracias por compartir! 🤗 A veces le damos demasiadas vueltas a las cosas y realmente no son tan así como creemos.",
    ],
    color: "#FFDDCC",
  },
  {
    text: [
      "¡Gracias por compartir! 🤔 Uhmmm ¿qué podríamos hacer para mejorar este estado…?",
      "¡Gracias por compartir! 🤔 Hay días que no sabemos ni cómo nos sentimos, nos falta un poco de sal en la vida… pero esos días también pasan.",
      "¡Gracias por compartir! 🤔 Todos tenemos días así… nos falta chispa o motivación y a veces no sabemos ni la razón.",
      "¡Gracias por compartir! 🤔 ¿Hoy es de esos días que te sientes “ni fú ni fá”? Ánimo con el día… Así nos sentimos todos en algún momento sin saber muy bien por qué…",
      "¡Gracias por compartir! 🤔 No hace falta siempre pensar en el finde o las próximas vacaciones jeje, pero a veces ayuda a motivarnos en días en los que nos sentimos así…",
    ],
    color: "#FEF6CD",
  },
  {
    text: [
      "¡Gracias por compartir! 🙂 Con un poco de jamón ibérico y un trozo de chocolate lo tienes chupao para pasar al siguiente nivel!",
      "¡Gracias por compartir! 🙂 ¿Qué has desayunado hoy? Por si te copio y me contagio jeje.",
      "¡Gracias por compartir! 🙂 ¿Ya has cogido el vuelo para tus próximas vacaciones?",
      "¡Gracias por compartir! 🙂 ¡Igual esa sonrisa esconde un planazo!",
      "¡Gracias por compartir! 🙂 Tu respuesta vale más que un emoji de unicornio.",
    ],
    color: "#D4F2F7",
  },
  {
    text: [
      "¡Gracias por compartir! 😃 Tú sí que sabes hacer que los días brillen. Hazlo que dure.",
      "¡Gracias por compartir! 😃 ¡Disfruta de este maravilloso día y contagia tu felicidad con todos los que estén cerca de tí!",
      "¡Gracias por compartir! 😃 ¡Hoy seguro que puedes con todo! Nos alegra muchísimo que te sientas así y ¡ojalá dure la racha!",
      "¡Gracias por compartir! 😃 ¡Qué guay! ¡Modo sonrisa activado! No sólo el chocolate nos hace tan felices, ¿verdad?",
      "¡Gracias por compartir! 😃 ¡Tu glándula pituitaria está hoy en plena forma! ¡Y no sabes lo que nos alegramos por tí!",
    ],
    color: "#CCFAE9",
  },
];

const privateSuccess =
  "Gracias por compartir 🤗 Tu mensaje está en buenas manos, no se hará público.";

export { successMsg, privateSuccess };
