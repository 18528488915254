import React, { useState } from "react";
import { VStack, Text, Flex, Columns, Column, Box } from "@sqymagma/elements";

import { useToast, useApp } from "@contexts";
import { yourlsApi } from "@services";
import { TextField, Button, IconLink } from "@elements";
import { isReqOk } from "@helpers";

const UrlShortener = () => {
  const { addShortUrl } = useApp();
  const { addToast } = useToast();
  const [url, setUrl] = useState("");
  const [keyword, setKeyword] = useState("");
  const [shortUrl, setShortUrl] = useState(null);

  const resetForm = () => {
    setUrl("");
    setKeyword("");
  };

  const shortenUrl = async () => {
    if (!url.length) return;

    const params = { url, keyword: keyword.toLowerCase() };
    const response = await yourlsApi.yourls(params);

    if (!response) {
      addToast("Error al intentar acortar la URL");
      return;
    }

    const { statusCode, message, status } = response;

    if (isReqOk(statusCode) && status !== "fail") {
      const {
        shorturl,
        url: { url: longUrl },
      } = response;
      const cleanShortUrl = shorturl.replace(/https:\/\//g, "");
      setShortUrl(cleanShortUrl);
      addShortUrl(longUrl);
      resetForm();
    } else {
      addToast(message);
    }
  };

  const copyUrl = () => {
    if (shortUrl) {
      navigator.clipboard.writeText(shortUrl);
      addToast("URL copiada en el portapapeles");
    }
  };

  const clearShortUrl = () => setShortUrl(null);

  const buttonProps = {
    disabled: !url.length && !shortUrl,
    action: shortUrl ? copyUrl : shortenUrl,
    children: shortUrl ? "Copiar" : "Acortar",
  };

  return (
    <VStack gap="xxs">
      <Flex minHeight={30} alignItems="center">
        {!shortUrl && (
          <Columns
            hs="xxs"
            alignItems="flex-end"
            width="100%"
            overflow="visible"
          >
            <Column width="60%">
              <TextField
                value={url}
                onChange={setUrl}
                onEnter={shortenUrl}
                placeholder="Pega, acorta y comparte"
              />
            </Column>
            <Column width="40%">
              <TextField
                value={keyword}
                onChange={setKeyword}
                onEnter={shortenUrl}
                placeholder="custom-url"
                note="No uses mayúsculas ni símbolos, excepto el guión (-) para separar palabras"
                prefix="sqy.es/"
                small
              />
            </Column>
          </Columns>
        )}
        {shortUrl && (
          <Box width="100%">
            <Flex justifyContent="space-between">
              <Box>
                <Text textStyle="body" color="text01" weight="bold">
                  Esta es tu url:{" "}
                </Text>
                <Text textStyle="body" color="text01">
                  {shortUrl}
                </Text>
              </Box>
              <IconLink
                action={clearShortUrl}
                icon="close"
                tooltip="Borrar"
                small
                inverted
              />
            </Flex>
          </Box>
        )}
      </Flex>
      <Flex justifyContent="flex-end" mt="xxs">
        <Button {...buttonProps} rightIcon="arrowRight2" animateHover />
      </Flex>
    </VStack>
  );
};

export default UrlShortener;
