import * as React from "react";
import { VStack, Text } from "@sqymagma/elements";
import { format } from "date-fns";

import { IconLink } from "@elements";
import { Holiday, Event } from "@types";
import { useOutOfBounds } from "@hooks";

import HolidayComponent from "../Holiday";
import EventComponent from "../Event";

import * as S from "./style";

const es = require("date-fns/locale/es").default;

const MoreEvents = (props: Props) => {
  const { events, date, close, holiday } = props;

  const moreEventsRef = React.useRef() as React.MutableRefObject<HTMLElement>;
  const eventsBounds = useOutOfBounds(moreEventsRef);

  const dayOfWeek = format(date, "eee", { locale: es });
  const day = format(date, "d");

  return (
    <S.MoreEvents
      ref={moreEventsRef}
      $height={eventsBounds.height}
      $fixOutOfBounds={eventsBounds.fixOutOfBounds}
    >
      <S.CloseMoreEvents>
        <IconLink icon="close" action={close} small tooltip="Cerrar" />
      </S.CloseMoreEvents>
      <VStack textAlign="center" mb="xxs">
        <Text textStyle="subtitle03">{dayOfWeek.toUpperCase()}</Text>
        <Text textStyle="display03" weight="bold">
          {day}
        </Text>
      </VStack>
      {!!holiday && <HolidayComponent holiday={holiday} />}
      {events.map((event, index) => (
        <EventComponent key={index} event={event} />
      ))}
    </S.MoreEvents>
  );
};

interface Props {
  events: Event[];
  date: Date;
  holiday: Holiday | undefined;
  close: () => void;
}

export default MoreEvents;
