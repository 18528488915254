import React, { useEffect, useState } from "react";
import { Text, Box, Flex } from "@sqymagma/elements";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";
import camels1 from "@gifs/camels1.gif";
import camels2 from "@gifs/camels2.gif";
import { GifCamels1, GifCamels2 } from "./style";

import moment from "moment";

require("moment-precise-range-plugin");

export type UserSteps = {
  date: string;
  steps: string;
};

export type CamelsProps = {
  totalYearSteps?: number;
  topSteps?: Array<UserSteps>;
};

const Camels = ({
  totalYearSteps,
  topSteps,
  active,
}: CamelsProps & { active: boolean }) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "Camels");
  const durations =
    totalYearSteps && totalYearSteps > 0
      ? config?.children
      : config?.childrenNoData;

  const [activeItem, setActiveItem] = useState(0);

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="#1404C7"
      $deg1="rgb(94, 255, 68, 0.80)"
      $deg2="rgba(255, 18, 18, 0.80)"
      $rotate="38.768deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {totalYearSteps && totalYearSteps > 0 ? (
        <>
          {/* 1 */}
          {activeItem === 0 && (
            <div>
              <Text
                color="#F9B59F"
                width="100%"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "56px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.6px",
                }}
              >
                Caminante no hay camino, se hace el camino al andar...
              </Text>
            </div>
          )}

          {/* 2 */}
          {activeItem === 1 && (
            <Flex flexDirection="column">
              <Text
                color="#fff"
                width="100%"
                style={{
                  fontFamily: "'Noto Serif', serif",
                  fontSize: "30px",
                  fontWeight: "400",
                  lineHeight: "51px",
                  letterSpacing: "0.6px",
                }}
              >
                Este año has hecho
              </Text>
              <Text
                color="#F9B59F"
                width="100%"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "69px",
                  wordBreak: "break-word",
                }}
              >
                {Number(totalYearSteps).toLocaleString("es-ES")}
              </Text>
              <Text
                color="#fff"
                width="100%"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "60px",
                  fontWeight: "400",
                }}
              >
                pasos
              </Text>
              <Box mt="70px">
                {topSteps &&
                  topSteps.map((top, i) => {
                    return (
                      <Flex key={i}>
                        <Flex pt={i !== 0 && "8px"}>
                          <Text
                            color="#fff"
                            style={{
                              fontFamily: "'Noto Serif', serif",
                              fontSize: "30px",
                              fontWeight: "600",
                              lineHeight: "normal",
                              letterSpacing: "0.6px",
                            }}
                            width="54px"
                          >
                            {`0${i + 1}`}
                          </Text>
                        </Flex>

                        <Flex
                          flexDirection="column"
                          pb="16px"
                          pt={i !== 0 && "8px"}
                          width="100%"
                          style={{
                            borderBottom: "1px solid #fff",
                          }}
                        >
                          <Text
                            color="#F9B59F"
                            style={{
                              fontFamily: "'Abril Fatface', serif",
                              fontSize: "30px",
                              fontWeight: "400",
                              lineHeight: "normal",
                              letterSpacing: "0.6px",
                            }}
                          >
                            {Number(top.steps).toLocaleString("es-ES")}
                          </Text>
                          <Text
                            color="#fff"
                            style={{
                              fontFamily: "'Noto Serif', serif",
                              fontSize: "20px",
                              fontWeight: "600",
                              lineHeight: "normal",
                              letterSpacing: "0.6px",
                            }}
                          >
                            {moment(top.date, "DD/MM/YYYY")
                              .subtract(7, "d")
                              .format("DD/MM/YYYY") +
                              " - " +
                              moment(top.date, "DD/MM/YYYY")
                                .subtract(1, "d")
                                .format("DD/MM/YYYY")}
                          </Text>
                        </Flex>
                      </Flex>
                    );
                  })}
              </Box>
              <GifCamels1 $image={camels1} />
            </Flex>
          )}
        </>
      ) : (
        <>
          {activeItem === 0 && (
            <Flex flexDirection="column">
              {/* 1 no steps */}
              <Text
                color="#F9B59F"
                width="100%"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "44px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.6px",
                }}
              >
                Este año no has participado en la carrera de Kamellos...
              </Text>
              <Text
                color="#F9B59F"
                width="100%"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "44px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.6px",
                }}
                mt="40px"
              >
                ¿Te animas el año que viene?
              </Text>
              {active && <GifCamels2 $image={camels2} />}
            </Flex>
          )}
        </>
      )}
    </StepWrapper>
  );
};

export default Camels;
