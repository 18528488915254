import React from "react";
import { Text, Flex, Box } from "@sqymagma/elements";
import { format, isToday, isYesterday, isValid } from "date-fns";

import { useApp } from "@contexts";
import { extractEmailAndSender } from "@helpers";
import { Avatar } from "@elements";
import { Email } from "@types";

import * as S from "./style";

const es = require("date-fns/locale/es").default;

const maxEmailText = 80;

const Message = (props: Props) => {
  const { email } = props;
  const { id, from, date, subject, snippet, unread } = email;
  const { people, markReadEmail } = useApp();

  const isLongSubject = subject.length > maxEmailText;
  const subjectText = `${subject.slice(0, maxEmailText)}${
    !isLongSubject ? ": " : "..."
  }`;
  let snippetText = !isLongSubject
    ? `${snippet?.slice(0, maxEmailText - subject.length)}`
    : "";
  const isLongText = subjectText.length + snippetText.length >= maxEmailText;
  if (isLongText && !isLongSubject) {
    snippetText = snippetText.trim() + "...";
  }

  const { emailAddress, emailSender } = extractEmailAndSender(from);
  const personInfo = people?.find((person) => person.email === emailAddress);

  let when = "";
  const _date = new Date(date);
  if (isValid(_date)) {
    const day = format(_date, "d' 'LLL", { locale: es }).toLowerCase();
    const time = format(_date, "H':'mm");
    when = isToday(_date) ? time : isYesterday(_date) ? "ayer" : day;
  }

  const handleOpenEmail = () => {
    if (id) {
      window.open(`https://mail.google.com/mail#all/${id}`, "_blank");
      if (unread) markReadEmail(id);
    }
  };

  return (
    <S.MessageWrapper onClick={handleOpenEmail}>
      <Avatar image={personInfo?.photo} name={emailSender} />
      <S.Message>
        <Flex justifyContent="space-between">
          <Text textStyle="headingxs" color={unread ? "ui01" : "text01"}>
            {emailSender}
            {unread && <S.Unread />}
          </Text>
          <Text
            textStyle="subtitle03"
            color="text01"
            ml="xxs"
            style={{ whiteSpace: "nowrap" }}
          >
            {when}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text textStyle="bodyInline" color="text01" style={{ flexGrow: 2 }}>
            <strong>{!!subject.length && subjectText}</strong>
            {snippetText}
          </Text>
          <Box width="23px" height="23px" ml="xs">
            <S.Arrow width="23px" height="23px" />
          </Box>
        </Flex>
      </S.Message>
    </S.MessageWrapper>
  );
};

interface Props {
  email: Email;
}

export default Message;
