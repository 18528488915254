import styled from "styled-components";
import { theme } from "@sqymagma/theme";

import { calendarColors } from "@config";
import { ReactComponent as TimeMarkJointSvg } from "@images/time-mark-joint.svg";
import { CalendarName } from "@types";

const EventDetails = styled.div`
  flex-grow: 2;
  cursor: pointer;
  color: ${(p) => p.theme.colors.text01};

  &:hover > * {
    color: ${(p) => p.theme.colors.ui01};
  }
`;

const EventActionsWrapper = styled.div`
  min-width: 30px;
  display: flex;
  justify-content: flex-end;
`;

const CalendarMark = styled.div<{
  isPending: boolean;
  calendarName: CalendarName;
}>`
  background-color: ${(p) =>
    p.isPending ? "transparent" : calendarColors[p.calendarName]};
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border-color: ${(p) => calendarColors[p.calendarName]};
  border-width: ${(p) => (p.isPending ? "1px" : "0px")};
  border-style: solid;
`;

const TimeMarkWrapper = styled.div<{ time: string; last: boolean }>`
  position: relative;
  height: 2px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.secondaryBackground};
  margin-bottom: ${(p) => (p.last ? 0 : p.theme.spacing.xs)};
  margin-top: ${(p) => (p.last ? p.theme.spacing.xs : 0)};
  border-radius: 1px;

  &:after {
    content: "${(p) => p.time}";
    ${theme("textStyle.tiny")};
    background-color: ${theme("colors.secondaryBackground")};
    color: ${theme("colors.text02")};
    padding: 3px 0 2px 3px;
    position: absolute;
    top: 1px;
    left: 0;
    transform: translate(-100%, -50%);
    width: 30px;
    border-radius: 3px 0 0 3px;
    text-align: center;
  }
`;

const TimeMarkJoint = styled(TimeMarkJointSvg)`
  position: absolute;
  width: 12px;
  height: 19px;
  top: 1px;
  left: -0.5px;
  transform: translate(0, -50%);
  fill: ${(p) => p.theme.colors.secondaryBackground};
`;

export {
  EventDetails,
  EventActionsWrapper,
  CalendarMark,
  TimeMarkWrapper,
  TimeMarkJoint,
};
