import React from "react";
import { VStack, Text, Flex } from "@sqymagma/elements";

import { getUserDataFromEmail } from "@helpers";
import { useApp } from "@contexts";
import { Avatar, Icon, Tooltip } from "@elements";
import { Animation } from "@components";

import People from "./People";
import { getEmailsWithMoreClaps } from "./utils";
import * as S from "./style";

const Ranking = (props: {
  lastMonthRanking: {
    senders: Record<string, number>;
    receivers: Record<string, number>;
  };
}) => {
  const { lastMonthRanking } = props;

  const { people } = useApp();

  const receivers = Object.entries(lastMonthRanking.receivers);
  const senders = Object.entries(lastMonthRanking.senders);

  const receiversWinners = getEmailsWithMoreClaps(receivers);
  const sendersWinners = getEmailsWithMoreClaps(senders);

  const singleReceiverWinner = receiversWinners.length === 1;
  const singleSenderWinner = sendersWinners.length === 1;

  const Person = ({ email }: { email: string }) => {
    const user = getUserDataFromEmail(email, people);
    return (
      <Tooltip content={user?.name}>
        <S.PersonWrapper>
          <S.Wreath>
            <Icon name="wreath" width={94} />
          </S.Wreath>
          <Avatar
            image={user?.photo}
            name={user?.name}
            size="xl"
            border="gold"
          />
        </S.PersonWrapper>
      </Tooltip>
    );
  };

  return (
    <>
      <S.AnimationWrapper>
        <S.Animation>
          <Animation name="confetti" width="320px" height="300px" />
        </S.Animation>
      </S.AnimationWrapper>

      <VStack gap="xs">
        <Text textStyle="subtitle01" color="text01" weight="bold">
          El mes pasado
        </Text>

        <Flex>
          <VStack gap="xs" alignItems="center" width="50%" px="xs">
            {singleReceiverWinner ? (
              <Person email={receiversWinners[0][0]} />
            ) : (
              <People emails={receiversWinners} people={people} />
            )}
            <Text textStyle="bodyInline" color="text01" textAlign="center">
              {singleReceiverWinner ? "recibió" : "recibieron"} más aplausos
            </Text>
          </VStack>

          <VStack gap="xs" alignItems="center" width="50%" px="xs">
            {singleSenderWinner ? (
              <Person email={sendersWinners[0][0]} />
            ) : (
              <People emails={sendersWinners} people={people} />
            )}
            <Text textStyle="bodyInline" color="text01" textAlign="center">
              {singleSenderWinner ? "envió" : "enviaron"} más aplausos
            </Text>
          </VStack>
        </Flex>
      </VStack>
    </>
  );
};

export default Ranking;
