import React, { useEffect, useState } from "react";
import { Text, Box } from "@sqymagma/elements";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";

import thanks from "@gifs/thanks.gif";
import people1 from "@images/people1.png";
import people2 from "@images/people2.png";
import people3 from "@images/people3.png";
import people4 from "@images/people4.png";
import { GifEnd } from "./style";

interface FarewellProps {
  active: boolean;
  year: number;
}

const Farewell = ({ active, year }: FarewellProps) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "Farewell");
  const durations = config?.children;

  const [activeItem, setActiveItem] = useState(0);

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="#FF4C96"
      $deg1="#6F7BF7"
      $deg2="#BA73D9"
      $rotate="109.222deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            color="#fff"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "25px",
              lineHeight: "56px",
              letterSpacing: "0.88px",
            }}
          >
            Ha sido un
          </Text>

          {activeItem === 0 && (
            <Text
              color="#fff"
              width="70%"
              mt="32px"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "168px",
                lineHeight: "156px",
                wordBreak: "break-word",
                opacity: 0,
                animationName: "fadeIn",
                animationDuration: "200ms",
                animationDelay: "1s",
                animationFillMode: "forwards",
                textAlign: "center",
              }}
            >
              {year}
            </Text>
          )}
        </div>
      )}

      {/* 2 */}
      {activeItem === 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Text
            color="#fff"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "27px",
              lineHeight: "56px",
              letterSpacing: "0.88px",
            }}
          >
            para
          </Text>

          {activeItem === 1 && (
            <Text
              color="#fff"
              width="154px"
              display="block"
              mb="40px"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "112px",
                lineHeight: "97px",
              }}
            >
              co no cer se
            </Text>
          )}
          <Box
            style={{
              position: "absolute",
              bottom: "80px",
              right: 0,
              zIndex: -1,
            }}
          >
            <img src={people1} alt={"people"} width="316px" />
          </Box>
        </div>
      )}

      {/* 3 */}
      {activeItem === 2 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50px",
              right: "-15px",
              zIndex: -1,
            }}
          >
            <img src={people2} alt={"people"} width="419px" />
          </Box>
          <Text
            color="#fff"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "27px",
              lineHeight: "56px",
              letterSpacing: "0.88px",
            }}
          >
            para
          </Text>

          <Text
            color="#fff"
            width="154px"
            display="block"
            mb="40px"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "112px",
              lineHeight: "97px",
            }}
          >
            ce le brar se
          </Text>
        </div>
      )}

      {/* 4 */}
      {activeItem === 3 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Text
            color="#fff"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "27px",
              lineHeight: "56px",
              letterSpacing: "0.88px",
              textAlign: "right",
            }}
          >
            para
          </Text>

          <Text
            color="#fff"
            width="185px"
            mb="40px"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "112px",
              lineHeight: "97px",
              textAlign: "right",
            }}
          >
            co nec tar se
          </Text>
          <Box
            style={{
              position: "absolute",
              bottom: "110px",
              left: "0px",
              zIndex: -1,
            }}
          >
            <img src={people3} alt={"people"} width="274px" />
          </Box>
        </div>
      )}

      {/* 5 */}
      {activeItem === 4 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <Text
            color="#fff"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "27px",
              lineHeight: "56px",
              letterSpacing: "0.88px",
              textAlign: "right",
            }}
          >
            y
          </Text>

          <Text
            color="#fff"
            width="185px"
            mb="40px"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "112px",
              lineHeight: "97px",
              textAlign: "left",
            }}
          >
            re for zar se
          </Text>
          <Box
            style={{
              position: "absolute",
              top: "150px",
              right: "-20px",
              zIndex: -1,
            }}
          >
            <img src={people4} alt={"people"} width="328px" />
          </Box>
        </div>
      )}

      {/* 6 */}
      {activeItem === 5 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Text
            color="#fff"
            width="100%"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "46px",
              lineHeight: "97px",
              textAlign: "center",
            }}
          >
            ¡Gracias!
          </Text>
          <GifEnd $image={thanks} />
        </div>
      )}
    </StepWrapper>
  );
};

export default Farewell;
