import styled from "styled-components";

const GifCamels1 = styled.div<{ $image: string }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  position: absolute;
  right: -90px;
  bottom: 10vh;
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  border-radius: 50%;
`;

const GifCamels2 = styled.div<{ $image: string }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 375px;
  height: 375px;
  position: absolute;
  right: -35px;
  bottom: 5vh;
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  border-radius: 50%;
`;

export { GifCamels1, GifCamels2 };
