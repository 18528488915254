import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import packageJson from "@packageJson";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: packageJson.version,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampler: (samplingContext) => {
    const hostname = samplingContext.location?.hostname;
    return hostname === "localhost" ? 0 : 1;
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
