import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const CalendarHeader = styled.div`
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.xxs};
  color: ${theme("interactive02")};
  ${theme("textStyle.subtitle01")};
  font-weight: 700;
`;

export { CalendarHeader };
