import styled, { css } from "styled-components";
import { Box, Text } from "@sqymagma/elements";
import { theme } from "@sqymagma/theme";
import { darkenHexColor } from "@helpers";

const MiniWidgetBox = styled(Box)`
  border-radius: ${(p) => p.theme.radii.l};
  box-shadow: none;

  &:hover {
    background-color: ${(p) => darkenHexColor(p.bg, 0.1)};
  }

  @media (min-width: ${theme("breakpoints.s")}) {
    box-shadow: ${(p) => p.theme.shadows.small};
  }
`;

const Greeting = styled(Text)<{ $rewardType: string | null }>`
  color: ${theme("colors.text01")};
  ${theme("textStyle.display02")};
  cursor: ${(p) => (p.$rewardType ? "pointer" : "default")};
  display: inline-block;

  &::after {
    ${(p) =>
      p.$rewardType &&
      css`
        content: "🎉";
        display: inline-block;
        margin-left: ${(p) => p.theme.spacing.xxs};
      `}
  }

  &:hover:after {
    animation: shake 1s linear;
  }
`;

export { MiniWidgetBox, Greeting };
