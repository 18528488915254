import React, { RefObject, useEffect, useState } from "react";
import { Text, Flex } from "@sqymagma/elements";
import { AnimatePresence } from "framer-motion";
import { XMasonry, XBlock } from "react-xmasonry";
import { io } from "socket.io-client";
import { useLocation } from "react-router-dom";

import { MoodState } from "@types";
import { Button } from "@elements";

import MoodCard from "./MoodCard";
import * as S from "./style";

interface TimelineProps {
  moods?: Array<MoodState>;
  resetMoods: () => void;
  getStats: () => void;
  timelineRef: RefObject<HTMLDivElement>;
  updateMoodData: (moodUpdated: MoodState) => void;
}

const Timeline = (props: TimelineProps) => {
  const { moods, resetMoods, getStats, timelineRef, updateMoodData } = props;

  const location = useLocation();
  const state = location.state as { openMood?: MoodState | null };
  const openMood = state?.openMood;

  const [newMoods, setNewMoods] = useState(0);

  // WebSocket to notify new messages
  useEffect(() => {
    function onNewMood() {
      setNewMoods((newMoods) => newMoods + 1);
    }
    const socket = io(process.env.REACT_APP_REMOTE_API_URL as string);
    socket.on("new mood", onNewMood);
    return () => {
      socket.off("new mood", onNewMood);
      socket.disconnect();
    };
  }, []);

  const reloadMoods = () => {
    timelineRef.current?.scrollIntoView({ behavior: "smooth" });
    resetMoods();
    getStats();
    setNewMoods(0);
  };

  const notMoodsYet = moods?.length === 0;

  return (
    <AnimatePresence>
      {openMood && (
        // Hidden MoodCard just to show mood comments from notification
        <MoodCard
          key={openMood.id}
          {...openMood}
          reloadMoods={reloadMoods}
          updateMoodData={updateMoodData}
          fromNotification
        />
      )}
      {!moods ? null : (
        <S.Timeline>
          {!!newMoods && (
            <S.NewMoods initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Button
                appearance="secondary"
                action={reloadMoods}
                leftIcon="sync"
                rounded
                shadow
              >
                {`Hay ${newMoods} ${
                  newMoods > 1 ? "mensajes nuevos" : "mensaje nuevo"
                }`}
              </Button>
            </S.NewMoods>
          )}

          {notMoodsYet && (
            <Flex justifyContent="center" pb="s">
              <Text textStyle="body" color="text03" textAlign="center">
                No hay mensajes que mostrar
              </Text>
            </Flex>
          )}

          <XMasonry responsive targetBlockWidth={450} center={false}>
            {moods.map((mood, index) => (
              <XBlock key={index}>
                <MoodCard
                  reloadMoods={reloadMoods}
                  updateMoodData={updateMoodData}
                  {...mood}
                />
              </XBlock>
            ))}
          </XMasonry>
        </S.Timeline>
      )}
    </AnimatePresence>
  );
};

export default Timeline;
