import React, { createContext } from "react";

import { Loading } from "@components";

import { AuthState } from "./reducer";
import { useAuthProvider } from "./hooks";

interface ProviderProps {
  children: JSX.Element;
}

interface ProviderValue extends AuthState {
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  refreshToken: () => Promise<void>;
}

export const AuthContext = createContext({} as ProviderValue);

export const AuthProvider = (props: ProviderProps) => {
  const { children } = props;
  const auth = useAuthProvider();

  return (
    <AuthContext.Provider value={auth}>
      {auth.isLoading ? <Loading fadeIn /> : children}
    </AuthContext.Provider>
  );
};
