import { remoteApi } from "@services";
import { Timesheet } from "@types";

const getTimesheet = async (start: Date, end: Date) => {
  const timesheets: Array<Timesheet> = await remoteApi.getTimesheets(
    start,
    end
  );

  const projects: { [key: string]: number } = {};
  const events: { [key: string]: number } = {};
  let training = 0;

  const skip = ["Vacation", "Jornada Intensiva"];

  timesheets?.forEach((t) => {
    if (skip.includes(t.projectName)) {
      return;
    }

    if (t.projectName === "Formación") {
      training += t.minutes;
    } else if (t.projectType === "EVENT") {
      if (events[t.projectName]) {
        events[t.projectName] += t.minutes;
      } else {
        events[t.projectName] = t.minutes;
      }
    } else if (t.projectType === "REGULAR") {
      if (projects[t.projectName]) {
        projects[t.projectName] += t.minutes;
      } else {
        projects[t.projectName] = t.minutes;
      }
    }
  });

  return { training: Math.round(training / 60), projects, events };
};

const formatProjects = (projects: { [key: string]: number }) => {
  const projectArr = Object.keys(projects).map((name) => ({
    name,
    time: projects[name],
  }));
  projectArr.sort((a, b) => b.time - a.time);

  const total = projectArr.reduce((prev, current) => prev + current.time, 0);
  const numberFormatter = new Intl.NumberFormat("es-ES", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return projectArr.map((p) => {
    const percentage = numberFormatter.format((p.time * 100) / total);
    return {
      ...p,
      hours: Math.round(p.time / 60),
      percentage:
        percentage.length === 3 && percentage[0] !== "0"
          ? `0${percentage}`
          : percentage,
    };
  });
};

export { getTimesheet, formatProjects };
