import styled, { css } from "styled-components";
import { Box } from "@sqymagma/elements";
import { theme } from "@sqymagma/theme";

const Event = styled(Box)`
  white-space: nowrap;
  background-color: ${(p) => p.color};
  color: ${(p) => p.theme.colors.light};
  border-radius: ${(p) => p.theme.radii.s};
  padding-left: ${(p) => p.theme.spacing.xxs};
  padding-right: ${(p) => p.theme.spacing.xxs};
  margin-top: ${(p) => p.theme.spacing.xxxs};
  overflow: hidden;
  position: relative;
  cursor: ${(p) => (p.onClick ? "pointer" : "default")};

  &::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(p) => p.color};
    mix-blend-mode: ${(p) => (p.$pending ? "normal" : "multiply")};
  }

  .event-data {
    line-height: 20px;

    & > span {
      font-weight: 700;
    }
  }

  ${(p) =>
    p.$pending &&
    css`
      background-color: ${theme("colors.primaryBackground")}88;
      color: ${p.color};
      border: 1px dashed ${p.color};
      .event-data {
        line-height: 18px;
      }
    `}
`;

export { Event };
