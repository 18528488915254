import React, { MutableRefObject, useEffect, useRef } from "react";
import { VStack, Flex, Box } from "@sqymagma/elements";
import { useViewportSpy } from "beautiful-react-hooks";

import { useApp } from "@contexts";
import { Icon, IconLink, Loader } from "@elements";
import { WidgetBox } from "@components";

import Message from "./Message";

const emailsPerLoad = 5;
const maxEmailsLoaded = 100;

const openGmail = () => window.open("https://mail.google.com/mail", "_blank");

const Emails = () => {
  const { emails, getEmails, numberOfEmails } = useApp();
  const emailsRef = useRef();
  const loadMoreEmails = useViewportSpy(
    emailsRef as unknown as MutableRefObject<HTMLElement>
  );
  const showLoader = emails.length < maxEmailsLoaded;

  useEffect(() => {
    if (!!emails.length && loadMoreEmails) {
      const emailsToLoad = numberOfEmails + emailsPerLoad;
      getEmails(emailsToLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreEmails]);

  const handleRefreshEmails = () => {
    getEmails(numberOfEmails);
  };

  return (
    <WidgetBox height="270px" overflowY="scroll">
      <Flex justifyContent="space-between">
        <Icon name="gmail" onClick={openGmail} width={45} />
        <IconLink icon="sync" action={handleRefreshEmails} rotateOnClick />
      </Flex>
      <VStack gap="s" pt="m">
        {emails.map((email, idx) => (
          <Message email={email} key={idx} />
        ))}
      </VStack>
      {showLoader && (
        <Box ref={emailsRef} pt="l" pb="xs">
          <Flex alignItems="center" justifyContent="center">
            <Loader small={!!emails.length} />
          </Flex>
        </Box>
      )}
    </WidgetBox>
  );
};

export default Emails;
