import styled, { keyframes } from "styled-components";
import Tilt from "react-parallax-tilt";
import { motion } from "framer-motion";
import { Box } from "@sqymagma/elements";

import logo from "@icons/logo-transparent.svg";

const moveGradient = keyframes`
  0%{background-position:4% 0%}
  50%{background-position:97% 100%}
  100%{background-position:4% 0%}
`;

const TitleWrapper = styled.div`
  width: 320px;
  padding: 25px 0 35px;
  margin: 0 auto;
  transition: 1s;
  position: absolute;

  @media (max-width: 1215px) {
    display: none;
  }

  @media (min-width: 1215px) {
    width: 600px;
  }
`;

const Video = styled.video`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors.ui02};
`;

const AccessWrapper = styled(motion.div)<{ width: string }>`
  width: ${(p) => p.width};
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(30px);
  box-shadow: -3px 0 25px black;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
`;

const AccessContainer = styled.div`
  width: 375px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionContainer = styled.section`
  width: 100%;
  margin-top: 130px;
  color: ${(p) => p.theme.colors.light};

  @media (min-width: 1215px) {
    margin-top: 150px;
  }
`;

const TiltWrapper = styled(Tilt)`
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  pointer-events: none;
`;

const HeroTextName = styled.div`
  font-family: "Moderna", sans-serif;
  font-size: 170px;
  line-height: 1.6em;
  font-weight: 400;

  @media (min-width: 1450px) {
    font-size: 200px;
    line-height: 1.3em;
  }
`;

const HeroTextSlogan = styled.h1`
  font-family: "Moderna", sans-serif;
  font-size: 50px;
  line-height: 62px;
  padding-left: 8px;
  letter-spacing: 1px;
  width: 100%;
  pointer-events: none;
  background-color: ${(p) => p.theme.colors.light};
  background: linear-gradient(
    57deg,
    rgb(255, 167, 69),
    rgb(254, 134, 159),
    rgb(239, 122, 200),
    rgb(160, 131, 237),
    rgb(67, 174, 255)
  );
  background-size: 200% 200%;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${moveGradient} 20s ease infinite;
`;

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: fixed;
  padding: 25px 35px;
`;

const Logo = styled.a`
  display: block;
  width: 52px;
  height: 52px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1215px) {
    width: 32px;
    height: 32px;
  }
`;

const GoogleBtn = styled.button`
  transition:
    background-color ${(p) => p.theme.timing.hover},
    box-shadow ${(p) => p.theme.timing.hover};

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: bold;
  font-family: ${(p) => p.theme.fontFamily.primary};

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 2px ${(p) => p.theme.colors.ui01};
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 10px 2px ${(p) => p.theme.colors.ui01};
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, 0.04),
      0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
`;

const AppName = styled(Box)`
  font-family: "Moderna", sans-serif;
  font-size: 64px;
  line-height: 100%;
  font-weight: 400;
  color: ${(p) => p.theme.colors.light};
`;

export {
  TitleWrapper,
  Video,
  AccessWrapper,
  AccessContainer,
  SectionContainer,
  TiltWrapper,
  HeroTextName,
  HeroTextSlogan,
  HeaderContainer,
  Logo,
  GoogleBtn,
  AppName,
};
