import styled from "styled-components";
import { theme } from "@sqymagma/theme";
import { Flex } from "@sqymagma/elements";

import { ReactComponent as VirtualCoffeeSvg } from "@images/virtual-coffee.svg";

const VirtualCoffee = styled(VirtualCoffeeSvg)`
  fill: ${theme("colors.text01")};
`;

const ButtonsWrapper = styled(Flex)`
  justify-content: flex-end;
  padding-top: ${(p) => p.theme.spacing.xs};

  & > * {
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

const CoffeeParticipants = styled.div<{
  columns?: number;
  number: string;
}>`
  display: grid;
  grid-template-columns: ${(p) => `repeat(${p.columns}, 1fr)`};
  grid-gap: ${(p) => p.theme.spacing.s};
  padding: ${(p) => p.theme.spacing.xxs};
  padding-left: ${(p) => p.theme.spacing.l};
  margin: ${(p) => p.theme.spacing.xxs} 0;
  background-color: ${(p) => p.theme.colors.quaternaryBackground};
  border-radius: ${(p) => p.theme.radii.s};
  cursor: pointer;
  position: relative;

  &:after {
    content: ${(p) => JSON.stringify(p.number)};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${theme("textStyle.display03")};
    color: ${(p) => p.theme.colors.text01};
    width: ${(p) => p.theme.spacing.l};
    display: flex;
    justify-content: center;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.ui01};
  }
`;

export { VirtualCoffee, ButtonsWrapper, CoffeeParticipants };
