import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";

const CellActions = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Row = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 1fr;

  &:hover > ${CellActions} {
    display: block;
  }
`;

const Cell = styled.div<{ clickable?: boolean }>`
  ${theme("textStyle.body")};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.xxs};
  font-weight: bold;
  word-break: break-all;

  ${(p) =>
    p.clickable &&
    css`
      cursor: pointer;
      &:hover {
        color: ${p.theme.colors.textOnInteractive01Hover};
      }
    `}

  & span {
    ${theme("textStyle.bodyInline")};
    display: block;
    font-weight: normal;
    line-height: 1.2em;
    margin-top: ${(p) => p.theme.spacing.xxxs};
  }
`;

export { Row, Cell, CellActions };
