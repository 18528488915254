import React from "react";
import { theme } from "@sqymagma/theme";
import { format } from "date-fns";

import { MiniWidget } from "@elements";
import { ReactComponent as WeLogSvg } from "@images/welog.svg";

const es = require("date-fns/locale/es").default;

const WeLog = () => {
  const weekNumber = format(new Date(), "w", {
    locale: es,
    firstWeekContainsDate: 7,
  });

  const handleAction = () => {
    window.open(
      "https://www.figma.com/design/ZYE5WyWBE7TQ2vLgElK4lE/SQY---Welog?node-id=0-1&t=VZQtWaOBsTvWK0Rq-1",
      "_blank"
    );
  };

  return (
    <MiniWidget
      title="WeLog"
      description={`Semana ${weekNumber}`}
      color={theme("colors.light")}
      icon="clipboard"
      action={handleAction}
      image={WeLogSvg}
    />
  );
};

export default WeLog;
