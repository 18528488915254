import styled, { css } from "styled-components";
import { Box } from "@sqymagma/elements";
import { theme } from "@sqymagma/theme";

const WidgetBox = styled(Box)<{
  hideOverflow?: boolean;
  $shadow?: "normal" | "tiny" | "none";
}>`
  position: relative;
  border-radius: ${(p) => p.theme.radii.l};
  padding: ${(p) => p.theme.spacing.s};
  box-shadow: ${(p) => (p.$shadow === "tiny" ? p.theme.shadows.tiny : "none")};

  &:hover {
    cursor: ${(p) => (p.cursor ? p.cursor : "default")};
  }

  ${(p) =>
    !p.bg &&
    css`
      background-color: ${(p) => p.theme.colors.quinaryBackground};
    `}

  ${(p) =>
    p.hideOverflow &&
    css`
      overflow: hidden;
    `}

  @media (min-width: ${theme("breakpoints.s")}) {
    box-shadow: ${(p) =>
      p.$shadow === "none"
        ? "none"
        : p.$shadow === "tiny"
        ? p.theme.shadows.tiny
        : p.theme.shadows.small};

    ${(p) =>
      !p.bg &&
      css`
        background-color: ${(p) => p.theme.colors.tertiaryBackground};
      `}
  }
`;

export default WidgetBox;
