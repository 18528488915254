import { User } from "@types";

export type AuthState = {
  user: User | null;
  isLoading: boolean;
};

export const initialState = {
  user: null,
  isLoading: true,
};

export const reducer = (state: AuthState, payload: Partial<AuthState>) => ({
  ...state,
  ...payload,
});
