import React, { useEffect, useState } from "react";
import { Flex, Text, VStack } from "@sqymagma/elements";

import projects1 from "@gifs/proyectos1.gif";
import projects2 from "@gifs/proyectos2.gif";
import projects3 from "@gifs/proyectos3.gif";

import { WRAPPED_STEPS } from "../constants";
import { StepWrapper } from "../style";
import { formatProjects, getTimesheet } from "./utils";
import {
  Circle,
  EventCircle,
  GifEnd,
  GifHubPlanner,
  GifTraining,
  Marquee,
} from "./style";

type HubPlannerProps = {
  active: boolean;
  wrappedStart: Date;
  wrappedEnd: Date;
};

type Project = {
  percentage: string;
  name: string;
  time: number;
  hours: number;
};

const lastText = ["cu", "-", "rra", "-", "zo", " "];

const HubPlanner = ({ active, wrappedStart, wrappedEnd }: HubPlannerProps) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "HubPlanner");
  const durations = config?.children;

  const [activeItem, setActiveItem] = useState(0);
  const [training, setTraining] = useState(0);
  const [projects, setProjects] = useState<Array<Project>>();
  const [events, setEvents] = useState<Array<Project>>();

  const getData = async () => {
    const { training, projects, events } = await getTimesheet(
      wrappedStart,
      wrappedEnd
    );

    setTraining(training);
    setProjects(formatProjects(projects));
    setEvents(formatProjects(events));
  };

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StepWrapper
      $bg="#AF61F3"
      $deg1="rgba(150, 160, 212, 0.60)"
      $deg2="rgba(224, 102, 49, 0.60)"
      $rotate="47.351deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <div style={{ overflowY: "hidden", height: "100%" }}>
          <Text
            color="#FCB"
            display="block"
            width="70%"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "36px",
              fontWeight: 500,
              lineHeight: "40px",
              letterSpacing: "0.72px",
            }}
          >
            Has dejado huella en
          </Text>
          <Text
            color="#FFFBE9"
            display="block"
            align="right"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "190px",
              letterSpacing: "4px",
            }}
          >
            {(projects?.length || 0) < 10
              ? `0${projects?.length || ""}`
              : projects?.length || 0}
          </Text>
          <Text
            color="#FCC2AF"
            display="block"
            align="right"
            width="60%"
            ml="auto"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "112px",
              lineHeight: "102px",
              letterSpacing: "2.24px",
              wordBreak: "break-word",
            }}
          >
            pro yec tos
          </Text>
        </div>
      )}

      {/* 2 */}
      {activeItem === 1 && (
        <div>
          <Text
            color="#FCB"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "36px",
              fontWeight: 500,
              lineHeight: "40px",
              letterSpacing: "0.72px",
            }}
          >
            Y también en
          </Text>
          <Text
            color="#FFFBE9"
            display="block"
            align="right"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "200px",
              letterSpacing: "4px",
            }}
          >
            {(events?.length || 0) < 10
              ? `0${events?.length || ""}`
              : events?.length || 0}
          </Text>
          <Text
            color="#FCC2AF"
            display="block"
            align="right"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "92px",
              lineHeight: "85px",
              letterSpacing: "1.84px",
            }}
          >
            eventos
          </Text>
          <Text
            color="#FCC2AF"
            display="block"
            align="right"
            width="70%"
            ml="auto"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "92px",
              lineHeight: "85px",
              letterSpacing: "1.84px",
              wordBreak: "break-word",
            }}
          >
            internos
          </Text>
        </div>
      )}

      {/* 3 */}
      {activeItem === 2 && (
        <div style={{ paddingTop: "62px" }}>
          <Text
            color="#FCB"
            display="block"
            align="right"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "144px",
              lineHeight: "156px",
              letterSpacing: "4px",
            }}
          >
            {(projects?.length || 0) < 10
              ? `0${projects?.length || ""}`
              : projects?.length || 0}
          </Text>
          <Text
            color="#FFF"
            display="block"
            mt="-65px"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "60px",
              lineHeight: "78px",
            }}
          >
            Proyectos
          </Text>

          <VStack gap="24px">
            {projects?.map(({ name, percentage }, index) => (
              <Flex
                key={index}
                justifyContent="space-between"
                alignItems="flex-end"
                pb="24px"
                style={{ borderBottom: "1px solid #fff" }}
              >
                <div style={{ paddingRight: "8px" }}>
                  <Text
                    color="#fff"
                    style={{
                      fontFamily: "'Noto Serif', serif",
                      fontSize: "17px",
                      fontWeight: 700,
                      letterSpacing: "0.34px",
                    }}
                  >
                    {name}
                  </Text>
                  <Text
                    color="#FCB"
                    display="block"
                    style={{
                      fontFamily: "'Abril Fatface', serif",
                      fontSize: "56px",
                      letterSpacing: "1.12px",
                    }}
                  >
                    {percentage}%
                  </Text>
                </div>
                <div>
                  <Circle
                    $index={index}
                    $percentage={percentage.replace(",", ".")}
                  />
                </div>
              </Flex>
            ))}
          </VStack>

          {activeItem === 2 && <GifHubPlanner $image={projects1} />}
        </div>
      )}

      {/* 4 */}
      {activeItem === 3 && (
        <div style={{ paddingTop: "62px" }}>
          <Text
            color="#FCB"
            display="block"
            align="right"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "144px",
              lineHeight: "156px",
              letterSpacing: "4px",
            }}
          >
            {(events?.length || 0) < 10
              ? `0${events?.length || ""}`
              : events?.length || 0}
          </Text>
          <Text
            color="#FFF"
            display="block"
            mt="-115px"
            style={{
              fontFamily: "'Abril Fatface', serif",
              fontSize: "60px",
              lineHeight: "62px",
            }}
          >
            Eventos internos
          </Text>

          <div
            style={{
              marginTop: "45px",
              display: "grid",
              gap: "8px",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {events?.map(({ name, hours }, index) => (
              <EventCircle key={index} $index={index}>
                <Text
                  color="#8A58B5"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "48px",
                    lineHeight: "62px",
                    letterSpacing: "0.96px",
                  }}
                >
                  {hours}h
                </Text>
                <Text
                  color="#5F3186"
                  width="80%"
                  align="center"
                  style={{
                    fontFamily: "'Noto Serif', serif",
                    fontSize: "16px",
                    fontWeight: 700,
                    letterSpacing: "0.32px",
                  }}
                >
                  {name}
                </Text>
              </EventCircle>
            ))}
          </div>
        </div>
      )}

      {/* 5 */}
      {activeItem === 4 && (
        <Flex flexDirection="column" justifyContent="space-between">
          <div>
            <Text
              color="#FCB"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "64px",
                lineHeight: "70px",
                letterSpacing: "1.28px",
              }}
            >
              Has invertido
            </Text>
            <Text
              color="#FFF"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "126px",
                letterSpacing: "2.52px",
              }}
            >
              {training}h
            </Text>
            <Text
              color="#FCC2AF"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "64px",
                lineHeight: "70px",
                letterSpacing: "1.28px",
              }}
            >
              en tu formación
            </Text>
          </div>

          <Text
            color="#fff"
            textStyle="body"
            display="block"
            style={{
              fontSize: "18px",
              fontWeight: 300,
              lineHeight: "28px",
              letterSpacing: "-0.18px",
            }}
          >
            {training < 50 ? (
              <>
                <strong style={{ fontWeight: 800 }}>El año que viene</strong>{" "}
                habrá más oportunidades
              </>
            ) : (
              <>
                <strong style={{ fontWeight: 800 }}>¡Eres un auténtico</strong>{" "}
                <em>héroe del aprendizaje!</em>
              </>
            )}
          </Text>

          {activeItem === 4 && <GifTraining $image={projects2} />}
        </Flex>
      )}

      {/* 6 */}
      {activeItem === 5 && (
        <div
          style={{
            paddingTop: "62px",
            overflow: "hidden",
          }}
        >
          <Marquee>
            <ul>
              {lastText
                .concat(lastText, lastText, lastText)
                .map((value, index) => (
                  <li
                    key={index}
                    style={{
                      display: "block",
                      width: value.trim() ? "auto" : "50px",
                      minWidth: value.trim() ? "auto" : "50px",
                    }}
                  >
                    <Text
                      color="#FCB"
                      style={{
                        fontFamily: "'Abril Fatface', serif",
                        fontSize: "95px",
                        letterSpacing: "1.9px",
                      }}
                    >
                      {value || " "}
                    </Text>
                  </li>
                ))}
            </ul>
          </Marquee>

          {activeItem === 5 && <GifEnd $image={projects3} />}
        </div>
      )}
    </StepWrapper>
  );
};

export default HubPlanner;
