import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { MeetingLink } from "@components";
import { getMeeting } from "@helpers";
import { MeetingId } from "@data/meetings";

const Meeting = (props: {
  id: MeetingId;
  disabled?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}) => {
  const { id, ...rest } = props;
  const meeting = getMeeting(id);

  return <MeetingLink {...rest} meeting={meeting} tiny />;
};

export { Meeting };
