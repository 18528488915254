import styled from "styled-components";
import { Box } from "@sqymagma/elements";

const Container = styled(Box)`
  max-width: 1750px;
  margin: 0 auto;
  background-color: ${(p) => p.theme.colors.paper};
  min-height: 100vh;
`;

const Reward = styled.span`
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: 2;
`;

export { Container, Reward };
