import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: ${(p) => `-${p.theme.spacing.xxs}`};
`;

export const MeetingsList = styled.div``;

export const MeetingItemWrapper = styled.div``;

export const MeetingItem = styled.div<{
  animationDelay: number;
  animationDuration: number;
  isDraggable: boolean;
}>`
  animation-delay: ${(p) => `-${p.animationDelay}s`};
  animation-duration: ${(p) => `${p.animationDuration}s`};
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;
  animation-name: ${(p) => p.isDraggable && "jiggle"};
  padding-bottom: ${(p) => p.theme.spacing.xxs};

  @keyframes jiggle {
    0% {
      transform: rotate(-1deg);
      animation-timing-function: ease-in;
    }

    50% {
      transform: rotate(1.5deg);
      animation-timing-function: ease-out;
    }
  }
`;
