import { tools as toolsSections } from "@data";
import { Tool } from "@types";

const tools = Object.keys(toolsSections)
  .map((key) => toolsSections[key])
  .reduce(
    (prev: Array<{ name?: string; items: Tool[] }>, curr) => [
      ...prev,
      ...curr.types,
    ],
    []
  )
  .reduce((prev, curr) => [...prev, ...curr.items], [] as Tool[]);

const getTool = (id: string) => {
  return tools.find((tool: Tool) => tool.id === id);
};

export { getTool };
