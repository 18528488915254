import styled from "styled-components";
import Select from "react-select";

const StyledSelect = styled(Select)`
  ${(p) => p.theme.textStyle.body}

  .react-select__control {
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid ${(p) => p.theme.colors.line02};
    box-shadow: none;
    transition:
      background-color 0.5s,
      box-shadow 0.5s,
      border-color 0.5s;

    &:hover {
      border-color: ${(p) => p.theme.colors.brand01};
    }

    .react-select__single-value {
      color: ${(p) => p.theme.colors.text01};
    }

    .react-select__indicator-separator {
      background-color: ${(p) => p.theme.colors.line01};
    }

    .react-select__dropdown-indicator,
    .react-select__clear-indicator {
      color: ${(p) => p.theme.colors.interactive03};
      cursor: pointer;

      &:hover {
        color: ${(p) => p.theme.colors.interactive03Hover};
      }
    }
  }

  .react-select__control--is-focused {
    border-color: ${(p) => p.theme.colors.line02};

    &:hover {
      border-color: ${(p) => p.theme.colors.line02};
    }
  }

  .react-select__menu {
    background-color: ${(p) => p.theme.colors.tertiaryBackground};

    .react-select__option {
      &:hover,
      &:focus,
      &:active {
        background-color: ${(p) => p.theme.colors.interactive04Hover};
        cursor: pointer;
      }
    }

    .react-select__option--is-focused {
      background-color: ${(p) => p.theme.colors.interactive04Focus};
    }

    .react-select__option--is-selected {
      background-color: ${(p) => p.theme.colors.brand01};

      &:hover {
        background-color: ${(p) => p.theme.colors.brand01};
      }
    }
  }
`;

export { StyledSelect };
