import styled from "styled-components";
import { Box, HStack } from "@sqymagma/elements";

export const EditorBox = styled(Box)`
  border-top: 1px solid #d3d3d3;
`;

export const Content = styled(Box)`
  overflow: auto;
  max-height: calc(100vh - 150px);
`;

export const Replays = styled(HStack)`
  svg {
    path,
    circle {
      fill: ${(p) => p.theme.colors.text01};
    }
  }
`;
