import { handleRequest } from "@helpers";
import { getHeaders } from "./helpers";

const remoteApiUrl = process.env.REACT_APP_REMOTE_API_URL;

// get the access token from the backend
const requestAccessToken = async (code: string) => {
  if (code) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("code", code);
    const params = {
      url: `${remoteApiUrl}/google/token`,
      options: {
        ...getHeaders(),
        method: "POST",
        body: urlencoded,
      },
      errorMsg: "Error while requesting access token.",
    };
    return handleRequest(params);
  }
};

// get the refresh token from the backend
const refreshToken = async (token: string) => {
  if (token) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("token", token);
    const params = {
      url: `${remoteApiUrl}/google/refresh-token`,
      options: {
        ...getHeaders(),
        method: "POST",
        body: urlencoded,
      },
      errorMsg: "Error while getting refresh token.",
    };
    return handleRequest(params);
  }
};

export { requestAccessToken, refreshToken };
