import styled from "styled-components";

const Badge = styled.div`
  min-width: 16px;
  padding-inline: 2px;
  height: 16px;
  border-radius: 8px;
  font-size: 11px;
  font-family: ${(p) => p.theme.fontFamily.primary};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  background-color: ${(p) => p.theme.colors.brand01};
  color: ${(p) => p.theme.colors.light};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Badge;
