import React from "react";
import { format, parse, add } from "date-fns";
import { Text, Box } from "@sqymagma/elements";

import { useApp, useToast } from "@contexts";
import { IconLink } from "@elements";
import { ShortUrl } from "@types";
import { Modal } from "@components";
import { useModal } from "@hooks";

import * as S from "./style";

const es = require("date-fns/locale/es").default;

const Row = (props: Props) => {
  const {
    data: { shorturl, title, url, timestamp, clicks },
  } = props;

  const { removeShortUrl } = useApp();
  const { addToast } = useToast();
  const { isOpen, toggleModal } = useModal(false);

  const cleanShortUrl = shorturl.replace(/https:\/\//g, "");
  const keyword = shorturl.replace(/https:\/\/sqy\.es\//g, "");
  const parsedTime = parse(timestamp, "y-MM-dd HH:mm:ss", new Date());
  const timezoneOffset = new Date().getTimezoneOffset();
  const fixedUtcTime = add(parsedTime, { minutes: Math.abs(timezoneOffset) });
  const date = format(fixedUtcTime, "LLLL d, yyyy", { locale: es });
  const hour = format(fixedUtcTime, "HH:mm", { locale: es });

  const copyUrl = () => {
    navigator.clipboard.writeText(cleanShortUrl);
    addToast("Url copiada en el portapapeles");
  };

  const handleRemoveUrl = () => {
    toggleModal();
    removeShortUrl(url, keyword);
  };

  return (
    <>
      <S.Row>
        <S.Cell onClick={copyUrl} clickable>
          {cleanShortUrl}
        </S.Cell>
        <S.Cell>
          {title}
          <span>{url}</span>
        </S.Cell>
        <S.Cell>
          {date}
          <span>{hour}</span>
        </S.Cell>
        <S.Cell>{clicks}</S.Cell>
        <S.CellActions>
          <IconLink
            icon="close"
            tooltip="Eliminar"
            small
            inverted
            action={toggleModal}
          />
        </S.CellActions>
      </S.Row>

      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        size="S"
        title="Eliminar Url"
        mainAction={{ title: "Eliminar", action: handleRemoveUrl }}
        secondaryAction={{ title: "Cancelar", action: toggleModal }}
      >
        <Box py="s" px="xs">
          <Text textStyle="body" color="text01">
            ¿Seguro que quieres eliminar <strong>{title}</strong>?
          </Text>
        </Box>
      </Modal>
    </>
  );
};

interface Props {
  data: ShortUrl;
}

export default Row;
