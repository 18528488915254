import React from "react";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "@helpers";
import { Reward, User } from "@types";

const es = require("date-fns/locale/es").default;

const getToday = () => {
  const date = new Date();
  const dayOfWeek = format(date, "EEEE", { locale: es });
  const month = format(date, "MMMM", { locale: es });
  const day = format(date, "d", { locale: es });
  const today = `${capitalizeFirstLetter(
    dayOfWeek
  )}, ${day} de ${month.toLowerCase()}`;
  return today;
};

const getGreeting = (reward: Reward, user: User | null) => {
  switch (reward.type) {
    case "secuoyasBirthday":
      return (
        <>
          ¡Feliz cumpleaños, <strong>Secuoyas</strong>!
        </>
      );
    case "birthday":
      return (
        <>
          ¡Feliz cumpleaños, <strong>{user?.givenName}</strong>!
        </>
      );
    case "hiringAnniversary": {
      const years = reward.payload as number;
      return (
        <>
          ¡Enhorabuena por {years > 1 ? "tus" : "tu"} {years > 1 ? years : ""}{" "}
          {years > 1 ? "años" : "año"} en Secuoyas!
        </>
      );
    }
    default:
      return (
        <>
          ¡Hola de nuevo, <strong>{user?.givenName}</strong>!
        </>
      );
  }
};

export { getToday, getGreeting };
