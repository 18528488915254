import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";

const DayWrapper = styled.div<{
  isOutOfScope: boolean;
  isDayWeekend: boolean;
}>`
  visibility: ${(p) => (p.isOutOfScope ? "hidden" : "visible")};
  position: relative;
  height: 100px;
  border-right: 1px solid ${theme("colors.line01")};
  border-bottom: 1px solid ${theme("colors.line01")};
  text-align: left;
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.xxxs};
  background-color: ${(p) =>
    p.isDayWeekend ? `${p.theme.colors.secondaryBackground}20` : "transparent"};
`;

const DayNumber = styled.div<{ isToday: boolean }>`
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: initial;
  color: ${(p) => (p.isToday ? p.theme.colors.light : p.theme.colors.text01)};
  ${theme("textStyle.subtitle03")};
  font-weight: 700;
  cursor: default;

  ${(p) =>
    p.isToday &&
    css`
      background-color: ${(p) => p.theme.colors.ui05};
    `}
`;

const EventsSummary = styled.span`
  ${theme("textStyle.tiny")};
`;

const MoreEventsButton = styled.div`
  ${theme("textStyle.tiny")};
  margin-top: ${(p) => p.theme.spacing.xxxs};
  padding-left: ${(p) => p.theme.spacing.xxs};
  cursor: pointer;
  border-radius: ${(p) => p.theme.radii.s};
  transition: background-color ${(p) => p.theme.timing.hover} ease-in;
  position: relative;

  &:hover {
    background-color: ${(p) => p.theme.colors.quaternaryBackground};
  }
`;

export { DayWrapper, DayNumber, EventsSummary, MoreEventsButton };
