import styled, { css } from "styled-components";

export const ToolBoxWrapper = styled.div``;

export const ToolBox = styled.div<{ $tiny?: boolean }>`
  border-radius: ${(p) => p.theme.radii.m};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  cursor: pointer;
  transition: all ${(p) => p.theme.timing.s};
  display: inline-block;
  align-content: center;
  border: 1px solid ${(p) => p.theme.colors.darkgray};
  color: ${(p) => p.theme.colors.text01};
  background-color: ${(p) => p.theme.colors.primaryBackground};

  & svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.quinaryBackground};
  }

  ${(p) =>
    p.$tiny &&
    css`
      zoom: 1.8;
      padding: ${(p) => p.theme.spacing.xxs};
      border-radius: ${(p) => p.theme.radii.s};
      background-color: ${(p) => p.theme.colors.secondaryBackground};
      border: none;

      &:hover {
        transform: scale(1.05);
        background-color: ${(p) => p.theme.colors.secondaryBackground};
      }
    `}
`;
