import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const ToastsWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${theme("spacing.xxs")};
  width: 300px;
`;

export { ToastsWrapper };
