import React, { memo, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";

import { Button, IconLink } from "@elements";

import * as S from "./style";

interface Props {
  isOpen: boolean;
  hide: () => void;
  children: JSX.Element;
  title: string;
  size?: "S" | "M" | "L";
  mainAction?: { title: string; action: () => void };
  secondaryAction?: { title: string; action: () => void };
  height?: string;
  width?: string;
  allowOverflow?: boolean;
  isChild?: boolean;
  lightHeader?: boolean;
}

const Modal = (props: Props) => {
  const {
    isOpen,
    hide,
    children,
    title,
    size,
    mainAction,
    secondaryAction,
    height,
    width,
    allowOverflow,
    isChild,
    lightHeader,
  } = props;

  const handleClose = useCallback(() => {
    hide();
  }, [hide]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") handleClose();
    };
    if (isOpen) {
      document.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleClose, isOpen]);

  const titleContent = title ? <S.Title>{title}</S.Title> : null;

  const mainActionButton = mainAction ? (
    <Button appearance="secondary" action={mainAction.action}>
      {mainAction.title}
    </Button>
  ) : null;

  const secondaryActionButton = secondaryAction ? (
    <Button appearance="primary" action={secondaryAction.action}>
      {secondaryAction.title}
    </Button>
  ) : null;

  const footer =
    mainAction || secondaryAction ? (
      <S.ModalFooter>
        {secondaryActionButton}
        {mainActionButton}
      </S.ModalFooter>
    ) : null;

  return isOpen
    ? createPortal(
        <>
          <S.LockBody lock />
          <S.ModalOverlay isChild={isChild} />
          <S.ModalWrapper className="modal-container" isChild={isChild}>
            <S.Modal size={size} height={height} width={width}>
              <S.ModalHeader lightHeader={lightHeader}>
                {titleContent}
                <S.ButtonWrapper>
                  <IconLink icon="close" action={handleClose} small inverted />
                </S.ButtonWrapper>
              </S.ModalHeader>
              <S.ModalContent allowOverflow={allowOverflow}>
                {children}
              </S.ModalContent>
              {footer}
            </S.Modal>
          </S.ModalWrapper>
        </>,
        document.body
      )
    : null;
};

export default memo(Modal);
