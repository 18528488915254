import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { Meeting } from "@types";
import { useApp } from "@contexts";
import firebase from "@database";

import MeetingBox from "./MeetingBox";

const MeetingLink = (props: Props) => {
  const {
    meeting: { externalUrl, id },
    disabled,
  } = props;

  const {
    config: { openVideoPopup },
  } = useApp();

  const handleOnClick = () => {
    window.open(externalUrl, "_blank", openVideoPopup ? "popup" : undefined);
    firebase.logEvent("meeting_click", { meeting_room: id });
  };

  if (disabled) return <MeetingBox {...props} />;

  return (
    <div
      onClick={handleOnClick}
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
    >
      <MeetingBox {...props} />
    </div>
  );
};

interface Props {
  meeting: Meeting;
  tiny?: boolean;
  disabled?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

export default MeetingLink;
