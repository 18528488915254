import React from "react";

import * as S from "./style";

const Toggle = (props: Props) => {
  const { name, value, disabled, size, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (onChange) {
      onChange(isChecked);
    }
  };

  return (
    <S.Wrapper size={size}>
      <S.Input
        id={`toggle-${name}`}
        type="checkbox"
        name={`toggle-${name}`}
        checked={value}
        disabled={disabled}
        onChange={handleChange}
      />
      <S.Label htmlFor={`toggle-${name}`} />
    </S.Wrapper>
  );
};

interface Props {
  name: string;
  value?: boolean;
  disabled?: boolean;
  size?: "s" | "m";
  onChange?: (value: boolean) => void;
}

export default Toggle;
