import { fromUnixTime, getMonth } from "date-fns";
import { Applause } from "@types";

const isOtherMonth = (
  created: { _seconds: number },
  index: number,
  applauses: Array<Applause>
) => {
  return index === 0
    ? fromUnixTime(created._seconds).getMonth() !== getMonth(new Date()) // if is the first applause but from a past month
    : fromUnixTime(created._seconds).getMonth() !==
        fromUnixTime(applauses[index - 1].created._seconds).getMonth(); // if the month is different than the previous applause
};

const getEncodedMessage = (message: string, from = "") => {
  const messageHtml = document.createElement("html");
  messageHtml.innerHTML = message;
  const messageEncoded = encodeURI(
    messageHtml.textContent || messageHtml.innerText
  );
  return [
    encodeURI(`\n\n- - - - - - - - - -\n${from} escribió: \n`),
    encodeURI("\n- - - - - - - - - -\n\n"),
  ].join(messageEncoded);
};

export { isOtherMonth, getEncodedMessage };
