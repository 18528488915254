import * as React from "react";
import { VStack, Text } from "@sqymagma/elements";

import { WidgetBox, UrlShortener } from "@components";

const UrlShortenerWidget = () => {
  return (
    <WidgetBox>
      <VStack gap="xxs">
        <Text textStyle="subtitle01" color="text01" weight="bold">
          Acortador de Url
        </Text>
        <UrlShortener />
      </VStack>
    </WidgetBox>
  );
};

export default UrlShortenerWidget;
