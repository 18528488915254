import React from "react";
import { Icon, Tooltip } from "@elements";
import { IconNames } from "@elements/Icon";
import { getInitials, stringToColor } from "@helpers";
import { THEMES } from "@constants";
import { useStyle } from "@contexts";

import * as S from "./style";

const sizes = {
  xs: "24px",
  s: "30px",
  m: "50px",
  l: "66px",
  xl: "80px",
};

const statusIcons: Record<string, { name: IconNames; color: string } | null> = {
  accepted: { name: "checkmark", color: "green" },
  declined: { name: "close", color: "red" },
  tentative: { name: "questionMark", color: "amber" },
  needsAction: null,
};

const Avatar = (props: Props) => {
  const {
    image,
    name,
    tooltip,
    size = "m",
    status,
    border,
    squared = false,
  } = props;

  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;
  const colorType = isDarkTheme ? [50, 50] : [70, 70]; // [saturation, lightness]
  const color = !image && name && stringToColor(name, ...colorType);
  const icon = status && statusIcons[status];

  return (
    <S.AvatarWrapper size={sizes[size]} hasStatus={!!status}>
      <Tooltip content={tooltip}>
        <S.Avatar
          image={image}
          initials={getInitials(name)}
          size={sizes[size]}
          smallInitials={["xs", "s"].includes(size)}
          $color={color}
          $border={border}
          $squared={squared}
        />
      </Tooltip>
      {!!icon && (
        <S.StatusWrapper $color={icon.color}>
          <Icon name={icon.name} />
        </S.StatusWrapper>
      )}
    </S.AvatarWrapper>
  );
};

interface Props {
  image?: string;
  name?: string;
  tooltip?: string;
  size?: keyof typeof sizes;
  status?: "accepted" | "declined" | "tentative" | "needsAction";
  border?: string;
  squared?: boolean;
}

export default Avatar;
