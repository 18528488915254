import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { StyleProvider, AuthProvider } from "@contexts";
import Routes from "@routes";
import { Error } from "@components";
import { useServiceWorker } from "@hooks";

import "./index.css";

const App = () => {
  const serviceWorker = useServiceWorker();

  return (
    <StyleProvider>
      <AuthProvider>
        <Router>
          <Sentry.ErrorBoundary fallback={<Error />}>
            <Routes serviceWorker={serviceWorker} />
          </Sentry.ErrorBoundary>
        </Router>
      </AuthProvider>
    </StyleProvider>
  );
};

export default App;
