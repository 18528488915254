import React, { useEffect, useState } from "react";
import { Flex, Text, VStack } from "@sqymagma/elements";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";

import claps1 from "@gifs/aplausos1.gif";
import claps2 from "@gifs/aplausos2.gif";
import { GifClaps1, GifClaps2 } from "./style";

type ClapsListType = {
  email: string;
  name?: string;
  claps: number;
};

export type ClapsProps = {
  received?: number;
  receivedList?: Array<ClapsListType>;
  sent?: number;
  sentList?: Array<ClapsListType>;
};

type ClapsListProps = {
  list: Array<ClapsListType>;
};

const ClapsList = ({ list }: ClapsListProps) => {
  return (
    <VStack gap="xxs" width="90%">
      {list.map(({ email, name, claps }, index) => (
        <Flex key={email}>
          <Text
            color="#fff"
            width="54px"
            style={{
              fontFamily: "'Noto Serif', serif",
              fontSize: "30px",
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "0.6px",
            }}
          >
            0{index + 1}
          </Text>
          <div style={{ flexGrow: 1 }}>
            <VStack
              gap="3px"
              pb="16px"
              style={{
                borderBottom:
                  index < list.length - 1 ? "1px solid #fff" : "none",
              }}
            >
              <Text
                color="#E0F65A"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "30px",
                  lineHeight: "normal",
                  letterSpacing: "0.6px",
                }}
              >
                {name || email}
              </Text>
              <Text
                color="#fff"
                style={{
                  fontFamily: "'Noto Serif', serif",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  letterSpacing: "0.4px",
                }}
              >
                {claps} aplausos
              </Text>
            </VStack>
          </div>
        </Flex>
      ))}
    </VStack>
  );
};

const Claps = ({
  active,
  received,
  receivedList,
  sent,
  sentList,
}: ClapsProps & { active: boolean }) => {
  const config = WRAPPED_STEPS.find(({ step }) => step === "Claps");
  const durations = received ? config?.children : config?.childrenNoData;

  const [activeItem, setActiveItem] = useState(0);

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="#09CDAA"
      $deg1="rgba(207, 175, 215, 0.80)"
      $deg2="rgba(150, 68, 255, 0.80)"
      $rotate="56.676deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {(received || 0) > 0 ? (
        <>
          {/* 1 */}
          {activeItem === 0 && (
            <div>
              <Text
                color="#E0F65A"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "56px",
                  lineHeight: "50px",
                  letterSpacing: "0.84px",
                }}
              >
                Porque a todos nos gusta que nos den nuestro pin, aquí van tus
                aplausos de este año
              </Text>
            </div>
          )}

          {/* 2 */}
          {activeItem === 1 && (
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              style={{ height: "100%", overflowY: "hidden" }}
            >
              <div>
                <Text
                  color="#E0F65A"
                  display="block"
                  position="relative"
                  mb="-50px"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "144px",
                    lineHeight: "156px",
                    letterSpacing: "2.88px",
                  }}
                >
                  {received}
                </Text>
                <Text
                  color="#fff"
                  align="center"
                  display="block"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "52px",
                    lineHeight: "54px",
                  }}
                >
                  aplausos recibidos
                </Text>
              </div>

              {receivedList?.length ? <ClapsList list={receivedList} /> : null}

              {activeItem === 1 && <GifClaps1 $image={claps1} />}
            </Flex>
          )}

          {/* 3 */}
          {activeItem === 2 && (
            <Flex flexDirection="column" justifyContent="space-between">
              <div>
                <Text
                  color="#fff"
                  align="center"
                  display="block"
                  position="relative"
                  mb="-45px"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "52px",
                    lineHeight: "54px",
                  }}
                >
                  aplausos enviados
                </Text>
                <Text
                  color="#E0F65A"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "144px",
                    lineHeight: "156px",
                    letterSpacing: "2.88px",
                  }}
                >
                  {sent}
                </Text>
              </div>

              {sentList?.length ? <ClapsList list={sentList} /> : null}

              {activeItem === 2 && <GifClaps2 $image={claps2} />}
            </Flex>
          )}
        </>
      ) : (
        <>
          {/* 1 no claps */}
          {activeItem === 0 && (
            <div>
              <Text
                color="#fff"
                align="right"
                display="block"
                position="relative"
                mb="-45px"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "52px",
                  lineHeight: "54px",
                }}
              >
                Por todo lo que has hecho este año
              </Text>
              <Text
                color="#E0F65A"
                display="block"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "90px",
                  lineHeight: "86px",
                  letterSpacing: "1.8px",
                }}
              >
                Te
              </Text>
              <Text
                color="#E0F65A"
                display="block"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "90px",
                  lineHeight: "86px",
                  letterSpacing: "1.8px",
                }}
              >
                mere
              </Text>
              <Text
                color="#E0F65A"
                display="block"
                style={{
                  fontFamily: "'Abril Fatface', serif",
                  fontSize: "90px",
                  lineHeight: "86px",
                  letterSpacing: "1.8px",
                }}
              >
                ces un aplauso
              </Text>
            </div>
          )}

          {/* 2 no claps */}
          {activeItem === 1 && (
            <div>
              {activeItem === 1 &&
                new Array(3).fill("Clap").map((value, index) => (
                  <Text
                    key={index}
                    color="#E0F65A"
                    align="center"
                    display="block"
                    style={{
                      fontFamily: "'Abril Fatface', serif",
                      fontSize: "139px",
                      lineHeight: "176px",
                      letterSpacing: "2.78px",
                      opacity: index === 0 ? 1 : 0,
                      animationName: "fadeIn",
                      animationDuration: "200ms",
                      animationDelay: `${index * 0.5}s`,
                      animationFillMode: "forwards",
                    }}
                  >
                    {value}
                  </Text>
                ))}

              {activeItem === 1 && <GifClaps2 $image={claps2} $bottom />}
            </div>
          )}
        </>
      )}
    </StepWrapper>
  );
};

export default Claps;
