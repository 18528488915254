import { isBefore, isAfter, isToday } from "date-fns";

import { areSameDay } from "@helpers";
import { Event } from "@types";

const getEventPosition = (
  event: Event,
  idx: number,
  allEvents: Event[],
  selectedDay: Date
) => {
  if (!isToday(selectedDay))
    return { isNextEvent: false, isLastFinishedEvent: false };
  const now = new Date();
  const previousEvents = allEvents.slice(0, idx);
  const isPreviousEventsFinished = previousEvents.every((event) => {
    return (
      idx === 0 ||
      !event.end.dateTime ||
      isBefore(new Date(event.end.dateTime), now) ||
      (!areSameDay(new Date(event.end.dateTime), now) &&
        isAfter(now, new Date(event.start.dateTime))) // treats started events that end on another day as finished for today
    );
  });
  const isEventFinished = isAfter(now, new Date(event.end.dateTime));
  const isEventFinishingToday = areSameDay(new Date(event.end.dateTime), now);
  const isThisEventStarted = isAfter(now, new Date(event.start.dateTime));
  const isMoreThanOneDayEventStarted =
    !isEventFinishingToday && isThisEventStarted;
  const isEventStartingToday = isToday(new Date(event.start.dateTime));
  const isEventEndingToday = isToday(new Date(event.end.dateTime));
  const isNextEvent =
    (isEventStartingToday || isEventEndingToday) &&
    isPreviousEventsFinished &&
    !isEventFinished &&
    !isMoreThanOneDayEventStarted;
  const isLastEvent = allEvents.length === idx + 1;
  const isLastFinishedEvent =
    isLastEvent &&
    isPreviousEventsFinished &&
    (isEventFinished || isMoreThanOneDayEventStarted) &&
    (isEventStartingToday || isEventEndingToday);

  return { isNextEvent, isLastFinishedEvent };
};

export { getEventPosition };
