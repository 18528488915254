import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Hubbles from "./Hubbles";
import Taxonomy from "./Taxonomy";

const Library = () => {
  return (
    <Switch>
      <Route exact path="/library/hubbles">
        <Hubbles />
      </Route>
      <Route exact path="/library/taxonomy">
        <Taxonomy />
      </Route>
      <Route exact path="/library">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Library;
