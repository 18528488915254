import React, { useRef, useState } from "react";
import { format, getMonth, isWeekend } from "date-fns";
import { useMediaQuery } from "beautiful-react-hooks";

import { Event } from "@types";
import { useApp, useStyle } from "@contexts";
import { areSameDay } from "@helpers";
import { useHandleClickOutside } from "@hooks";

import EventComponent from "./Event";
import Holiday from "./Holiday";
import MoreEvents from "./MoreEvents";

import * as S from "./style";

const Day = (props: Props) => {
  const { date, events, today, currentDate } = props;
  const { holidays } = useApp();
  const {
    globalTheme: { breakpoints },
  } = useStyle();
  const [isMoreEventsOpen, setIsMoreEventsOpen] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);

  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.l})`);

  const isDayWeekend = isWeekend(date);
  const isToday = areSameDay(date, today);
  const isOutOfScope = getMonth(currentDate) !== getMonth(date);
  const holiday = holidays.find((holiday) => areSameDay(holiday.date, date));

  const slicedEvents = holiday ? 1 : 2;
  const displayedEvents = events.slice(0, slicedEvents);
  const hiddenEvents = events.slice(slicedEvents);

  const handleOpenMoreEvents = () => setIsMoreEventsOpen(true);
  const handleCloseMoreEvents = () => setIsMoreEventsOpen(false);
  const handleClickOutside = (e: MouseEvent) => {
    if (wrapper.current && wrapper.current.contains(e.target as Node)) return;
    setIsMoreEventsOpen(false);
  };
  useHandleClickOutside(isMoreEventsOpen, handleClickOutside);

  const eventsOutput =
    !isOutOfScope &&
    displayedEvents.map((event, index) => (
      <EventComponent key={index} event={event} />
    ));

  const moreEventsText = `${hiddenEvents.length}${
    isDesktop ? (hiddenEvents.length === 1 ? " evento" : " eventos") : ""
  } más`;

  return (
    <S.DayWrapper
      ref={wrapper}
      isDayWeekend={isDayWeekend}
      isOutOfScope={isOutOfScope}
    >
      <S.DayNumber isToday={isToday}>{format(date, "d")}</S.DayNumber>
      <S.EventsSummary>
        {!!holiday && <Holiday holiday={holiday} />}
        {eventsOutput}
      </S.EventsSummary>
      {!!hiddenEvents.length && (
        <S.MoreEventsButton onClick={handleOpenMoreEvents}>
          <span>{moreEventsText}</span>
          {isMoreEventsOpen && (
            <MoreEvents
              events={events}
              holiday={holiday}
              date={date}
              close={handleCloseMoreEvents}
            />
          )}
        </S.MoreEventsButton>
      )}
    </S.DayWrapper>
  );
};

interface Props {
  date: Date;
  events: Event[];
  today: Date;
  currentDate: Date;
}

export default Day;
