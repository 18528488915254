import jwtDecode from "jwt-decode";

import { IUserFromApi } from "@types";

const getUser = (idToken: string, lastUserInfo?: Partial<IUserFromApi>) => {
  const user: IUserFromApi = jwtDecode(idToken);
  const { sub, name, given_name, picture, email, exp } = {
    ...lastUserInfo,
    ...user,
  };

  return {
    id: sub,
    name,
    givenName: given_name,
    image: picture,
    email,
    idToken,
    expiration: new Date(exp * 1000),
  };
};

export { getUser };
