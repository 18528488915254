import styled from "styled-components";

const Selectors = styled.div`
  & > * {
    display: inline-block;
    margin-right: ${(p) => p.theme.spacing.xxxs};
    margin-bottom: ${(p) => p.theme.spacing.xxxs};
  }
`;

const Animation = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
`;

export { Selectors, Animation };
