import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";

const AvatarWrapper = styled.div<{ size: string; hasStatus: boolean }>`
  position: relative;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  margin-right: calc(${(p) => (p.hasStatus ? p.size : 0)} * 0.25);
`;

const Avatar = styled.div<{
  size: string;
  initials: string;
  image?: string;
  smallInitials: boolean;
  $color?: string | boolean;
  $border?: string;
  $squared: boolean;
}>`
  border-radius: ${(p) => (p.$squared ? "25%" : "50%")};
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  background-image: ${(p) =>
    p.image ? `url(${p.image})` : "url(transparent.png)"};
  background-color: ${(p) => p.$color || p.theme.colors.secondaryBackground};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ${(p) => p.theme.timing.l} ease-in-out;

  ${(p) =>
    p.$border &&
    css`
      border: 1px solid ${theme(`colors.${p.$border}`)};
    `}

  &:after {
    content: ${(p) => !p.image && JSON.stringify(p.initials)};
    color: ${(p) => p.theme.colors.darkgray};
    mix-blend-mode: multiply;
    ${(p) =>
      p.smallInitials
        ? theme("textStyle.body")
        : css`
            ${theme("textStyle.bodyLead")};
            font-weight: bold;
          `}
  }
`;

const StatusWrapper = styled.div<{ $color: string }>`
  width: 50%;
  height: 50%;
  background-color: ${(p) => `${p.theme.colors[p.$color]}66`};
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: -25%;
  border: 1px solid ${(p) => p.theme.colors.line06};
  transform-style: preserve-3d;

  & svg {
    width: 95%;
    height: 95%;
    fill: ${(p) => p.theme.colors[p.$color]};
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${(p) => p.theme.colors.light};
    border-radius: 50%;
    position: absolute;
    transform: translateZ(-1px);
  }
`;

export { AvatarWrapper, Avatar, StatusWrapper };
