import React from "react";
import { format } from "date-fns";
import { Box, Flex } from "@sqymagma/elements";

import { capitalizeFirstLetter } from "@helpers";
import { IconLink } from "@elements";

import * as S from "./style";

const es = require("date-fns/locale/es").default;

const Header = (props: Props) => {
  const { selectedDay, changeSelectedDay } = props;
  const todayDate = format(new Date(), "MMMM' 'yyyy", { locale: es });
  const weekNumber = format(selectedDay, "'semana 'w", {
    locale: es,
    firstWeekContainsDate: 7,
  });

  const handleChangeToday = () => changeSelectedDay(new Date());

  return (
    <Box pb="xs">
      <Flex justifyContent="space-between" alignItems="center">
        <S.Header onClick={handleChangeToday}>
          {capitalizeFirstLetter(weekNumber)} -{" "}
          {capitalizeFirstLetter(todayDate)}
        </S.Header>
        <IconLink icon="calendar" to="/calendar" tooltip="Calendario mensual" />
      </Flex>
    </Box>
  );
};

interface Props {
  selectedDay: Date;
  changeSelectedDay: (date: Date) => void;
}

export default Header;
