import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const tabsGap = "xs";

const OuterWrapper = styled.div`
  width: fit-content;
  margin: auto;
  border-radius: ${(p) => p.theme.radii.circle};
  border: 1px solid ${(p) => p.theme.colors.line07};
  padding: ${(p) => p.theme.spacing.xxxs};
  background-color: ${(p) => p.theme.colors.quinaryBackground};
`;

const InnerWrapper = styled.div`
  position: relative;
`;

const Tabs = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing[tabsGap]};
  width: fit-content;
`;

const Tab = styled.div<{ active: boolean }>`
  ${theme("textStyle.body")};
  color: ${(p) => (p.active ? p.theme.colors.light : p.theme.colors.text01)};
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  transition:
    color ${(p) => p.theme.timing.s} ease,
    background-color ${(p) => p.theme.timing.s} ease;
  padding: ${(p) => p.theme.spacing.xxxs} ${(p) => p.theme.spacing.xxs};
  z-index: 1;
  border-radius: ${(p) => p.theme.radii.circle};
  &:hover {
    background-color: ${(p) => !p.active && p.theme.colors.overlay03};
  }
`;

const Active = styled.div<{ position: number; size: number; index: number }>`
  display: inline-block;
  position: absolute;
  z-index: 0;
  top: 0;
  height: 100%;
  background-color: ${(p) => p.theme.colors.ui01};
  width: ${(p) => `${p.size}px`};
  left: ${(p) =>
    `calc(${p.position}px + (${p.theme.spacing[tabsGap]} * ${p.index}))`};
  transition: all ${(p) => p.theme.timing.s} ease;
  border-radius: ${(p) => p.theme.radii.circle};
`;

export { OuterWrapper, InnerWrapper, Tabs, Tab, Active };
