import styled from "styled-components";
import { Box, VStack } from "@sqymagma/elements";

export const IconWrapper = styled(Box)``;

export const PeopleWrapper = styled(Box)`
  text-align: center;
`;

export const CardContent = styled(VStack)`
  text-align: center;
`;
