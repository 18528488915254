import { Person } from "@types";

const formatClapsList = (
  claps: { [key: string]: number },
  people: Array<Person>
) => {
  const clapsList = Object.keys(claps).map((value) => ({
    email: value,
    name: people.find((p) => p.email === value)?.name,
    claps: claps[value],
  }));
  clapsList.sort((a, b) => b.claps - a.claps);

  return clapsList.slice(0, 3);
};

export { formatClapsList };
