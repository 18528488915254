import styled from "styled-components";
import { Box } from "@sqymagma/elements";

const MoreEvents = styled(Box)<{ $height: number; $fixOutOfBounds: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  max-width: 200px;
  min-height: 100%;
  margin-left: -50%;
  margin-top: -50%;
  background-color: ${(p) => p.theme.colors.tertiaryBackground};
  padding: ${(p) => p.theme.spacing.xxs};
  border-radius: ${(p) => p.theme.radii.m};
  transition: all ${(p) => p.theme.timing.xs} ease-in;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadows.medium};
  cursor: default;
  animation: zoomIn ${(p) => p.theme.timing.xs};
  transform: translateY(
    calc(
      -50% + ${(p) => `${p.$height / 2}px`} - ${(p) => `${p.$fixOutOfBounds}px`}
    )
  );
`;

const CloseMoreEvents = styled(Box)`
  position: absolute;
  right: 0;
  margin-right: ${(p) => p.theme.spacing.xxs};
`;

export { MoreEvents, CloseMoreEvents };
