import { useEffect, useRef, useState } from "react";

const useKeyboard = (triggerKeys: string[]) => {
  const [allKeysPressed, setAllKeysPressed] = useState(false);
  const [keysPressed, setKeysPressed] = useState({});
  const keysRef = useRef<Record<string, string>>(keysPressed);
  keysRef.current = keysPressed;

  const handleKeyDown = (e: KeyboardEvent) => {
    const lowerCase = e.key.toLowerCase();
    const isTriggerKey = triggerKeys.some(
      (key) => key.toLowerCase() === lowerCase
    );
    if (isTriggerKey && !keysRef.current[lowerCase]) {
      setKeysPressed((state) => ({ ...state, [lowerCase]: true }));
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    const lowerCase = e.key.toLowerCase();
    if (keysRef.current[lowerCase]) {
      const pressed = { ...keysRef.current };
      delete pressed[lowerCase];
      setKeysPressed({ ...pressed });
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      setKeysPressed({});
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allKeysPressed =
      triggerKeys.length === Object.keys(keysPressed).length;
    setAllKeysPressed(allKeysPressed);
  }, [keysPressed, triggerKeys]);

  useEffect(() => {
    allKeysPressed && setKeysPressed({});
  }, [allKeysPressed]);

  return allKeysPressed;
};

export { useKeyboard };
