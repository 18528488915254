import * as React from "react";
import { Text, HStack } from "@sqymagma/elements";

import { Person } from "@types";
import { Avatar, Icon, TextField } from "@elements";
import { useHandleClickOutside, useSearch } from "@hooks";
import { useApp, useAuth } from "@contexts";
import { sortByName } from "@helpers";

import * as S from "./style";
import { SettingsProps } from "..";

interface PeopleSelectorProps {
  settings: SettingsProps;
  setSettings: React.Dispatch<React.SetStateAction<SettingsProps>>;
}

const PeopleSelector = (props: PeopleSelectorProps) => {
  const {
    settings: { emails },
    setSettings,
  } = props;

  const { people } = useApp();
  const { user } = useAuth();

  const [openSelector, setOpenSelector] = React.useState(false);
  const selectorRef = React.useRef<HTMLDivElement>(null);

  const peopleSorted = people.sort(sortByName);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchedPeople] = useSearch(peopleSorted, {
    searchQuery,
    searchFields: ["name"],
  });

  // remove logged user and sort by name
  const availablePeople = searchedPeople.filter(
    (person) => person.email !== user?.email
  );

  // filter callbacks
  const isEmailIncluded = (person: Person) =>
    person.email && emails.includes(person.email);
  const isNotEmailIncluded = (person: Person) =>
    person.email && !emails.includes(person.email);

  const selectedPeople = availablePeople.filter(isEmailIncluded);
  const unselectedPeople = availablePeople.filter(isNotEmailIncluded);

  const visibleSelectedPeople = peopleSorted
    .filter(isEmailIncluded)
    .slice(0, 3);
  const moreSelectedPeople =
    peopleSorted.filter(isEmailIncluded).length - visibleSelectedPeople.length;

  const handleClickOutside = (e: MouseEvent) => {
    if (selectorRef.current && selectorRef.current.contains(e.target as Node))
      return;
    setOpenSelector(false);
    setSearchQuery("");
  };
  useHandleClickOutside(openSelector, handleClickOutside);

  const handleClickSelector = () => setOpenSelector((state) => !state);

  const selectPerson = (email: string) => {
    if (emails.includes(email)) {
      const updatedEmails = emails.filter((addedEmail) => addedEmail !== email);
      setSettings((state) => ({ ...state, emails: updatedEmails }));
    } else {
      const updatedEmails = [...emails, email];
      setSettings((state) => ({ ...state, emails: updatedEmails }));
    }
    setOpenSelector(false);
    setSearchQuery("");
  };

  const Person = (props: Person & { selected?: boolean }) => {
    const { email, name, photo, selected } = props;
    return (
      <S.Person
        alignItems="center"
        gap="xxs"
        onClick={() => email && selectPerson(email)}
      >
        <Avatar size="s" image={photo} name={name} />
        <Text
          textStyle="bodyInline"
          color={selected ? "ui01" : "text01"}
          style={{ textWrap: "nowrap" }}
        >
          {name}
        </Text>
        {selected && <Icon name="checkmark" width={19} color="ui01" />}
      </S.Person>
    );
  };

  return (
    <S.Wrapper>
      <S.Button active={!!emails.length} onClick={handleClickSelector}>
        <Icon name="personAdd" height={16} color="text01" />
        Compis
        <HStack ml="xxxs" gap="2px" py={{ default: 0, xxl: "1.5px" }}>
          {visibleSelectedPeople.map((person) => (
            <Avatar
              key={person.email}
              size="xs"
              image={person.photo}
              name={person.name}
            />
          ))}
        </HStack>
        {moreSelectedPeople > 0 && (
          <Text textStyle="bodyInline">
            <strong>+{moreSelectedPeople}</strong>
          </Text>
        )}
      </S.Button>

      {openSelector && (
        <S.SelectorBox ref={selectorRef}>
          <TextField
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Busca compis"
            small
            autofocus
          />
          <S.PeopleWrapper>
            {selectedPeople.map((person) => (
              <Person key={person.email} selected {...person} />
            ))}
            {unselectedPeople.map((person) => (
              <Person key={person.email} {...person} />
            ))}
          </S.PeopleWrapper>
        </S.SelectorBox>
      )}
    </S.Wrapper>
  );
};

export default PeopleSelector;
