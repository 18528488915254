import { AppEvent } from "@types";

const events: Record<string, AppEvent> = {
  applausesReminder: {
    weekDays: [5],
    hour: 12,
    minute: 0,
    title: (appState) => {
      const claps = appState?.applauses.remainingClaps;
      return claps ? `Aún te quedan ${claps} aplausos 👏` : null; // do not notify if there are no claps left
    },
    message:
      "¿Quieres enviar aplausos a alguien esta semana? Este puede ser un buen momento.",
    goTo: "/applauses",
  },
};

export default events;
