import React, { useMemo } from "react";
import { Flex, VStack } from "@sqymagma/elements";
import { AnimatePresence } from "framer-motion";

import { useApp, useStyle } from "@contexts";
import { Tooltip } from "@elements";
import { menu } from "@data";
import { THEMES } from "@constants";

import { ReactComponent as Logo } from "@icons/logo.svg";
import { ReactComponent as LogoInverted } from "@icons/logo-inverted.svg";
import partyHatLight from "@images/party-hat-light.png";
import partyHatDark from "@images/party-hat-dark.png";

import MenuItem from "./MenuItem";
import * as S from "./style";

const DesktopNavBar = () => {
  const { theme, localStorageTheme, changeTheme } = useStyle();
  const { reward } = useApp();

  const Menu = useMemo(
    () => (
      <VStack as="ul" gap="xxxs" alignItems="center">
        {menu.map((item, idx) => (
          <MenuItem {...item} key={idx} />
        ))}
      </VStack>
    ),
    []
  );

  const isDarkTheme = theme === THEMES.dark;

  const themeOptions = [
    {
      title: "Claro",
      action: () => changeTheme(THEMES.light),
      active: localStorageTheme === THEMES.light,
    },
    {
      title: "Oscuro",
      action: () => changeTheme(THEMES.dark),
      active: localStorageTheme === THEMES.dark,
    },
    {
      title: "Sistema",
      action: () => changeTheme(THEMES.system),
      active: localStorageTheme === THEMES.system,
    },
  ];

  return (
    <S.Wrapper display={{ default: "none", l: "flex" }}>
      <S.Menu>
        <Flex flexDirection="column" justifyContent="space-between">
          <VStack gap="l">
            <Flex justifyContent="center">
              <S.Logo
                onClick={() =>
                  window.open("https://www.secuoyas.com/", "_blank")
                }
              >
                <AnimatePresence>
                  {reward.type === "secuoyasBirthday" && (
                    <S.PartyHat
                      src={isDarkTheme ? partyHatDark : partyHatLight}
                      initial={{ opacity: 0, marginTop: "-100%" }}
                      animate={{ opacity: 1, marginTop: "0%" }}
                      transition={{ ease: "easeOut", duration: 1 }}
                    />
                  )}
                </AnimatePresence>
                <Tooltip content="secuoyas.com">
                  {isDarkTheme ? (
                    <LogoInverted width="50px" />
                  ) : (
                    <Logo width="50px" />
                  )}
                </Tooltip>
              </S.Logo>
            </Flex>
            {Menu}
          </VStack>
          <VStack alignItems="center" gap="xxxs">
            <MenuItem
              icon="colorPalette"
              title="Tema"
              submenu={{ options: themeOptions }}
              bottom
            />
          </VStack>
        </Flex>
      </S.Menu>
      <S.Fold />
    </S.Wrapper>
  );
};

export default DesktopNavBar;
