const isReqOk = (reqStatus: number) => reqStatus >= 200 && reqStatus < 400;

const handleRequest = async (params: {
  url: string;
  options?: RequestInit;
  errorMsg?: string;
}) => {
  try {
    const { url, options, errorMsg } = params;
    const response = await fetch(url, options);
    if (!isReqOk(response.status)) throw new Error(errorMsg);
    return await response.json();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.warn(error.message);
    return null;
  }
};

export { isReqOk, handleRequest };
