import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const Wrapper = styled.div`
  background-color: ${(p) => `${p.theme.colors.darkgray}15`};
  backdrop-filter: blur(50px);
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  margin-top: 2px;
`;

const HeaderRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 1fr;
`;

const HeaderCell = styled.div`
  ${theme("textStyle.subtitle02")};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.xxs};
`;

const Footer = styled.div`
  ${theme("textStyle.bodyInline")};
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export { Wrapper, HeaderRow, HeaderCell, Footer };
