import React from "react";
import { useHistory } from "react-router";
import { theme } from "@sqymagma/theme";

import { MiniWidget } from "@elements";
import { ReactComponent as KamelloWidgetSvg } from "@images/kamello-widget.svg";

const CamelsWidget = () => {
  const history = useHistory();

  const handleAction = () => {
    history.push("/camels");
  };

  return (
    <MiniWidget
      title="Carrera de Kamellos"
      description="Pasito a pasito"
      color={theme("colors.light")}
      icon="race"
      action={handleAction}
      image={KamelloWidgetSvg}
    />
  );
};

export default CamelsWidget;
