import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { AppProvider, ToastProvider } from "@contexts";
import { Desktop, SignIn } from "@screens";
import { ScrollToTop } from "@components";
import { SWorker } from "@types";

import { PrivateRoute } from "./PrivateRoute";

const Routes = (props: Props) => {
  const { serviceWorker } = props;
  const location = useLocation();
  const pathname = location.pathname === "/signin" ? "/signin" : "/";

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch location={location} key={pathname}>
        <Route exact path="/signin">
          <SignIn />
        </Route>
        <PrivateRoute path="/">
          <ScrollToTop />
          <AppProvider serviceWorker={serviceWorker}>
            <ToastProvider>
              <Desktop />
            </ToastProvider>
          </AppProvider>
        </PrivateRoute>
      </Switch>
    </AnimatePresence>
  );
};

interface Props {
  serviceWorker: SWorker;
}

export default Routes;
