import React, { useRef, useState, useEffect } from "react";
import { useResizeObserver } from "beautiful-react-hooks";

import * as S from "./style";

const Tabs = (props: {
  tabs: Array<string>;
  active: number;
  change: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { tabs, active, change } = props;
  const tabsRef = useRef<HTMLDivElement>(null);
  const [tabsWidth, setTabsWidth] = useState<Array<number>>([]);
  const tabsElementSize = useResizeObserver(
    tabsRef as React.MutableRefObject<HTMLElement>
  );

  const getTabsWidth = () => {
    if (tabsRef.current) {
      const children = tabsRef.current.children;
      const widths = [];
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const rect = child.getBoundingClientRect();
        widths.push(rect.width);
      }
      setTabsWidth(widths);
    }
  };

  useEffect(() => {
    getTabsWidth();
  }, [tabsElementSize]);

  const position = tabsWidth.reduce(
    (prev, curr, index) => (index < active ? prev + curr : prev),
    0
  );

  return (
    <S.OuterWrapper>
      <S.InnerWrapper>
        <S.Tabs ref={tabsRef}>
          {tabs.map((tab, index) => (
            <S.Tab
              key={index}
              onClick={() => change(index)}
              active={index === active}
            >
              {tab}
            </S.Tab>
          ))}
        </S.Tabs>

        <S.Active position={position} size={tabsWidth[active]} index={active} />
      </S.InnerWrapper>
    </S.OuterWrapper>
  );
};

export default Tabs;
