import styled from "styled-components";

const Wrapper = styled.div`
  .HW_badge {
    font-family: ${(p) => p.theme.fontFamily.primary};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    background-color: ${(p) => p.theme.colors.brand01};
  }

  .HW_badge.HW_softHidden {
    display: none;
  }
`;

const IconWrapper = styled.div`
  margin-right: 4px;
`;

export { IconWrapper, Wrapper };
