import { format } from "date-fns";
import { capitalizeFirstLetter } from "@helpers";

const es = require("date-fns/locale/es").default;

const getChartLabel = (data: [string, number]) => {
  const [year, month] = data[0].split("-");
  const date = new Date();
  date.setFullYear(parseInt(year));
  date.setMonth(parseInt(month) - 1);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return capitalizeFirstLetter(format(date, "MMMM", { locale: es }));
};

export { getChartLabel };
