import styled from "styled-components";
import { theme } from "@sqymagma/theme";

const DayHeaders = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  & > div {
    box-sizing: border-box;
    padding: ${(p) => p.theme.spacing.xs} 0;
    color: ${theme("colors.text01")};
    ${theme("textStyle.subtitle03")};
    font-weight: 700;
    text-align: center;
  }
`;

export { DayHeaders };
