import styled, { css } from "styled-components";

export const WeatherWrapper = styled.div<{ isFirstLoad: boolean }>`
  ${(p) =>
    p.isFirstLoad &&
    css`
      animation: fadeIn ${(p) => p.theme.timing.m} ease-in;
    `}

  & svg {
    fill: ${(p) => p.theme.colors.text01};
  }
`;
