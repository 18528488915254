import styled, { css } from "styled-components";

export const TextAreaWrapper = styled.div<{ height: string }>`
  min-height: ${(p) => p.height};
  transition: all ${(p) => p.theme.timing.m};
  border-width: 0 0 2px;
  border-style: solid;
  border-color: ${(p) => p.theme.colors.line02};
  &:hover {
    border-color: ${(p) => p.theme.colors.brand01};
  }
  &:focus-within {
    border-color: ${(p) => p.theme.colors.line02};
  }
`;

export const TextArea = styled.textarea<{ fontSize: number; height: string }>`
  font-family: ${(p) => p.theme.fontFamily.primary};
  font-size: ${(p) => `${p.fontSize}px`};
  width: 100%;
  height: ${(p) => p.height};
  overflow: auto;
  resize: none;
  border-width: 0;
  background-color: transparent;
  color: ${(p) => p.theme.colors.text01};
  transition: all ${(p) => p.theme.timing.m};
  padding: ${(p) => p.theme.spacing.xs} 0;
  cursor: pointer;
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `};

  &::placeholder {
    transition: all ${(p) => p.theme.timing.s};
  }

  &:focus {
    outline: 0;
    cursor: text;

    &::placeholder {
      opacity: 0;
    }
  }
`;
