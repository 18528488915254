import styled from "styled-components";

const WrappedHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 16px 24px 0;
  @media (min-width: 768px) {
    background: #131212;
  }
`;

const Nav = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  margin-bottom: 12px;
`;

const Item = styled.li<{ $active: boolean; $duration: number }>`
  flex: 1;
  height: 2px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.6);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background: #fff;
    width: 0;
    height: 2px;
    border-radius: 2px;
  }

  ${(p) =>
    p.$active &&
    `
    &::after {
      content: "";
      position:absolute;
      background:#fff;
      width: 100%;
      transition: width ${p.$duration}ms linear;
    }
  `}
`;

const Player = styled.li`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const CustomButton = styled.button`
  border: none;
  outline: none;
  background: none;
  padding: 0;
`;

export { WrappedHeader, Nav, Item, Player, CustomButton };
