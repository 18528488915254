import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Box } from "@sqymagma/elements";

import { NAVBAR_HEIGHT } from "./constants";

const Wrapper = styled(Box)`
  background-color: ${(p) => p.theme.colors.ui04};
  border-bottom: 1px solid ${(p) => p.theme.colors.line03};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${NAVBAR_HEIGHT};
  padding: 0 ${(p) => p.theme.spacing.xs};
  position: relative;
`;

const MenuButton = styled.div`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;

  span {
    margin: 0 auto;
    position: relative;
    top: 12px;
    transition-duration: 0s;
    transition-delay: 0.2s;
  }

  span:before,
  span:after {
    position: absolute;
    content: "";
  }

  span,
  span:before,
  span:after {
    width: 30px;
    height: 4px;
    background-color: ${(p) => p.theme.colors.interactive02};
    display: block;
  }

  span:before {
    margin-top: -10px;
    transition-property: margin, transform;
    transition-duration: 0.2s;
    transition-delay: 0.2s, 0s;
  }

  span:after {
    margin-top: 10px;
    transition-property: margin, transform;
    transition-duration: 0.2s;
    transition-delay: 0.2s, 0s;
  }

  &.open span {
    background-color: rgba(0, 0, 0, 0);
    transition-delay: 0.2s;
  }

  &.open span:before {
    margin-top: 0;
    transform: rotate(45deg);
    transition-delay: 0s, 0.2s;
  }

  &.open span:after {
    margin-top: 0;
    transform: rotate(-45deg);
    transition-delay: 0s, 0.2s;
  }
`;

const Picture = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const Logo = styled(NavLink)`
  fill: ${(p) => p.theme.colors.text01};
  line-height: 0;
  display: flex;
  align-items: center;
`;

const Badge = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.info};
  border: 1px solid ${(p) => p.theme.colors.ui04};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: -7px;
  top: -7px;
`;

const AppName = styled(Box)`
  font-family: "Moderna", sans-serif;
  font-size: 26px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.text01};
`;

export { Wrapper, MenuButton, Picture, Logo, Badge, AppName };
