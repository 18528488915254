import { useEffect } from "react";

const useHandleClickOutside = (
  isOpen: boolean,
  handleClickOutside: (e: MouseEvent) => void
) => {
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);
};

export { useHandleClickOutside };
