import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Text, HStack } from "@sqymagma/elements";
import { useHistory } from "react-router-dom";

import { Avatar, Badge, Icon, Tooltip } from "@elements";
import { useApp } from "@contexts";
import { remoteApi } from "@services";
import { MoodState } from "@types";
import { useHandleClickOutside, useModal } from "@hooks";
import { getPostTimeFromUnix, getUserDataFromEmail } from "@helpers";

import * as S from "./style";

const CommentsNotifier = () => {
  const history = useHistory();
  const { moodsWithNewComments, people } = useApp();
  const [moods, setMoods] = useState<Array<MoodState>>([]);

  const menuRef = useRef<HTMLDivElement>(null);
  const { isOpen, toggleModal } = useModal();

  const handleClickOutside = (e: MouseEvent) => {
    if (menuRef.current && menuRef.current.contains(e.target as Node)) return;
    toggleModal();
  };
  useHandleClickOutside(isOpen, handleClickOutside);

  useEffect(() => {
    remoteApi.getMoodsById(moodsWithNewComments).then((data) => setMoods(data));
  }, [moodsWithNewComments]);

  const badge = moodsWithNewComments.length;
  const tooltip = !badge ? "Ningún comentario sin leer" : "";

  const handleIconClick = () => badge && toggleModal();

  const openMood = (mood: MoodState) => () => {
    toggleModal();
    history.push(`/mood`, { openMood: mood });
  };

  return (
    <S.Wrapper $clickable={!!badge} ref={menuRef} onClick={handleIconClick}>
      <Tooltip content={tooltip}>
        <>
          <Icon name="comment" size="small" color="interactive01" />
          {!!badge && (
            <S.BadgeWrapper>
              <Badge>{badge}</Badge>
            </S.BadgeWrapper>
          )}
        </>
      </Tooltip>

      <AnimatePresence>
        {isOpen && (
          <S.Menu
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.15 }}
            onClick={toggleModal}
          >
            {moods?.map((mood, index) => {
              const user = getUserDataFromEmail(mood.user, people);
              return (
                <S.MenuItem key={index} onClick={openMood(mood)}>
                  <Avatar
                    image={user?.photo}
                    name={user?.name}
                    size="s"
                    squared
                  />
                  <S.TextWrapper>
                    <Text textStyle="bodyInline" color="text01">
                      Nuevos comentarios en la publicación de{" "}
                      <b>{user?.name}</b>
                    </Text>
                    <HStack alignItems="center" gap="xs">
                      <Text textStyle="tiny" color="text01">
                        {getPostTimeFromUnix(mood.created._seconds)}
                      </Text>
                      <HStack alignItems="center" gap="xxxs">
                        <Icon name="comment" size="xs" color="interactive01" />
                        <Text textStyle="tiny" color="text01">
                          {mood.comments?.length}
                        </Text>
                      </HStack>
                    </HStack>
                  </S.TextWrapper>
                </S.MenuItem>
              );
            })}
          </S.Menu>
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
};

export default CommentsNotifier;
