import * as React from "react";
import { Text, Box, Flex } from "@sqymagma/elements";
import { googleApi } from "@services";
import { StepWrapper } from "../style";
import { WRAPPED_STEPS } from "../constants";
import email from "@images/emailWrapped.svg";
import mails from "@gifs/mails.gif";
import { GifEmails } from "./style";
import moment from "moment";
require("moment-precise-range-plugin");

type GoogleProps = {
  active: boolean;
  wrappedStart: Date;
  wrappedEnd: Date;
};

const GoogleEmails = ({ active, wrappedStart, wrappedEnd }: GoogleProps) => {
  const [totalInboxEmails, setTotalInboxEmails] = React.useState(0);
  const [totalSentEmails, setTotalSentEmails] = React.useState(0);
  const [activeItem, setActiveItem] = React.useState(0);
  const config = WRAPPED_STEPS.find(({ step }) => step === "GoogleEmails");
  const durations = config?.children;

  const getInboxEmails = async (total = 0, nextPageToken?: string) => {
    let sum = total;
    const data = await googleApi.getAllEmails(
      500,
      `before:${moment(wrappedEnd).format("YYYY/MM/DD")} after:${moment(
        wrappedStart
      ).format("YYYY/MM/DD")}`,
      "INBOX",
      nextPageToken ? nextPageToken : undefined
    );

    sum += data?.messages?.length || 0;

    if (data?.nextPageToken) {
      await getInboxEmails(sum, data.nextPageToken);
    } else {
      setTotalInboxEmails(sum);
    }
  };

  const getSentEmails = async (total = 0, nextPageToken?: string) => {
    let sum = total;
    const data = await googleApi.getAllEmails(
      500,
      `before:${moment(wrappedEnd).format("YYYY/MM/DD")} after:${moment(
        wrappedStart
      ).format("YYYY/MM/DD")}`,
      "SENT",
      nextPageToken ? nextPageToken : undefined
    );

    sum += data?.messages?.length || 0;

    if (data?.nextPageToken) {
      await getSentEmails(sum, data.nextPageToken);
    } else {
      setTotalSentEmails(sum);
    }
  };

  React.useEffect(() => {
    getInboxEmails();
    getSentEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextItem = (active: number) => {
    if (!durations || active >= durations.length) {
      return;
    }

    setActiveItem(active);

    setTimeout(() => {
      nextItem(active + 1);
    }, durations[active] * 1000);
  };

  React.useEffect(() => {
    if (active && durations) {
      setTimeout(() => {
        nextItem(activeItem + 1);
      }, durations[activeItem] * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <StepWrapper
      $bg="#FFB152"
      $deg1="rgb(255, 249, 91, 0.70)"
      $deg2="rgb(229, 0, 78, 0.70)"
      $rotate="69.964deg"
      style={{
        opacity: active ? 1 : 0,
      }}
    >
      {/* 1 */}
      {activeItem === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box>
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Noto Serif', serif",
                fontSize: "27px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.6px",
              }}
            >
              Ha sido un año de
            </Text>
          </Box>
          <Box>
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "70px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.6px",
              }}
            >
              conexión
            </Text>
          </Box>
          <Box mt="64px">
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Gilroy', sans-serif",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.6px",
              }}
            >
              En los que hemos intercambiado cientos de emails, conversaciones,
              ideas y algún que otro GIF.
            </Text>
          </Box>
        </div>
      )}

      {/* 2 */}
      {activeItem === 1 && (
        <div>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box>
              <Text
                color="#fff"
                width="100%"
                style={{
                  fontFamily: "'Noto Serif', serif",
                  fontSize: "27px",
                  fontWeight: "500",
                  letterSpacing: "0.48px",
                  lineHeight: "155%",
                }}
              >
                Nuestra <strong>bandeja de entrada</strong> ha sido una
                auténtica pista de aterrizaje
              </Text>
            </Box>
            <Flex flexDirection="column">
              <Flex justifyContent="end">
                <Text
                  color="#fff"
                  width="100%"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "100px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    letterSpacing: "0.6px",
                    textAlign: "right",
                  }}
                >
                  {Number(totalInboxEmails).toLocaleString("es-ES")}
                </Text>
              </Flex>
              <Flex justifyContent="end">
                <Text
                  color="#FFF0A4"
                  width="60%"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "40px",
                    fontWeight: "500",
                    textAlign: "right",
                  }}
                >
                  emails recibidos
                </Text>
              </Flex>
              <Flex justifyContent="end">
                <Text
                  color="#fff"
                  width="100%"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "100px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    letterSpacing: "0.6px",
                    textAlign: "right",
                  }}
                >
                  {Number(totalSentEmails).toLocaleString("es-ES")}
                </Text>
              </Flex>
              <Flex justifyContent="end">
                <Text
                  color="#FFF0A4"
                  width="60%"
                  style={{
                    fontFamily: "'Abril Fatface', serif",
                    fontSize: "40px",
                    fontWeight: "500",
                    textAlign: "right",
                  }}
                >
                  emails enviados
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </div>
      )}

      {/* 3 */}
      {activeItem === 2 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box height="270px" width="270px" position="relative">
            <GifEmails $image={mails} />
            <img src={email} alt={"email"} width="270px" />
          </Box>
          <Box mt="68px" mb="15px">
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Noto Serif', serif",
                fontSize: "40px",
                fontWeight: "500",
              }}
            >
              Nuestro buzón
            </Text>
          </Box>
          <Flex justifyContent="center" width="70%">
            <Text
              color="#fff"
              width="100%"
              style={{
                fontFamily: "'Gilroy', sans-serif",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.6px",
                textAlign: "center",
              }}
            >
              está más lleno que un buffet libre en hora punta
            </Text>
          </Flex>
        </div>
      )}
    </StepWrapper>
  );
};

export default GoogleEmails;
