import React from "react";
import { theme } from "@sqymagma/theme";
import { useHistory } from "react-router";

import { MiniWidget } from "@elements";
import { ReactComponent as TaxonomySvg } from "@images/taxonomy.svg";

const Taxonomy = () => {
  const history = useHistory();

  const handleAction = () => {
    history.push("/library/taxonomy");
  };

  return (
    <MiniWidget
      title="Taxonomy"
      description="Nuestra newsletter"
      color={theme("colors.light")}
      icon="email"
      action={handleAction}
      image={TaxonomySvg}
    />
  );
};

export default Taxonomy;
