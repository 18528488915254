import * as React from "react";
import { Text, Box } from "@sqymagma/elements";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  ChartDataset,
  Legend,
} from "chart.js";

import { useStyle } from "@contexts";
import { THEMES } from "@constants";

import * as S from "./style";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);
ChartJS.defaults.font.family = "'Gilroy', sans-serif";

interface ChartProps {
  title?: string;
  labels?: Array<string>;
  datasets: Array<ChartDataset<"line">>;
  stepSize?: number;
}

const Chart = (props: ChartProps) => {
  const { title, labels, datasets, stepSize = 1 } = props;

  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;

  return (
    <S.ChartContainer>
      {title && (
        <Text textStyle="subtitle03" color="text01" textAlign="center">
          {title}
        </Text>
      )}
      <Box position="relative" width="100%">
        <Line
          data={{
            labels,
            datasets: datasets.map((dataset) => ({
              tension: 0,
              borderColor: dataset.borderColor,
              borderWidth: 2,
              hoverBorderWidth: 2,
              pointBackgroundColor: isDarkTheme ? "#1a1e24" : "#ffffff",
              ...dataset,
            })),
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                align: "center",
                labels: {
                  color: isDarkTheme ? "#f6f6f6" : "#101010",
                  usePointStyle: true,
                  pointStyle: "rectRounded",
                  boxHeight: 8,
                  boxWidth: 8,
                },
              },
              tooltip: {
                displayColors: false,
              },
            },
            scales: {
              x: {
                grid: { color: isDarkTheme ? "#363636" : "#e1e5ea" },
                ticks: {
                  color: isDarkTheme ? "#ffffff" : "#101010",
                  callback: (value, index) =>
                    labels?.[index].slice(0, 3).toLowerCase(),
                },
              },
              y: {
                beginAtZero: stepSize === 1,
                grid: { color: isDarkTheme ? "#363636" : "#e1e5ea" },
                ticks: {
                  color: isDarkTheme ? "#ffffff" : "#101010",
                  stepSize,
                },
              },
            },
          }}
        />
      </Box>
    </S.ChartContainer>
  );
};

export default Chart;
