import React, { useCallback, useEffect, useState } from "react";
import { useApp } from "@contexts";
import { googleApi } from "@services";
import { Tabs, Avatar, Loader, Tooltip, IconLink } from "@elements";
import { Camel } from "@types";
import { Flex, Text, Box } from "@sqymagma/elements";
import { THEMES } from "@constants";
import { Icon } from "@elements";
import { useStyle } from "@contexts";
import * as S from "./style";
import medalSmall from "@images/medalSmall.svg";
import kamello from "@images/kamello-featured.svg";
import Ranking from "./ranking";
import Teams from "./teams";
import { Animation } from "@components";
import {
  getGroupsSteps,
  Team,
  GeneralClassificationTeam,
  getGeneralClassification,
  writeGeneralClassification,
} from "./utils";

const Camels = () => {
  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;

  const [loading, setLoading] = useState(true);
  const [ranking, setRanking] = useState<Array<Camel>>([]);
  const [featuredRanking, setFeaturedRanking] = useState<Array<Camel>>([]);
  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [teamsSteps, setTeamsSteps] = useState<Array<Team>>([]);
  const [teamsGeneralClassification, setTeamsGeneralClassification] = useState<
    Array<GeneralClassificationTeam>
  >([]);
  const [activeTab, setActiveTab] = useState(0);
  const [totalStepsLastWeek, setTotalStepsLastWeek] = useState<number>(0);
  const { people } = useApp();

  const tabs = [
    "división guepardo (+70k)",
    "división gacela (40-70k)",
    "división liebre (-40k)",
  ];

  const getAverage = (steps: Array<number>) => {
    const filterSteps = steps.filter((steps) => steps !== 0);
    const average = filterSteps.reduce((a, b) => a + b, 0) / filterSteps.length;

    return Math.round(steps[steps.length - 1] - average);
  };

  const loadData = useCallback(() => {
    const { REACT_APP_CAMELS_GSHEET_ID: spreadsheetId } = process.env;
    if (!spreadsheetId) return;
    const payload = { spreadsheetId, range: "Carreras!A1:ZZ100" };
    googleApi
      .getSheet(payload)
      .then((rows) => {
        if (rows) {
          const dates = rows[0];
          rows.shift();
          const weekSteps: Array<Camel> = [];
          rows.map((person) => {
            const camel = people.find((camel) => person[0] === camel.email);
            let photo = "";
            let name = "";
            if (camel && camel.photo) {
              photo = camel.photo;
            }
            if (camel && camel.name) {
              name = camel.name;
            }

            return weekSteps.push({
              email: person[0],
              steps: Number(person[dates.length - 1]),
              name: name,
              photo: photo,
              average: getAverage(
                person.slice(1).map((steps) => Number(steps))
              ),
            });
          });
          const ranking = weekSteps
            .sort((a, b) => b.steps - a.steps)
            .filter((person) => Number(person.steps) > 0)
            .slice(0, weekSteps.length);
          const total = weekSteps
            .map((person) => person.steps)
            .reduce((prev, curr) => prev + curr, 0);
          const totalLastWeek = rows
            .map((person) => Number(person[dates.length - 2]))
            .reduce((prev, curr) => prev + curr, 0);
          const featuredRanking = weekSteps
            .sort((a, b) => b.average - a.average)
            .filter((person) => Number(person.average) > 0)
            .slice(0, 3);
          setFeaturedRanking(featuredRanking);
          setRanking(ranking);
          setTotalSteps(total);
          setTotalStepsLastWeek(totalLastWeek);
          const teamSteps = getGroupsSteps(weekSteps).sort(
            (a, b) => b.totalSteps - a.totalSteps
          );
          if (teamSteps) {
            writeGeneralClassification(teamSteps).then(() => {
              getGeneralClassification().then((generalClassification) => {
                if (generalClassification)
                  setTeamsGeneralClassification(
                    generalClassification.sort(
                      (a, b) => b.totalSteps - a.totalSteps
                    )
                  );
              });
            });
          }

          setTeamsSteps(teamSteps);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [people]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading) {
    return (
      <Flex justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        justifyContent="center"
        flexWrap="wrap"
        mb="l"
        style={{ gap: "48px" }}
      >
        <Flex
          minWidth="200px"
          width="25%"
          minHeight="100%"
          bg="secondaryBackground"
          flexDirection="column"
          borderRadius="m"
        >
          <Flex
            maxWidth="100%"
            height="100%"
            flexDirection="column"
            borderRadius="7px"
            border="1px solid"
            borderColor="line01"
            m="xxxs"
            p="s"
            justifyContent="center"
            alignItems="center"
            style={{ gap: "5px" }}
          >
            {isDarkTheme ? (
              <Icon name="logo" width={86} />
            ) : (
              <Icon name="logoInverted" width={86} />
            )}
            <Text color="gold" textStyle="body" weight="bold" mt="xs">
              {Number(totalStepsLastWeek).toLocaleString("es-ES")}
            </Text>
            <Flex justifyContent="center" alignItems="center">
              <Text color="gold" textStyle="display02" weight="bold">
                {Number(totalSteps).toLocaleString("es-ES")}
              </Text>
              <Text ml="xxs">
                {Number(totalStepsLastWeek) < Number(totalSteps) ? (
                  <Icon name="arrowRising" width={18} color="gold" />
                ) : (
                  <Icon name="arrowDescending" width={18} color="gold" />
                )}
              </Text>
            </Flex>
            <Text color="gold" textStyle="body" textAlign="center">
              pasos entre todos
            </Text>

            <Text color="text02" textStyle="display04" weight="bold">
              SQY
            </Text>
          </Flex>
        </Flex>
        <Box>
          <S.FeaturedBox>
            <S.IconBox>
              <img src={kamello} alt="medal" width="40px" />
            </S.IconBox>
            <S.TextBox>
              <S.ArrowRight />
              Mención especial por su evolución *
              <S.ArrowLeft />
            </S.TextBox>
            <Box mt="m" pt="xxs">
              <Text
                color="gold"
                textStyle="bodyInline"
                display="block"
                textAlign="center"
              >
                * Respecto al promedio de semanas anteriores
              </Text>
              {featuredRanking.length > 0 &&
                featuredRanking?.map((person, idx) => (
                  <Box key={idx} display="flex" alignItems="center" mt="s">
                    <Box>
                      <img src={medalSmall} alt="medal" width="40px" />
                    </Box>
                    <Box ml="xs">
                      <Avatar
                        image={person?.photo}
                        name={person?.name}
                        size="m"
                      />
                    </Box>
                    <Box fontSize="24px" ml="xs">
                      <Text
                        textStyle="display04"
                        color="text01"
                        mb="xxxs"
                        display="block"
                      >
                        {person.name}
                      </Text>
                      <Text textStyle="bodyLead" color="text01">
                        {Number(person.average) > 0 && "+"}
                        {Number(person.average).toLocaleString("es-ES")}
                      </Text>
                      <Text textStyle="body" color="text01">
                        {" "}
                        pasos
                      </Text>
                    </Box>
                  </Box>
                ))}
            </Box>
          </S.FeaturedBox>
        </Box>

        <S.FeaturedBox>
          <S.AnimationWrapper>
            <S.Animation>
              <Animation name="confetti" width="100%" height="100%" />
            </S.Animation>
          </S.AnimationWrapper>

          <Flex flexDirection="column">
            <Text
              textStyle="subtitle01"
              color="text01"
              weight="bold"
              pb="s"
              width="200px"
            >
              Los ganadores del reto de project managers son...
            </Text>
            <Flex
              alignItems="center"
              justifyContent="center"
              style={{ gap: "32px" }}
              pt="s"
              flexDirection="column"
            >
              <Tooltip content={"Team Majo"}>
                <S.PersonWrapper>
                  <S.Wreath>
                    <Icon name="wreath" width={94} />
                  </S.Wreath>

                  {isDarkTheme ? (
                    <Icon name="logoInverted" width={50} />
                  ) : (
                    <Icon name="logo" width={50} />
                  )}
                </S.PersonWrapper>
              </Tooltip>
              <Box fontSize="24px" ml="xs">
                <Text
                  textStyle="display04"
                  color="text01"
                  mb="xxxs"
                  display="block"
                  textAlign="center"
                >
                  Team Majo
                </Text>
                <Text textStyle="bodyLead" color="text01">
                  2.770.048
                </Text>
                <Text textStyle="body" color="text01">
                  {" "}
                  pasos
                </Text>
              </Box>
            </Flex>
          </Flex>
        </S.FeaturedBox>
      </Flex>

      <S.ToolBar>
        <Tabs tabs={tabs} active={activeTab} change={setActiveTab} />
        <IconLink
          inverted
          icon="sync"
          tooltip="Recargar ranking"
          rotateOnClick
          action={loadData}
        />
      </S.ToolBar>

      {activeTab === 0 && !!ranking.length && (
        <Ranking
          ranking={ranking
            .filter((person) => Number(person.steps) > 70000)
            .sort((a, b) => b.steps - a.steps)}
        />
      )}

      {activeTab === 1 && !!ranking.length && (
        <Ranking
          ranking={ranking
            .filter(
              (person) =>
                Number(person.steps) >= 40000 && Number(person.steps) <= 70000
            )
            .sort((a, b) => b.steps - a.steps)}
        />
      )}

      {activeTab === 2 && !!ranking.length && (
        <Ranking
          ranking={ranking
            .filter((person) => Number(person.steps) < 40000)
            .sort((a, b) => b.steps - a.steps)}
        />
      )}

      {activeTab === 3 && !!ranking.length && (
        <Teams
          teamsSteps={teamsSteps?.length ? teamsSteps : []}
          teamsGeneralClassification={
            teamsGeneralClassification?.length ? teamsGeneralClassification : []
          }
        />
      )}
    </>
  );
};

export default Camels;
