import styled from "styled-components";

const Background = styled.div`
  position: fixed;
  top: 0;
`;

const Circle1 = styled.div`
  position: absolute;
  width: 470px;
  height: 470px;
  left: 80px;
  top: 100px;
  background: linear-gradient(180deg, #3cc3dd 0%, #a950cf 100%);
  mix-blend-mode: difference;
  opacity: 0.5;
  filter: blur(120px);
`;

const Circle2 = styled.div`
  position: absolute;
  width: 560px;
  height: 560px;
  left: 940px;
  top: 470px;
  background: linear-gradient(180deg, #fbe15c 0%, #3ac7d3 100%);
  mix-blend-mode: difference;
  opacity: 0.5;
  filter: blur(120px);
`;

export { Background, Circle1, Circle2 };
