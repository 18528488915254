const getHeaders = () => {
  const token = localStorage.getItem("token");
  const idToken = token && JSON.parse(token);
  return {
    headers: {
      "X-Requested-With": "XmlHttpRequest",
      ...(idToken && { Authorization: idToken }), // used in the backend to check if the user is authorized
    },
  };
};

export { getHeaders };
