const DEFAULT_DELAY = 5;

const WRAPPED_STEPS = [
  {
    step: "Welcome",
    children: [4, 2, DEFAULT_DELAY],
  },
  {
    step: "Days",
    children: [DEFAULT_DELAY, DEFAULT_DELAY, 4.5],
  },
  {
    step: "GoogleEmails",
    children: [DEFAULT_DELAY, DEFAULT_DELAY, DEFAULT_DELAY],
  },
  {
    step: "HubPlanner",
    children: [
      DEFAULT_DELAY,
      3,
      8,
      DEFAULT_DELAY,
      8,
      // DEFAULT_DELAY, // Tus Inseparables Compañeros de Proyecto
      DEFAULT_DELAY,
    ],
  },
  {
    step: "Claps",
    children: [DEFAULT_DELAY, 11, 11],
    childrenNoData: [DEFAULT_DELAY, 4.5],
  },
  {
    step: "Google",
    children: [DEFAULT_DELAY, DEFAULT_DELAY, 8, 10],
  },
  {
    step: "Camels",
    children: [DEFAULT_DELAY, 10],
    childrenNoData: [DEFAULT_DELAY],
  },
  {
    step: "Taxonomy",
    children: [DEFAULT_DELAY, 10.5],
  },
  {
    step: "Farewell",
    children: [DEFAULT_DELAY, DEFAULT_DELAY, DEFAULT_DELAY, DEFAULT_DELAY, DEFAULT_DELAY, DEFAULT_DELAY],
  },
];

export { WRAPPED_STEPS, DEFAULT_DELAY };
