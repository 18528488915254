import React, { useEffect } from "react";
import { Text } from "@sqymagma/elements";

import { useApp } from "@contexts";
import { Icon, SliderField } from "@elements";
import { useHandleClickOutside } from "@hooks";
import { Applause } from "@types";

import * as S from "./style";
import { SettingsProps } from "..";

interface ClapsSelectorProps {
  settings: SettingsProps;
  setSettings: React.Dispatch<React.SetStateAction<SettingsProps>>;
  update?: Applause;
}

const ClapsSelector = (props: ClapsSelectorProps) => {
  const {
    settings: { claps },
    setSettings,
    update,
  } = props;

  const {
    applauses: { remainingClaps },
    getRemainingClaps,
  } = useApp();

  const [lastValue, setLastValue] = React.useState(claps);
  const [openSelector, setOpenSelector] = React.useState(false);
  const selectorRef = React.useRef<HTMLDivElement>(null);

  const maxClaps = remainingClaps + (update?.claps || 0) || 0;

  useEffect(() => {
    maxClaps < lastValue && setLastValue(0);
  }, [lastValue, maxClaps]);

  useEffect(() => {
    if (!openSelector) setLastValue(claps);
  }, [claps, openSelector]);

  const handleClickOutside = (e: MouseEvent) => {
    if (selectorRef.current && selectorRef.current.contains(e.target as Node))
      return;
    setOpenSelector(false);
  };
  useHandleClickOutside(openSelector, handleClickOutside);

  const handleClickSelector = () => {
    !openSelector && getRemainingClaps();
    setOpenSelector((state) => !state);
  };

  const handleChange = (claps: number) =>
    setSettings((state) => ({ ...state, claps }));

  return (
    <S.Wrapper>
      <S.Button active={lastValue > 0} onClick={handleClickSelector}>
        <Icon name="plusCircle" height={16} color="text01" />
        Aplausos
        {lastValue > 0 && (
          <Text textStyle="bodyInline">
            👏 <strong>{lastValue}</strong>
          </Text>
        )}
      </S.Button>

      {openSelector && (
        <S.SelectorBox ref={selectorRef}>
          <SliderField
            step={5}
            suffix={`/${maxClaps} 👏`}
            max={maxClaps}
            value={claps}
            onChange={handleChange}
            onEnterPress={() => setOpenSelector(false)}
            autofocus
          />
        </S.SelectorBox>
      )}
    </S.Wrapper>
  );
};

export default ClapsSelector;
