import React from "react";
import Snowfall from "react-snowfall";

import { useStyle } from "@contexts";
import { Animation } from "@components";
import { THEMES } from "@constants";

import * as S from "./style";

const Christmas = () => {
  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;

  return (
    <>
      <S.Snowfall>
        <Snowfall
          color={isDarkTheme ? "#252c33" : "#dee4fd"}
          snowflakeCount={150}
          speed={[0.5, 0.1]}
          wind={[-0.5, 1.5]}
          radius={[0.5, 1.5]}
        />
      </S.Snowfall>

      <S.Lights>
        <Animation name="lights" />
      </S.Lights>

      <S.Santa>
        <Animation name="santa" />
      </S.Santa>
    </>
  );
};

export default Christmas;
