import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useAuth } from "@contexts";

const PrivateRoute = (props: Props) => {
  const { children, ...rest } = props;

  const auth = useAuth();
  const { user } = auth;

  if (!user) {
    return (
      <Route {...rest}>
        <Redirect to={{ pathname: "/signin" }} />
      </Route>
    );
  }

  return <Route {...rest}>{children}</Route>;
};

interface Props {
  children: React.ReactNode;
  [x: string]: unknown;
}

export { PrivateRoute };
