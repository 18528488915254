import { meetings } from "@data";
import { MeetingId } from "@data/meetings";

const getAnimationProps = () => {
  const createProps = () => ({
    animationDelay: Math.random() * 0.6 + 0.15,
    animationDuration: Math.random() * 0.1 + 0.25,
  });
  return [...Array(meetings.length)].map(createProps);
};

const getValidMeetings = (uncheckedMeetings: MeetingId[] | null) => {
  if (!uncheckedMeetings) return;

  const validMeetings = uncheckedMeetings.filter((uncheckedMeeting) => {
    return meetings.some((meeting) => meeting.id === uncheckedMeeting);
  });

  const newMeetings = meetings.reduce((prev, curr) => {
    const isNewMeeting = !uncheckedMeetings.includes(curr.id);
    return isNewMeeting ? [...prev, curr.id] : prev;
  }, [] as MeetingId[]);

  validMeetings.push(...newMeetings);

  return validMeetings;
};

export { getAnimationProps, getValidMeetings };
