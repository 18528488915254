import styled from "styled-components";
import mask1 from "@images/masks/mascara-aplausos1.svg";

const GifMeetings = styled.div<{ $image: string }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url(${mask1});
  mask-position: center;
  mask-size: 300px;
  mask-repeat: no-repeat;
  width: 300px;
  height: 300px;
  position: absolute;
  right: -50px;
  bottom: -10px;
  z-index: -1;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 8s;
  animation-fill-mode: forwards;
`;

export { GifMeetings };
