import React from "react";
import { Text, Flex } from "@sqymagma/elements";

import { useApp } from "@contexts";
import { Icon } from "@elements";

import * as S from "./style";
import { IconNames } from "@elements/Icon";

const ToolLink = (props: Props) => {
  const { id, name, url, tiny } = props;
  const { addRecentTools } = useApp();

  const handleClick = () => {
    if (url) window.open(url, "_blank");
    addRecentTools(id);
  };

  return (
    <S.ToolBoxWrapper>
      <S.ToolBox onClick={handleClick} $tiny={tiny}>
        <Flex alignItems="center">
          <Icon name={id} />
          {!tiny && (
            <>
              <Text textStyle="body" pl="xxs" pr="xs" weight="bold">
                {name}
              </Text>
              <Icon name="externalLink" size="small" color="text01" />
            </>
          )}
        </Flex>
      </S.ToolBox>
    </S.ToolBoxWrapper>
  );
};

interface Props {
  id: IconNames;
  name: string;
  url: string;
  tiny?: boolean;
}

export default ToolLink;
