const { REACT_APP_EXPRESSO: expressoUrl } = process.env;

const getCoffees = async () => {
  if (!expressoUrl) return;
  try {
    const response = await fetch(expressoUrl, {
      cache: "no-store",
    });
    return await response.json();
  } catch (error) {
    console.warn(
      `Error while getting virtual coffees from ${expressoUrl}.`,
      error
    );
  }
};

const coffeesApi = { getCoffees };

export default coffeesApi;
