import React, { useEffect, useState } from "react";
import { useResizeObserver } from "beautiful-react-hooks";

const useOutOfBounds = (elementRef: React.MutableRefObject<HTMLElement>) => {
  const elementSize = useResizeObserver(elementRef);
  const [elementBounds, setElementBounds] = useState({
    height: 0,
    fixOutOfBounds: 0,
  });

  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight;
      const clientRect = elementRef.current.getBoundingClientRect();
      const { top, bottom } = clientRect;
      const windowHeight = window.innerHeight;
      const bounds = {
        height,
        fixOutOfBounds: 0,
        ...(bottom > windowHeight && { fixOutOfBounds: bottom - windowHeight }),
        ...(top < 0 && { fixOutOfBounds: top }),
      };
      setElementBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementSize]);

  return elementBounds;
};

export { useOutOfBounds };
