import { init, send } from "emailjs-com";

const {
  REACT_APP_EMAILJS_FEEDBACK_TEMPLATE: feedback,
  REACT_APP_EMAILJS_CULTURE_TEMPLATE: culture,
  REACT_APP_EMAILJS_USER_ID: user,
} = process.env;

const templates = {
  feedback,
  culture,
};

const sendMail = async (message: string, template: MailTemplate) => {
  const templateID = templates[template];

  if (!user || !templateID) return { ok: false };

  init(user);

  try {
    await send("default_service", templateID, { message }, user);
    return { ok: true };
  } catch (error) {
    console.error("Failed to send message", error);
    return { ok: false };
  }
};

const mailApi = { send: sendMail };

export type MailTemplate = keyof typeof templates;

export default mailApi;
