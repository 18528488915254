import * as React from "react";

import { Holiday } from "@types";
import { calendarColors } from "@config";

import * as S from "../Event/style";

const HolidayComponent = (props: { holiday: Holiday }) => {
  const { holiday } = props;
  return (
    <S.Event color={calendarColors.holidays}>
      <div className="event-data">
        <span>{holiday.name}</span>
      </div>
    </S.Event>
  );
};

export default HolidayComponent;
