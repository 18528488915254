import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
  width: calc(${(p) => p.theme.spacing.xxxl} * 4);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: ${(p) => `${p.theme.colors.primaryBackground}88`};
  backdrop-filter: blur(30px);
  box-shadow: ${(p) => p.theme.shadows.medium};
  animation: slide ${(p) => p.theme.timing.s} ease-in;

  @keyframes slide {
    0% {
      transform: translateX(calc(${(p) => p.theme.spacing.xxxl} * 4));
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const ModalHeader = styled.div`
  background: ${(p) => p.theme.colors.secondaryBackground};
  display: flex;
  padding: ${(p) => p.theme.spacing.xs};
`;

const ModalContent = styled.div`
  position: relative;
  flex-grow: 1;
  overflow: auto;
  padding: ${(p) => p.theme.spacing.xs};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headings};
  color: ${(p) => p.theme.colors.text02};
`;

const ButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;

export { Modal, ModalHeader, ModalContent, Title, ButtonWrapper };
