import * as React from "react";
import { useEffect, useState } from "react";
import { Text, VStack } from "@sqymagma/elements";

import { WidgetBox } from "@components";
import { IconNames } from "@elements/Icon";
import { Icon } from "@elements";
import { getRandom } from "@helpers";
import { useApp } from "@contexts";

import { averageMsg } from "./data";
import * as S from "./style";

interface AverageCardProps {
  average?: number;
  users?: number;
}

const AverageCard = (props: AverageCardProps) => {
  const { average, users } = props;

  const [message, setMessage] = useState("");
  const { people } = useApp();

  useEffect(() => {
    const { text } = getIconNameAndMsg(average);
    setMessage(text);
  }, [average]);

  const getIconNameAndMsg = (
    value?: number
  ): { icon: IconNames; text: string } => {
    switch (value) {
      case 1:
        return { icon: "reallyBadMoodOn", text: getRandom(averageMsg[0]) };
      case 2:
        return { icon: "badMoodOn", text: getRandom(averageMsg[0]) };
      case 3:
        return { icon: "neutralMoodOn", text: getRandom(averageMsg[1]) };
      case 4:
        return { icon: "happyMoodOn", text: getRandom(averageMsg[2]) };
      case 5:
        return { icon: "reallyHappyMoodOn", text: getRandom(averageMsg[2]) };
      default:
        return { icon: "noMood", text: "Comparte tu estado de ánimo" };
    }
  };

  const { icon } = getIconNameAndMsg(average);

  const moodColors = ["#FFCCE1", "#FFDDCC", "#FEF6CD", "#D4F2F7", "#CCFAE9"];

  const bgColor = average ? moodColors[average - 1] : "quinaryBackground";

  return (
    <VStack gap="xxs">
      <WidgetBox mb="xxs" bg={bgColor}>
        <S.CardContent gap="s" pt="m" pb="m">
          <S.IconWrapper>
            <Icon name={icon} width={96} height={96} />
          </S.IconWrapper>
          <Text
            textStyle="body"
            color={average ? "dark" : "text01"}
            weight="bold"
          >
            {message}
          </Text>
        </S.CardContent>
      </WidgetBox>
      <S.PeopleWrapper>
        <Text textStyle="bodyInline" color="text03">
          {users !== undefined && `Participación ${users}/${people.length}`}
        </Text>
      </S.PeopleWrapper>
    </VStack>
  );
};

export default AverageCard;
