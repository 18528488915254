import React from "react";
import { Text, Box } from "@sqymagma/elements";

import { Key } from "@elements";
import { ReactComponent as ErrorSvg } from "@icons/error.svg";

import * as S from "./style";

const Error = () => {
  return (
    <S.Wrapper>
      <Box align="center">
        <ErrorSvg width="100px" />
        <Box mt="xxxs">
          <Text textStyle="display03" color="text01" weight="bold">
            ¡Ups! Algo ha ido mal
          </Text>
        </Box>
        <Box mt="xxxs" mb="xs">
          <Text textStyle="bodyLead" color="text01">
            Prueba a recargar la página borrando la caché
          </Text>
        </Box>
        <Key textStyle="display03">⌘</Key>
        <Key textStyle="display03">⇧</Key>
        <Key textStyle="display03">R</Key>
      </Box>
    </S.Wrapper>
  );
};

export default Error;
