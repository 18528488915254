import React from "react";
import { useMediaQuery } from "beautiful-react-hooks";

import { useStyle } from "@contexts";
import { WEEK_DAYS } from "@constants";

import * as S from "./style";

const DayHeaders = () => {
  const {
    globalTheme: { breakpoints },
  } = useStyle();
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.m})`);
  const weekDays = isDesktop
    ? WEEK_DAYS
    : WEEK_DAYS.map((day) => day.slice(0, 3));

  return (
    <S.DayHeaders>
      {weekDays.map((weekday, idx) => (
        <div key={idx}>{weekday}</div>
      ))}
    </S.DayHeaders>
  );
};

export default DayHeaders;
