import styled from "styled-components";
import { Box } from "@sqymagma/elements";

export const IconWrapper = styled(Box)`
  cursor: pointer;
  transition: 0.2s ease-in;
  &:hover {
    transform: scale(1.1);
  }
`;

export const SuccessBox = styled(Box)`
  border-radius: ${(p) => p.theme.radii.m};
`;
