import { isValid, format } from "date-fns";

import { areSameDay } from "@helpers";

const es = require("date-fns/locale/es").default;

const getEventDateTimes = (
  event: { start: string; end: string },
  day: Date
) => {
  const { start, end } = event;
  const formatDateTime = (dateTime: string) => {
    if (!isValid(new Date(dateTime))) return null;
    return areSameDay(day, dateTime) || areSameDay(start, end)
      ? format(new Date(dateTime), "HH:mm")
      : format(new Date(dateTime), "d LLL HH:mm", {
          locale: es,
        });
  };
  const startTime = formatDateTime(start);
  const endTime = formatDateTime(end);
  return { startTime, endTime };
};

export { getEventDateTimes };
