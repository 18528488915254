import React from "react";
import { Columns, Box } from "@sqymagma/elements";

import { meetings } from "@data";
import { MeetingLink } from "@components";

import { Header } from "./atoms";

const Meetings = () => {
  return (
    <>
      <Header title="Trabajo" />
      <Columns cols={[1, 1, 2, 4]} hs="m" overflow="visible" pt="m">
        {meetings.map((meeting) => (
          <Box pb="m" key={meeting.id}>
            <MeetingLink meeting={meeting} />
          </Box>
        ))}
      </Columns>
    </>
  );
};

export default Meetings;
