import React from "react";
import { format, isToday, isWeekend, isWithinInterval } from "date-fns";

import { useApp } from "@contexts";
import { areSameDay } from "@helpers";

import * as S from "./style";

const Day = (props: Props) => {
  const { date, selectedDay, changeSelectedDay } = props;

  const { holidays, vacations } = useApp();

  const isSelectedDay = areSameDay(date, selectedDay);
  const isHoliday = holidays.some((holiday) => areSameDay(holiday.date, date));
  const isVacation =
    !isHoliday &&
    vacations.some((vacation) =>
      isWithinInterval(date, {
        start: new Date(vacation.start).setHours(0),
        end: new Date(vacation.end),
      })
    );

  const handleChangeDay = () => changeSelectedDay(date);

  return (
    <S.Day
      isSelectedDay={isSelectedDay}
      isToday={isToday(date)}
      onClick={handleChangeDay}
      isHoliday={isHoliday}
      isVacation={!isWeekend(date) && isVacation}
    >
      {format(date, "d")}
    </S.Day>
  );
};

interface Props {
  date: Date;
  selectedDay: Date;
  changeSelectedDay: (date: Date) => void;
}

export default Day;
