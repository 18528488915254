import styled from "styled-components";
import { motion } from "framer-motion";
import { theme } from "@sqymagma/theme";

const Timeline = styled.div`
  position: relative;
  // fix unwanted left margin caused by masonry library
  margin-inline: calc(${(p) => p.theme.spacing.xxs} * -1);
`;

const NewMoods = styled(motion.div)`
  position: sticky;
  z-index: 1;
  display: inline-block;
  float: left;
  top: 10px;
  left: 50%;
  margin-top: calc(${(p) => p.theme.spacing.l} * -1);
  transform: translateX(-50%);

  @media (min-width: ${theme("breakpoints.s")}) {
    transform: translateX(-6px);
  }
`;

export { Timeline, NewMoods };
