import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* Fallback */
  height: 100dvh;
  overflow: hidden;
  background: #131212;

  @media (min-width: 768px) {
    > div {
      position: relative;
      max-width: 430px;
      height: 100vh;
      margin: 0 auto;
    }
  }
`;

const Bg = keyframes`
  0% { transform: rotate(144.708deg) scale(1.2); }
  50% { transform: rotate(144.708deg) scale(0.8); }
  100% { transform: rotate(144.708deg) scale(1.2); }
`;

const StepWrapper = styled.section<{
  $bg: string;
  $deg1: string;
  $deg2: string;
  $rotate: string;
}>`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: ${(p) => p.$bg};

  > * {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    padding-inline: 24px;
    padding-top: 100px;
    padding-bottom: 16px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media (max-width: 768px) {
    > * {
      padding-top: 50px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 30%;
    left: -10%;
    width: 352.483px;
    height: 386.356px;
    transform: rotate(${(p) => p.$rotate});
    border-radius: 386.356px;
    background: linear-gradient(
      180deg,
      ${(p) => p.$deg1} 0%,
      ${(p) => p.$deg2} 100%
    );
    filter: blur(100px);
    animation: ${Bg} 5s ease-in-out infinite;
  }
`;

const RoundImage = styled.div<{ $image: string }>`
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 300px;
  height: 300px;
`;

export { Wrapper, StepWrapper, RoundImage };
