import { useContext, useEffect, useReducer, useRef } from "react";
import * as Sentry from "@sentry/browser";
import { getTime } from "date-fns";

import { googleApi } from "@services";
import firebase from "@database";
import { ONE_MINUTE_IN_MS } from "@constants";

import { AuthState, initialState, reducer } from "./reducer";
import { AuthContext } from "./provider";

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useAuthProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef<AuthState>(state);
  stateRef.current = state;

  const loadUser = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const user = await googleApi.loadUser(token);
        if (user) {
          await firebase.signIn(user.idToken);
          localStorage.setItem("token", JSON.stringify(user.idToken));
          dispatch({ user });
        }
      }
    } catch (error) {
      console.warn("Error while loading user.", error);
    } finally {
      dispatch({ isLoading: false });
    }
  };

  const signIn = async () => {
    try {
      const user = await googleApi.signIn();
      if (user) {
        await firebase.signIn(user.idToken);
        localStorage.setItem("token", JSON.stringify(user.idToken));
        dispatch({ user });
      }
    } catch (error) {
      console.warn("Error while signing in.", error);
    }
  };

  const signOut = async () => {
    try {
      dispatch({ user: null });
      localStorage.removeItem("token");
      googleApi.signOut();
    } catch (error) {
      console.warn("Error while signing out.", error);
    }
  };

  // refresh token when user is about to expire
  const refreshToken = async () => {
    const { user: currentUser } = stateRef.current;
    if (currentUser) {
      const now = new Date();
      const timeUserToExpire = getTime(currentUser.expiration) - getTime(now);
      if (timeUserToExpire < ONE_MINUTE_IN_MS) {
        const user = await googleApi.loadUser(currentUser.idToken);
        if (user) {
          localStorage.setItem("token", JSON.stringify(user.idToken));
          dispatch({ user });
        }
      }
    }
  };

  useEffect(() => {
    dispatch({ isLoading: true });
    googleApi.loadApi().then(loadUser);
    const refreshTokenChecker = setInterval(refreshToken, ONE_MINUTE_IN_MS);
    return () => {
      clearInterval(refreshTokenChecker);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.user) {
      Sentry.setUser({ email: state.user.email });
    }
  }, [state.user]);

  return {
    ...state,
    signIn,
    signOut,
    refreshToken,
  };
};
