import React from "react";
import { AnimatePresence } from "framer-motion";

import * as S from "./style";

const Note = (props: Props) => {
  const { children, open } = props;

  return (
    <AnimatePresence>
      {open && (
        <S.Note
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        >
          {children}
        </S.Note>
      )}
    </AnimatePresence>
  );
};

interface Props {
  children: JSX.Element | string;
  open: boolean;
}

export default Note;
