import React, { MutableRefObject, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useViewportSpy } from "beautiful-react-hooks";

import { useApp, useAuth, useStyle } from "@contexts";
import { VStack } from "@sqymagma/elements";
import { menu } from "@data";
import { THEMES } from "@constants";

import MenuItem from "./MenuItem";
import * as S from "./style";

const Menu = (props: Props) => {
  const { toggleModal } = props;
  const auth = useAuth();
  const { localStorageTheme, changeTheme } = useStyle();
  const {
    serviceWorker: { showReload, reloadPage },
  } = useApp();
  const wrapperRef = useRef(null);
  const inViewport = useViewportSpy(
    wrapperRef as unknown as MutableRefObject<HTMLElement>,
    { threshold: 0.85 }
  );

  useEffect(() => {
    if (inViewport === false) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewport]);

  const themeOptions = [
    {
      title: "Claro",
      action: () => changeTheme(THEMES.light),
      active: localStorageTheme === THEMES.light,
    },
    {
      title: "Oscuro",
      action: () => changeTheme(THEMES.dark),
      active: localStorageTheme === THEMES.dark,
    },
    {
      title: "Sistema",
      action: () => changeTheme(THEMES.system),
      active: localStorageTheme === THEMES.system,
    },
  ];

  return (
    <S.Menu
      ref={wrapperRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
    >
      <motion.div
        initial={{ x: -50 }}
        animate={{ x: 0 }}
        exit={{ x: -50 }}
        transition={{ ease: "easeOut", duration: 0.5 }}
      >
        <VStack as="ul" gap="xs" textAlign="left">
          {menu.map((item, idx) => (
            <MenuItem key={idx} {...item} toggleModal={toggleModal} />
          ))}
        </VStack>
        <S.Separator />
        <VStack gap="xs" mt="xs">
          {showReload && (
            <MenuItem title="Actualizar y reiniciar" action={reloadPage} />
          )}
          <MenuItem
            title="Tema"
            submenu={{ options: themeOptions }}
            toggleModal={toggleModal}
          />
          <MenuItem title="Salir" action={auth.signOut} />
        </VStack>
      </motion.div>
    </S.Menu>
  );
};

interface Props {
  toggleModal: () => void;
}

export default Menu;
