import styled from "styled-components";
import { motion } from "framer-motion";

import { NAVBAR_HEIGHT } from "../constants";

const Menu = styled(motion.div)`
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: calc(100vh - ${NAVBAR_HEIGHT});
  background-color: ${(p) => `${p.theme.colors.primaryBackground}bb`};
  backdrop-filter: blur(20px);
  border-bottom: 1px solid ${(p) => p.theme.colors.line03};
  padding: ${(p) => p.theme.spacing.xs};
  padding-bottom: ${(p) => p.theme.spacing.xxxl};
  overflow-y: scroll;
`;

const Separator = styled.div`
  height: 5px;
  width: 30px;
  background-color: ${(p) => p.theme.colors.text01};
  margin: ${(p) => p.theme.spacing.s} 0;
`;

export { Menu, Separator };
