import React from "react";

import Home from "./Home";
import Calendar from "./Calendar";
import Camels from "./Camels";
import Feedback from "./Feedback";
import Meetings from "./Meetings";
import Tools from "./Tools";
import ShortUrls from "./ShortUrls";
import Library from "./Library";
import Applauses from "./Applauses";
import Mood from "./Mood";
import Wrapped from "./Wrapped";

const routes = [
  { path: "/calendar", component: <Calendar /> },
  { path: "/camels", component: <Camels /> },
  { path: "/meetings", component: <Meetings /> },
  { path: "/tools", component: <Tools /> },
  { path: "/feedback", component: <Feedback /> },
  { path: "/shorturls", component: <ShortUrls /> },
  { path: "/library", component: <Library /> },
  { path: "/applauses", component: <Applauses /> },
  { path: "/mood", component: <Mood /> },
  { path: "/wrapped", component: <Wrapped /> },
  { path: "/", component: <Home /> },
];

export { routes };
