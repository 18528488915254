import styled from "styled-components";

const GifEnd = styled.div<{ $image: string }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: calc(-329px / 2);
  z-index: -1;
  background-image: url(${(p) => p.$image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: calc(329px / 2) calc(329px / 2) 0 0;
  width: 329px;
  height: 567px;
`;

export { GifEnd };
