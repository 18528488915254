import React, { useRef, useState } from "react";
import { useMediaQuery } from "beautiful-react-hooks";

import { useStyle } from "@contexts";
import { IconLink } from "@elements";
import { useHandleClickOutside } from "@hooks";

import * as S from "./style";

const Spotify = () => {
  const {
    globalTheme: { breakpoints },
  } = useStyle();
  const [isOpen, setIsOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.s})`);

  const handleIsOpen = () => setIsOpen(true);

  const handleClickOutside = (e: MouseEvent) => {
    if (iframeRef.current && iframeRef.current.contains(e.target as Node))
      return;
    setIsOpen(false);
  };
  useHandleClickOutside(isOpen, handleClickOutside);

  if (!isDesktop) return null;

  return (
    <S.Wrapper>
      {!isOpen && (
        <S.Tab>
          <IconLink icon="music" action={handleIsOpen} tooltip="Música" big />
        </S.Tab>
      )}
      <S.SpotifyBox isOpen={isOpen}>
        <S.Iframe ref={iframeRef}>
          <iframe
            title="Spotify"
            src="https://open.spotify.com/embed/playlist/0ohxyYfJqGiCheBifxN3Wf"
            width="100%"
            height="152"
            style={{ display: "block" }}
            allow="encrypted-media"
          ></iframe>
        </S.Iframe>
      </S.SpotifyBox>
    </S.Wrapper>
  );
};

export default Spotify;
