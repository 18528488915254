import * as React from "react";
import { Text, Box, VStack } from "@sqymagma/elements";
import { format } from "date-fns";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { useApp, useStyle } from "@contexts";
import { THEMES } from "@constants";

import * as S from "./style";
import { capitalizeFirstLetter, createImg } from "@helpers";

import reallyBad from "@icons/mood/really-bad_on.svg";
import bad from "@icons/mood/bad_on.svg";
import neutral from "@icons/mood/neutral_on.svg";
import happy from "@icons/mood/happy_on.svg";
import reallyHappy from "@icons/mood/really-happy_on.svg";

const customPoints = [
  createImg(reallyBad),
  createImg(bad),
  createImg(neutral),
  createImg(happy),
  createImg(reallyHappy),
];

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);
ChartJS.defaults.font.family = "'Gilroy', sans-serif";

interface DayChartProps {
  data?: Record<string, number>;
}

const DayChart = (props: DayChartProps) => {
  const { data } = props;
  const { people } = useApp();
  const xMax = people.length;

  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;
  const labels = [1, 2, 3, 4, 5];

  const es = require("date-fns/locale/es").default;
  const date = new Date();
  const dayOfWeek = format(date, "EEEE", { locale: es });
  const month = format(date, "MMMM", { locale: es });
  const day = format(date, "d", { locale: es });
  const year = format(date, "yyyy", { locale: es });
  const today = `${capitalizeFirstLetter(
    dayOfWeek
  )}, ${day} de ${month.toLowerCase()} de ${year}`;

  return (
    <S.ChartContainer>
      <VStack gap="xxxs">
        <Text textStyle="headings" color="text01">
          ¿Cómo se encuentra hoy el equipo?
        </Text>
        <Text textStyle="body" color="text01">
          {today}
        </Text>
      </VStack>

      <Box position="relative" width="100%" mt="xs">
        <Bar
          height={190}
          data={{
            labels,
            datasets: [
              {
                data: data && Object.values(data),
                backgroundColor: [
                  "#FA0067",
                  "#FF7733",
                  "#FBE15C",
                  "#3CC3DD",
                  "#19E99E",
                ],
                categoryPercentage: 0.5,
              },
            ],
          }}
          options={{
            indexAxis: "y" as const,
            responsive: true,
            elements: {
              bar: {
                borderWidth: 0,
                borderRadius: 10,
              },
            },
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                display: false,
              },
              datalabels: {
                color: isDarkTheme ? "#ffffff" : "#101010",
                anchor: "end",
                align: "end",
                formatter: (value) => value || "",
                font: { size: 15 },
              },
            },
            scales: {
              x: {
                border: { width: 0 },
                offset: true,
                grid: { color: isDarkTheme ? "#333333" : "#cccccc" },
                ticks: {
                  stepSize: 5,
                  display: false,
                },
                max: xMax % 5 === 0 ? xMax : Math.ceil(xMax / 5) * 5,
              },
              y: {
                reverse: true,
                border: { width: 0 },
                grid: { display: false },
                ticks: {
                  display: false,
                },
              },
            },
          }}
          plugins={[
            ChartDataLabels,
            {
              id: "custom_ticks",
              afterDraw: (chart) => {
                const ctx = chart.ctx;
                const yAxis = chart.scales.y;
                labels?.forEach((label) => {
                  const yPos = yAxis.getPixelForValue(Number(label) - 1);
                  ctx.drawImage(
                    customPoints[Number(label) - 1],
                    0,
                    yPos - 12,
                    24,
                    24
                  );
                });
              },
            },
          ]}
        />
      </Box>
    </S.ChartContainer>
  );
};

export default DayChart;
